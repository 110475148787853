import React from 'react';
import "./index.scss";

import Spinner from "../../Spinner";

import PropTypes from "prop-types";

export default function YesNoModal(props) {
    const [spinnerRight, setSpinnerRight] = React.useState(false);
    const [spinnerLeft, setSpinnerLeft] = React.useState(false);
    const [disabledRight, setDisabledRight] = React.useState(false);
    const [disabledLeft, setDisabledLeft] = React.useState(false);
    const [disabledAll, setDisabledAll] = React.useState(false);
    const [isLeftHovering, setIsLeftHovering] = React.useState(false);
    const [isRightHovering, setIsRightHovering] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const handleMouseEnterLeft = () => {
        setIsLeftHovering(true);
    };

    const handleMouseLeaveLeft = () => {
        setIsLeftHovering(false);
    };

    const handleMouseEnterRight = () => {
        setIsRightHovering(true);
    };

    const handleMouseLeaveRight = () => {
        setIsRightHovering(false);
    };

    const mainRef = React.useRef();

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {opactiy: getComputedStyle(mainRef.current).opacity},
            {opacity: 1}
        ], {duration: 300, delay: 100, iterations: 1, fill: "both", easing: "ease"});
    }, [mainRef.current]);

    return (
        <div className='component__yesNoModal'>
            <div className='component__yesNoModal__wrapper' ref={mainRef}>
                <img className='component__yesNoModal__wrapper__close' src='images/yesno__closeIcon.svg' alt='' onClick={() => props.xCallback ? props.xCallback() : props.onClose()} />
                <strong className='component__yesNoModal__wrapper__heading'>{props.heading}</strong>
                <p className='component__yesNoModal__wrapper__text'>
                    {props.text}
                </p>
                <div className='component__yesNoModal__wrapper__buttons'>
                    <div
                        style={{
                            display: props.buttonLeftHidden ? 'none' : 'flex',
                            background: disabledAll || disabledLeft ? 'rgb(220 220 220)' : isLeftHovering && props.isLeftButtonNormal ? '#EF9500' : isLeftHovering && !props.isLeftButtonNormal ? 'rgb(97 102 114)' : props.isLeftButtonNormal ? '#FCA311' : '#788093',
                            pointerEvents: disabledLeft || disabledAll ? 'none' : ''
                        }}
                        className='component__yesNoModal__wrapper__buttons__leftBtn'
                        onClick={() => !props.buttonLeftCallback ? props.onClose() : props.buttonLeftCallback({
                            close: () => props.onClose(),
                            spinner: (arg) => setSpinnerLeft(!!arg),
                            disabled: (arg) => setDisabledLeft(!!arg),
                            disabledAll: (arg) => setDisabledAll(!!arg),
                            errorMessage: (arg) => setErrorMessage(arg)
                        })}
                        onMouseEnter={handleMouseEnterLeft}
                        onMouseLeave={handleMouseLeaveLeft}
                    >
                        {!spinnerLeft && <p>{props.buttonLeftText ? props.buttonLeftText : 'No'}</p>}
                        {spinnerLeft && <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                    </div>
                    <div
                        style={{
                            display: props.buttonRightHidden ? 'none' : 'flex',
                            background: disabledAll || disabledRight ? 'rgb(220 220 220)' : isRightHovering && props.isRightButtonNormal ? '#EF9500' : isRightHovering && !props.isRightButtonNormal ? '#1A1A1A' : props.isRightButtonNormal ? '#1A1A1A' : '#1A1A1A',
                            pointerEvents: disabledRight || disabledAll ? 'none' : ''
                        }}
                        className='component__yesNoModal__wrapper__buttons__rightBtn'
                        onClick={() => {!props.buttonRightCallback ? props.onClose() : props.buttonRightCallback(props.funcParam); props.onClose();}}
                        onMouseEnter={handleMouseEnterRight}
                        onMouseLeave={handleMouseLeaveRight}
                    >
                        {!spinnerRight && <p>{props.buttonRightText ? props.buttonRightText : 'Yes'}</p>}
                        {spinnerRight && <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                    </div>
                </div>
                {errorMessage && <p className='component__yesNoModal__wrapper__errorMessage'>{errorMessage}</p>}
            </div>
        </div>
    )
}

YesNoModal.propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    buttonLeftText: PropTypes.string,
    buttonRightText: PropTypes.string,
    buttonLeftCallback: PropTypes.func,
    buttonRightCallback: PropTypes.func,
    buttonLeftHidden: PropTypes.bool,
    buttonRightHidden: PropTypes.bool,
    isLeftButtonNormal: PropTypes.bool,
    isRightButtonNormal: PropTypes.bool,
    xCallback: PropTypes.func,
}