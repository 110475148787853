import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment';
import animateModule from "../../modules/animateModule";
import { useLocation, useNavigate } from "react-router-dom";

import SimpleDropdown from '../../components/SimpleDropdown';
import Spinner from '../../components/Spinner';
import YesNoModal from "../../components/Modals/YesNoModal";

export default function AdminAffiliates() {
    const [sort, setSort] = React.useState({ name: 'Sort by', value: 'createdAt', order: 'any' });
    const [filter, setFilter] = React.useState({ name: 'Filter by', baseName: 'AccessConfirmed', value: 'all' });
    const [affiliates, setAffiliates] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);
    const [searchValue, setSearchValue] = React.useState(null);

    const searchRef = React.useRef(null);

    const mainNavigate = useNavigate();
  
    const animateNavigate = to => {
      animateModule(mainNavigate, to, document.querySelector(".route__toAnimate"));
    };

    const sortArray = [
        { name: 'Balance ASC', value: 'Balance', order: 'ASC' },
        { name: 'Balance DESC', value: 'Balance', order: 'DESC' },
        { name: 'Created Date ASC', value: 'createdAt', order: 'ASC' },
        { name: 'Created Date DESC', value: 'createdAt', order: 'DESC' },
        { name: 'Sort by', value: 'createdAt', order: 'any' },
    ];

    const filtersArray = [
        { name: 'Access Confirmed', baseName: 'AccessConfirmed', value: 1 },
        { name: 'Access Denied', baseName: 'AccessConfirmed', value: 0 },
        { name: 'Filter by', baseName: 'AccessConfirmed', value: 'all' }
    ];

    const getAffiliates = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllAffiliates`,
            data: {
                filters: [
                    filter.value !== 'all' ? { name: filter.baseName, op: 'eq', value: filter.value } : null,
                    String(searchValue).includes('N:') ? { name: 'FirstName', op: 'like', value: String(searchValue).replace('N:', '') } : null,
                    String(searchValue).includes('ID:') ? { name: 'ID', op: 'like', value: String(searchValue).replace('ID:', '') } : null
                ],
                orders: sort.order !== 'any' ? [[sort.value, sort.order]] : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setAffiliates(res.data.data);
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteAffiliate = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/deleteUser`,
            data: {
                id: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAffiliates();
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {

        });
    }

    React.useEffect(() => {
        getAffiliates();
    }, [filter, searchValue, sort]);

    return (
        <div className='route__adminAffiliates'>
            <div className='route__adminAffiliates__header'>
                <h1>Affiliates</h1>
                <div className='route__adminAffiliates__header__filters'>
                    <div className='route__adminAffiliates__header__filters__search'>
                        <img onClick={() => setSearchValue(searchRef.current.value)} src='images/search.png' alt='' />
                        <input ref={searchRef} type='text' placeholder='Search... N:name ID:id' />
                    </div>
                    <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Sort by' data={sortArray} setSelected={setSort} />
                    <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Filter by' data={filtersArray} setSelected={setFilter} />
                </div>
            </div>
            <div className='route__adminAffiliates__table'>
                <div className='route__adminAffiliates__table__headers'>
                    <p>ID</p>
                    <p>Username</p>
                    <p>Access</p>
                    <p>Name</p>
                    <p>E-mail</p>
                    <p>Country</p>
                    <p>Created</p>
                    <p>Balance</p>
                    <p>Actions</p>
                </div>
                <div className='route__adminAffiliates__table__content'>
                    {!spinner && !error ? affiliates?.map((adv, index) => {
                        return <div style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__adminAffiliates__table__content__row'>
                            <p>{adv.ID}</p>
                            <p>{adv.Username}</p>
                            <p
                                style={{ border: adv.AccessConfirmed === true ? '1px solid #1F9254' : '1px solid #A30D11', background: adv.AccessConfirmed === true ? '#EBF9F1' : '#FBE7E8', color: adv.AccessConfirmed === true ? '#1F9254' : '#A30D11', borderRadius: '30px', paddingLeft: '12px', paddingRight: '12px', paddingTop: '8px', paddingBlock: '8px', width: '70%', textAlign: 'center', fontSize: '13px' }}
                            >{adv.AccessConfirmed === true ? 'Approved' : 'Denied'}</p>
                            <p>{adv.FirstName} {adv.LastName}</p>
                            <p>{adv.Email}</p>
                            <p>{adv.Country}</p>
                            <p>{moment(adv.createdAt).format('DD.MM.YYYY.')}</p>
                            <p>${adv.Balance}</p>
                            <div className='route__adminAffiliates__table__content__row__actions'>
                                <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <DetailsModal refresh={getAffiliates} data={adv} name={adv.FirstName + ' ' + adv.LastName} />)} src='images/table__edit.png' alt='' />
                                <img onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <YesNoModal buttonRightCallback={deleteAffiliate} funcParam={adv.ID} heading='Delete advertiser?' text='This action cannot be reversed' />)} src='images/table__trash.png' alt='' />
                                <img onClick={() => animateNavigate(`/admin/affiliates/statistics?affiliateID=${adv.ID}`)} src='images/stats.png' alt='' />
                            </div>
                        </div>
                    }) : null}
                    {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    {error && <p style={{ color: 'red', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
                </div>
            </div>
        </div>
    )
}

const DetailsModal = (props) => {
    const [access, setAccess] = React.useState(props.data.AccessConfirmed);
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);

    const balanceRef = React.useRef(null);

    const updateAffiliate = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/updateAffiliate`,
            data: {
                ID: props.data?.ID,
                USDamount: balanceRef.current.value,
                AccessConfirmed: access
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.onClose();
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__detailsModal'>
        <div className='component__detailsModal__wrapper'>
            <div className='component__detailsModal__wrapper__header'>
                <h1>{props.name}</h1>
                <img onClick={props.onClose} src='images/closeModal.png' alt='' />
            </div>
            <div className='component__detailsModal__wrapper__body'>
                <div className='component__detailsModal__wrapper__body__left'>
                    <h1>Other informations</h1>
                    <div className='component__detailsModal__wrapper__body__left__field'>
                        <label>ZIP Code</label>
                        <p>{props.data?.ZIP}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__left__field'>
                        <label>Experience</label>
                        <p>{props.data?.Experience}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__left__field'>
                        <label>Telegram</label>
                        <p>{props.data?.Telegram}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__left__field'>
                        <label>Skype</label>
                        <p>{props.data?.Skype}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__left__field'>
                        <label>Whatsapp</label>
                        <p>{props.data?.Whatsapp}</p>
                    </div>
                    <div className='component__detailsModal__wrapper__body__left__field'>
                        <label>Squers</label>
                        <p>{props.data?.Squers}</p>
                    </div>
                </div>
                <div className='component__detailsModal__wrapper__body__right'>
                    <div className='component__detailsModal__wrapper__body__right__top'>
                        <h1>New balance</h1>
                        <div className='component__detailsModal__wrapper__body__right__top__field'>
                            <input ref={balanceRef} type='number' />
                        </div>
                    </div>
                    <div className='component__detailsModal__wrapper__body__right__bottom'>
                        <h1>Access</h1>
                        <div style={{ border: !access ? '1px solid gray' : '1px solid #1F9254' }} className='component__detailsModal__wrapper__body__right__bottom__switch'>
                            <div onClick={() => setAccess(ac => !ac)}
                                className={access ? 'component__detailsModal__wrapper__body__right__bottom__switch__circle component__detailsModal__wrapper__body__right__bottom__switch__circle__active' : 'component__detailsModal__wrapper__body__right__bottom__switch__circle'}
                            ></div>
                        </div>
                    </div>
                    <div onClick={() => updateAffiliate()} className='component__detailsModal__wrapper__body__right__bottom__button'>
                        {!spinner && <p>Save changes</p>}
                        {spinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
                    </div>
                    {error && <p>{error}</p>}
                </div>
            </div>
        </div>
    </div>
}