import React, { useEffect, useState, useMemo } from 'react';
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import axios from "axios";
import * as backendModule from "./modules/backendModule";
import Spinner from './components/Spinner';

import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import AccessDenied from './components/AccessDenied';
import UrgentNews from './components/Modals/UrgentNews';

import LandingPage from "./Landing/routes/LandingPage";
import Register from './Landing/routes/Register';
import Login from "./Landing/routes/Login";
import Terms from './Landing/routes/Terms';
import PrivacyPolicy from './Landing/routes/PrivacyPolicy';

import AdvertiserOffers from './Advertiser/AdvertiserOffers';
import AdvertiserLeads from './Advertiser/AdvertiserLeads';
import AdvertiserLeadStatusReport from './Advertiser/AdvertiserLeadStatusReport';
import AdvertiserBalance from './Advertiser/AdvertiserBalance';
import AdvertiserBlacklist from './Advertiser/AdvertiserBlacklist';
import AdvertiserNews from './Advertiser/AdvertiserNews';
import AdvertiserProfile from './Advertiser/AdvertiserProfile';
import AdvertiserStatistics from './Advertiser/AdvertiserStatistics';

import AffiliateDashboard from './Affiliate/AffiliateDashboard';
import AffiliateOffers from './Affiliate/AffiliateOffers';
import AffiliatePromoteInstructions from './Affiliate/AffiliatePromoteInstructions';
import AffiliateLeads from './Affiliate/AffiliateLeads';
import AffiliateBalance from './Affiliate/AffiliateBalance';
import AffiliatePayments from './Affiliate/AffiliatePayments';
import AffiliateTracking from './Affiliate/AffiliateTracking';
import AffiliateStatistics from './Affiliate/AffiliateStatistics';
import AffiliateNews from './Affiliate/AffiliateNews';
import Shop from './Affiliate/Shop';
import AffiliateProfile from './Affiliate/AffiliateProfile';

import AdminDashboard from './Admin/AdminDashboard';
import AdminOffers from "./Admin/AdminOffers";
import AdminAdvertisers from './Admin/AdminAdvertisers';
import AdminAffiliates from './Admin/AdminAffiliates';
import AdminLeads from './Admin/AdminLeads';
import AdminPayments from './Admin/AdminPayments';
import AdminNews from './Admin/AdminNews';
import AdminStatistics from './Admin/AdminStatistics';
import AdminAffiliatesStatistics from './Admin/AdminAffiliateStatistics';

import OfferPage from './GlobalRoutes/OfferPage';
import ReadNews from './GlobalRoutes/ReadNews';
import ApiPostbackDocumentation from './GlobalRoutes/Api&Postback';

function App() {
  const [logged, setLogged] = useState(false);
  const [userData, setUserData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [accessConfirmed, setAccessConfirmed] = useState(true);
  const [urgentNewsThere, setUrgentNewsThere] = React.useState(false);
  const [urgentArticle, setUrgentArticle] = React.useState([]);

  const location = useLocation();
  const deniedPaths = ["/", "/login", "/register", "/terms", "/privacypolicy"];
  const shouldShowSidebar = useMemo(() => !deniedPaths.includes(location.pathname), [location.pathname]);

  const checkLogin = async () => {
    try {
      const res = await axios.post(`${backendModule.backendURL}/auth/checkLogin`, {}, backendModule.axiosConfig);
      if (res.data.status === "ok") {
        const userInfo = res.data.data.UserInfo;
        setUserData(userInfo);
        setLogged(true);
        setAccessConfirmed(userInfo.AccessConfirmed || deniedPaths.includes(location.pathname));
      } else {
        setLogged(false);
      }
    } catch {
      setLogged(false);
    } finally {
      setSpinner(false);
    }
  };

  const urgentNewsCheck = async () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/news/getAllNews`,
      data: {
        filters: [{ name: 'IsUrgent', op: 'eq', value: true }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setUrgentNewsThere(res.data.data[0].length === 0 ? false : true);
        setUrgentArticle(res.data.data);
      }
      else {
        setUrgentNewsThere(false)
      }
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      urgentNewsCheck();
    }, 1000);
  }, [logged]);

  useEffect(() => {
    checkLogin();
  }, [location.pathname]);

  if (spinner) return <Spinner style={{ width: '64px',height: '50px', marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', display: 'block' }} color='white' />;
  if (!accessConfirmed) return <AccessDenied />;

  return (
    <div style={{
      display: logged && shouldShowSidebar ? 'grid' : 'block',
      gridTemplateColumns: window.innerWidth > 1400 ? '230px auto' : window.innerWidth < 1041 ? '1fr' : '200px auto',
      gridTemplateRows: window.innerWidth < 1041 ? '50px auto' : 'unset',
      height: logged && shouldShowSidebar ? '100vh' : '',
      backgroundImage: logged && shouldShowSidebar ? 'none' : ''
    }} className="route__contentWrapper">

      {logged && urgentNewsThere && <UrgentNews article={urgentArticle} setModal={setUrgentNewsThere} />}
      {logged && shouldShowSidebar &&  <Sidebar userData={userData} />}

      <div className='route__toAnimate'>
        <Header logged={logged} />

        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login setAccessConfirmed={setAccessConfirmed} setLogged={setLogged} logged={logged} setUserData={setUserData} />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />

          {/* Advertiser routes */}
          {userData?.Flags?.isAdvertiser && (
            <Route path="advertiser" element={<Outlet />}>
              <Route path="offers" element={<AdvertiserOffers userData={userData} />} />
              <Route path="offers/offerpage" element={<OfferPage mode="advertiser" />} />
              <Route path="leads" element={<AdvertiserLeads userData={userData} />} />
              <Route path="leads-status-report" element={<AdvertiserLeadStatusReport userData={userData} />} />
              <Route path="blacklist" element={<AdvertiserBlacklist userData={userData} />} />
              <Route path="balance" element={<AdvertiserBalance userData={userData} />} />
              <Route path="news" element={<AdvertiserNews />} />
              <Route path="news/read" element={<ReadNews mode='advertiser' />} />
              <Route path="profile" element={<AdvertiserProfile userData={userData} />} />
              <Route path="statistics" element={<AdvertiserStatistics userData={userData} />} />
            </Route>
          )}

          {/* Affiliate routes */}
          {userData?.Flags?.isAffiliate && (
            <Route path="affiliate" element={<Outlet />}>
              <Route path="dashboard" element={<AffiliateDashboard userData={userData} />} />
              <Route path="offers" element={<AffiliateOffers userData={userData} />} />
              <Route path="offers/offerpage" element={<OfferPage mode="affiliate" userData={userData} />} />
              <Route path="offers/offerpage/promote-instructions" element={<AffiliatePromoteInstructions />} />
              <Route path="leads" element={<AffiliateLeads userData={userData} />} />
              <Route path="balance" element={<AffiliateBalance userData={userData} />} />
              <Route path="payments" element={<AffiliatePayments checkLogin={checkLogin} userData={userData} />} />
              <Route path="tracking" element={<AffiliateTracking userData={userData} />} />
              <Route path="statistics" element={<AffiliateStatistics userData={userData} />} />
              <Route path="news" element={<AffiliateNews />} />
              <Route path="news/read" element={<ReadNews mode='affiliate' />} />
              <Route path="shop" element={<Shop />} />
              <Route path="profile" element={<AffiliateProfile userData={userData} />} />
            </Route>
          )}

          {/* Admin routes */}
          {userData?.Flags?.isAdmin && (
            <Route path="admin" element={<Outlet />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="offers" element={<AdminOffers />} />
              <Route path="offers/offerpage" element={<OfferPage mode="admin" />} />
              <Route path="advertisers" element={<AdminAdvertisers />} />
              <Route path="affiliates" element={<AdminAffiliates />} />
              <Route path="affiliates/statistics" element={<AdminAffiliatesStatistics />} />
              <Route path="leads" element={<AdminLeads userData={userData} />} />
              <Route path="payments" element={<AdminPayments userData={userData} />} />
              <Route path="news" element={<AdminNews />} />
              <Route path="news/read" element={<ReadNews mode='admin' />} />
              <Route path="statistics" element={<AdminStatistics userData={userData} />} />
            </Route>
          )}

          {/* Common routes */}
          {(userData?.Flags?.isAdmin || userData?.Flags?.isAdvertiser || userData?.Flags?.isAffiliate) && (
            <Route path="/help/api-and-postback-documentation" element={<ApiPostbackDocumentation userData={userData} />} />
          )}
        </Routes>

        <Footer />
      </div>
    </div>
  );
}

export default App;
