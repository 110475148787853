import React from 'react';
import "./index.scss";
import moment from 'moment';
import DateRangeCalendar from '../../components/DateRangeCalendar';
import Spinner from '../../components/Spinner';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { leadStatuses } from '../../modules/scppModule';

export default function AdvertiserLeadStatusReport(props) {
    const [spinner, setSpinner] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [dateRange, setDateRange] = React.useState({ start: null, end: null });
    const [paginationSpinner, setPaginationSpinner] = React.useState(false);
    const [paginationMessage, setPaginationMessage] = React.useState(null);

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/leadCountByStatus`,
            data: {
                UserID: props.userData?.ID,
                UserType: 'advertiser',
                startDate: dateRange.start !== null ? dateRange.start : null,
                endDate: dateRange.end !== null ? dateRange.end : null,
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        if (dateRange.start !== null && dateRange.end !== null) {
            getData();
        }
    }, [dateRange]);

    return (
        <div className='route__advertiserLeadStatusReport'>
            <div className='route__advertiserLeadStatusReport__header'>
                <h1>Status report</h1>
                <DateRangeCalendar range={dateRange} setRange={setDateRange} />
            </div>
            
            <div className='route__advertiserLeadStatusReport__table'>
                <div className='route__advertiserLeadStatusReport__table__headers'>
                    <p>Date</p>
                    <p>{leadStatuses[0].name}</p>
                    <p>{leadStatuses[1].name}</p>
                    <p>{leadStatuses[2].name}</p>
                    <p>{leadStatuses[3].name}</p>
                    <p>{leadStatuses[4].name}</p>
                    <p>{leadStatuses[5].name}</p>
                    <p>{leadStatuses[6].name}</p>
                    <p>{leadStatuses[7].name}</p>
                </div>
                <div className='route__advertiserLeadStatusReport__table__content'>
                    {!spinner && !error ? data?.map((record, index) => {
                        return <div style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__advertiserLeadStatusReport__table__content__row'>
                            <p>{moment(record.date).format('DD.MM.YYYY.')}</p>
                            <p>{record.statuses[0].numberOfLeads}</p>
                            <p>{record.statuses[1].numberOfLeads}</p>
                            <p>{record.statuses[2].numberOfLeads}</p>
                            <p>{record.statuses[3].numberOfLeads}</p>
                            <p>{record.statuses[4].numberOfLeads}</p>
                            <p>{record.statuses[5].numberOfLeads}</p>
                            <p>{record.statuses[6].numberOfLeads}</p>
                            <p>{record.statuses[7].numberOfLeads}</p>
                        </div>
                    }) : null}
                    {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    {error && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
                    {data.length === 0 && <p style={{ padding: '10px' }}>No data yet</p>}
                </div>
            </div>
        </div>
    )
}
