import React from 'react';

export default function Terms() {
    return (
        <div style={{padding: '50px 162px 50px 162px'}} className='route__terms'>
            <h1 style={{fontSize: '22px', color: '#CAFF33'}}>Terms and Conditions</h1>
            <br></br>
            <p style={{color: 'white', fontSize: '14px'}}>
                Legal Notice
                Trade name “LeadSquar” relates to one or any number of legal entities, including its subsidiaries and affiliates. Each of the mentioned legal entities is a separate and independent legal entity.
                LeadSquar is a general purpose advertising network that works with various types of advertisers and publishers around the world, and offers an ability to launch ads campaigns by automated means. Advertising campaign management is carried out through a personal user account, and LeadSquar only offers a tool for such campaign management. Therefore, LeadSquar is not an advertising agency, but an online service connecting advertisers and websites with ads spaces available (publishers).
                Your use of this website is subject to our terms and conditions (including the Advertiser and Publisher Agreement) set out herein, and by using this website you are agreeing, and you are signifying your agreement, to be bound by these terms and conditions. You are also responsible for ensuring that all persons who access our website through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.
                The information contained in this website is provided on “as is” basis and for general information purposes only. The information is provided by LeadSquar and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                Through this website you are able to link to other websites which are not under the control of LeadSquar. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                Every effort is made to keep the website up and running smoothly. However, LeadSquar takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                Before making any decision or taking any action that may affect your financial position or status, consult with a qualified professional. None of the entities within the LeadSquar network is responsible for any loss sustained by any person using this website or service.
                This website and services provided herein managed by: LeadSquar D.O.O.
                <br></br><br></br>
                TERMS AND CONDITIONS
                <br></br><br></br>
                By using our services, you are agreeing to our Terms and Conditions, so please read them carefully.
                Advertiser Agreement | Publisher Agreement
                <br></br><br></br>
                ADVERTISER AGREEMENT<br></br>
                LeadSquar(the, “LeadSquar”, “We”, etc.) being an advertising network that provide services for products monetization and promotion, connecting publishers and advertisers through such service globally (the, “Service”), and You (the, “Advertiser”, “You”, “Yours”, etc.), seeking for an online service for managing advertising campaigns and marketing consultancy, media market analysis, ads campaign planning, media time buying, and
                WHEREAS,
                LeadSquar has offered its services to the Advertiser through https://LeadSquar.com website (the, “Program”), and You decided to utilise the Service,
                NOW,
                LeadSquar and Advertiser hereby agree as follows:
                BY CHECKING THE BOX AND CLICKING "I ACCEPT" BUTTON, AS APPLICABLE, OR BY CONTINUING TO PARTICIPATE IN THE SERVICE FOLLOWING OUR PUBLICATION OF THE REVISED VERSION OF THIS AGREEMENT ON OUR WEBSITE, YOU (A) CONFIRM THAT YOU ARE AWARE AND COMPLY WITH THIS AGREEMENT AND AGREE TO BE BOUND BY THIS ADVERTISER AGREEMENT; (B) ACKNOWLEDGE AND AGREE THAT YOU HAVE INDEPENDENTLY EVALUATED THE DESIRABILITY OF PARTICIPATING IN THE PROGRAM AND ARE NOT RELYING ON ANY REPRESENTATION, GUARANTEE, OR STATEMENT OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT; (C) HEREBY REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED AND LAWFULLY ABLE TO ENTER INTO THIS AGREEMENT AND THAT YOU ARE AND WILL REMAIN IN COMPLIANCE WITH THIS AGREEMENT; AND (D) AGREE TO RECEIVE DIRECTLY TO YOUR E-MAIL NEWSLETTERS, TRAFFIC MONETIZATION INSIGHTS, LATEST PROMOTIONS, CASE-STUDIES AND TUTORIALS FROM US.
                Out of concern for the environment, this Agreement is concluded exclusively in electronic form and no paper copy will be produced. This Agreement concluded in electronic form shall be deemed equivalent to a paper document bearing a handwritten signature and a seal. At the request of the Party, this Agreement or any additional documents to it, accounting and other documents signed by electronic document exchange shall be replaced by a paper document signed by handwritten signature and sealed within 20 business days from the date from the receipt of the Party's written request. In this case, the costs for sending the requested documents shall be borne by the Party, which sent the relevant request.
                <br></br><br></br>
                1. DEFINITIONS<br></br>
                1.1. “Ad(s), Advertisement(s)” or “Advertising materials” – means graphical, interactive, rich media and video, or other online advertisements, including, without limitation, banners, buttons, towers, skyscrapers, pop-ups, pop-unders and video advertisements or similar generated by Advertiser’s web-servers in response to a query from LeadSquar or created by publisher(s) in the course of the services.
                1.2. “Advertiser” – means a party that has decided to enter into this Agreement and to assign LeadSquar to provide online services in accordance with the terms and conditions of this Agreement.
                1.3. “Advertiser Account” / “Account” – means the Advertiser’s account at LeadSquar website www.LeadSquar.com for deposit of money and managing of campaigns.
                1.4. “Content” – means all ad content, related technology and tags provided by Advertiser that are subject to the Services under this Agreement.
                1.5. “Effective Date” – means the date of adoption by Advertiser terms of this Agreement or in the absence of its signature, the date when the Advertiser set up an Advertiser Account with LeadSquar.
                1.6. “LeadSquar Network” – means LeadSquar’s digital advertising network available at www.LeadSquar.com, including advertisers and publishers.
                1.7. “LeadSquar Network Property” – means any website, application, content, property or any other media owned, operated, or provided by a company within the LeadSquar Network upon which Ads are placed.
                1.8. “Campaign” – means certain actions set up by an Advertiser to increase traffic to the Advertiser or its affiliate website, sales and/or attracting new customers.
                <br></br><br></br>
                2. THE SERVICE<br></br>
                2.1. LeadSquar provides You an opportunity to participate in our Service and Program by placing its Ads or Ads created by publishers on web pages of publishers registered in LeadSquar Network or other web pages chosen by such publishers. LeadSquar will track and report its Services in a manner and on a schedule as determined by LeadSquar.
                2.2. In order to become an Advertiser you must first accurately submit an application for LeadSquar account at our website and be in compliance with this Agreement or register yourself as an Advertiser by contacting LeadSquar directly for acceptance, and not use any aliases or other means to mask your true identity or contact information. After we review your application, we will notify of your acceptance or rejection as LeadSquar’s Advertiser. We may accept or reject your account registration at any time at our sole discretion for any reason. LeadSquar reserves the right to add, edit, remove or reclaim any account details (including your submissions) with or without your consent if deemed appropriate at LeadSquar sole discretion.
                2.3. By filing your account application or registering as an Advertiser you confirm your understanding and unreserved acceptance of this Agreement and terms and conditions of LeadSquar, including, but not limited to, the Privacy Policy published at our website concerning the Services, and confirm You are a duly authorized signatory, has full legal capacity and all the necessary authority to bind the individual, company or other entity, and hereby submitting a legally binding electronic signature and entering into a legally binding contract.
                2.4. LeadSquar has the following Non Acceptable Business rules for Advertisers:
                - Where there are known or perceived links to terrorist organizations, military, arms and/or ammunition manufacture or sales;
                - Where there is knowledge or suspicion of money laundering or terrorist financing;
                - Where it is known or there are reasonable grounds for suspicion that a criminal offence has taken place;
                - Where the client or any of the clients associated parties are subject to any sanctions measures;
                - Where the client is undertaking an activity or trade within, from or into a country where that activity is subject to embargo and/or trade control restrictions;
                - Producers/publishers of racist/pornographic/pressure group material or extremist organizations;
                - Regulated entities that do not have the appropriate licensing;
                - Political organizations.
                <br></br><br></br>
                3. GENERAL TERMS<br></br>
                3.1. Communication between LeadSquar and the Advertiser shall be made through the Advertiser’s Personal Account in LeadSquar Network, which is placed at the following link: https://partner.LeadSquar.com.
                The Advertiser undertakes and is responsible for ensuring compliance with the information security regime during the provision of services, including the following:
                - ensure confidentiality of authorization data;
                - use passwords resistant to cracking;
                - not allow access to the Personal Account and the electronic mailbox of the Advertiser to persons not authorized by the Advertiser;
                - immediately notify LeadSquar in the event of loss or existence of reasonable suspicions regarding a violation of confidentiality of the authorization data used by the Advertiser to access its Personal Account, as well as the authorization data of the Advertiser’s electronic mailbox.
                3.2. The Advertiser shall provide LeadSquar with advertising materials necessary to render the services. The Advertiser represents that it is entitled to use the advertising materials based on copyrights and licenses held, and that the use of the advertising materials does not breach any laws, regulations, or rights of third parties. In no case shall the Advertiser transfer copyrights or licenses to the advertising materials to LeadSquar.
                3.3. LeadSquar has the right to refuse to accept and place advertising materials transferred by the Advertiser in case if content of said materials contradicts to the applicable legislation, LeadSquar policy or contains any signs of improper advertising or obviously does not correspond with the generally accepted moral and ethical standards. Nevertheless, in no case shall LeadSquar be obliged to check advertising materials or be responsible for any of the above violations.
                3.4. The Advertiser must immediately inform LeadSquar about all changes connected with previously provided information and advertising materials.
                3.5. The Parties may also allow third party webmasters and affiliates to create their own advertising materials necessary to generate leads. Where advertising materials are created by third party webmasters and affiliates, the responsibility for these advertising materials shall rest solely with these creators.
                3.6. Types and formats of advertising materials placed in order to fulfill the Agreement shall be chosen by LeadSquar or third party webmasters and affiliates engaged by LeadSquar independently.
                3.7. Each item of advertising materials shall contain a code of the agreed integration method in order to track traffic generated to the Advertiser’s web site (web site of a lead performance) and register leads.
                3.8. The following types of integration may be applied between LeadSquar and the Advertiser:
                API integration – a cooperation interface between the systems of LeadSquar and the Advertiser for lead metering and data exchange;
                postback URL – a specific link created to enable LeadSquar Network for registering leads. Additional parameters of this URL enable for identifying the lead source and other features of the order.
                3.9. LeadSquar will provide the Advertiser with the statistics of lead performance through the Personal Account of the Advertiser.
                3.10. A period of life duration post click cookie shall be 45 (forty-five) days. If a user makes a lead during indicated period of time after transfer to the Advertiser’s web site (web site of a lead performance), that lead should be counted in favor of LeadSquar.
                3.11. The Advertiser must not take any action leading to malfunction or improper operation of the integration between LeadSquar Network, published advertising materials and the Advertiser’s web site (web site of a lead performance).
                3.12. LeadSquar has the right to engage third parties for rendering services, including distributors of advertising. The Advertiser shall not without consent of LeadSquar collaborate with above mentioned third parties on rendering services similar to that hereof for the period of 2 (two) years. If such collaboration is discovered the Advertiser shall pay a fine to LeadSquar in an amount of the cost of the similar services provided in a similar period, as if they were accommodated by LeadSquar itself.
                3.13. The Advertiser is aware and agrees that LeadSquar cannot guarantee lead performance in the amount required by the Advertiser as it directly depends on actions of the Internet users. Information about forecast of lead performance is presented by LeadSquar on basis of received statistic data. Thereupon the Advertiser understands and accepts the fact that, as considering objective reasons forecast is presumptive, LeadSquar at any conditions shall not bear responsibility for non-fulfillment of forecast of lead performance.
                3.14. LeadSquar has the right to unilaterally suspend rendering the services including the following cases:
                3.14.1. In case of the absence of correct integration for any reason;
                3.14.2. In case of a delay in payment by the Advertiser;
                3.14.3. In case of reasonable suspicions that advertising materials of the Advertiser breach any laws, regulations, or rights of third parties.
                3.15. LeadSquar acknowledges and agrees that the Advertiser reserves the right, at its sole discretion, to set any limitation on the services, including, by setting a cap, increasing or decreasing the budget or capping per any given period by providing LeadSquar with 2 (two) business days prior written notice.
                3.16. LeadSquar reserves the right to discontinue Service and withhold Advertisers’ remuneration or account balance or fine at any time and terminate the Agreement without liability to Advertiser in case of material breach of this Agreement by the Advertiser or its associates. Parties hereby agree that any form of fraudulent or illegal activity, or any violation of this Agreement, the applicable laws and regulations shall be deemed a material breach of this Agreement.
                3.17. Advertiser is solely responsible for all: (i) Contents generated by or for Advertiser; (ii) properties to which a Content directs users (including without limitation content on the domain or landing page reached by clicking on the Content URLs; and/or (iv) Advertiser’s services. Advertiser accepts and acknowledges the full responsibility in the event that the Contents in a Campaign would be deemed invalid or illegal in any applicable jurisdiction.
                3.18. Each Party waives its rights against the other in respect of warranties and representations (whether written or oral) not expressly set out or referred to in this Agreement. Nothing in this clause limits or excludes either Party’s liability for fraud.
                3.19. Hereby You represent and warrant that You have all necessary rights, permits and licenses to start and manage ad campaigns and for display Advertisement and operate Your websites and business activities in the selected jurisdictions. In case of breach of this obligation, LeadSquar may terminate this Agreement at any time without prior notice, withhold any remuneration or account balance and claim for compensation of incurred losses and damages.
                3.20. Advertiser undertakes to ensure that its servers support the traffic directed to ad campaign through our service. Anyway, LeadSquar takes no responsibility for all the consequences in case your servers cannot support the traffic directed to your website.
                3.21. You hereby agree not to use LeadSquar system interface, available to You in connection with the execution of this Agreement, in any ways not provided for by this Agreement, including not to distribute or transfer it to any third party.
                3.22. Hereby You agree not to grant any third parties the opportunity to place Ads that violate the requirements of the legislation, as well as ethics and morality rules. You shall bear all the expenses and losses incurred from Your illegal use of copyrighted materials (including Ads, trademarks, etc).
                3.23. You warrant not to use automated tools, including robots, scripts, or spiders, for generation of the inquiries or gather information from the interface of the LeadSquar Network.
                3.24. Hereby You warrant that You will not use the LeadSquar Network system interface for any purposes that violate any applicable laws or rights of any third parties, including its intellectual property.
                3.25. You agree NOT to modify, adapt, translate, disassemble or otherwise attempt to derive the source code of any software, used in LeadSquar Network, Services or Program.
                3.26. Hereby You represent and warrant to provide LeadSquar with all the documentation or its equivalents, needed for identification of the parties, ascertainment of the legal fact and fulfilment of its obligations under this Agreement, within 5 business days from the date of request. In certain cases, we may withhold all payments until we will receive relevant documentation from you.
                3.27. Hereby You irrevocably authorize LeadSquar to transfer a request received by LeadSquar to provide information for the payment directly to Your financial institution available.
                <br></br><br></br>
                4. PRICE AND SETTLEMENT<br></br>
                4.1. All statistics for the purposes of billing and general delivery reporting are based on LeadSquar’s reporting system. No other system shall be considered as an official source of information for inventory. Rates for advertising campaigns are calculated on the basis of its pricing model, frequency of impressions, ads placement, number of acquisitions, GEOs and other campaign terms.
                4.2. Payment for services provided by LeadSquar shall be made by non-cash transfer of funds to the LeadSquar bank account. The moment of payment shall be deemed as the date of receipt of funds to the LeadSquar bank account. The costs of bank commissions and charges for the transfer of funds shall be made according to the OUR principle, in accordance with the SWIFT rules and the customs of international payments.
                4.3. In the event that Advertiser believes that there is a discrepancy in LeadSquar’s reporting system (stats) for reporting period, Advertiser must provide LeadSquar with a reasoned report of such discrepancy within 3 calendar days from receipt of LeadSquar’s server reports in relevant reporting period. Otherwise, LeadSquar shall not be liable for such discrepancy, services shall be deemed rendered, and will calculate earnings on the basis of its reporting system. If the Parties are unable to reach an agreement regarding the discrepancy, then LeadSquar reporting system shall prevail.
                4.4. Advertiser is responsible for all applicable taxes associated with provided ad services, other than taxes based on LeadSquar income. Advertiser shall indemnify LeadSquar against all losses suffered or incurred by LeadSquar arising out of or in connection with any payment made to LeadSquar.
                <br></br><br></br>
                5. LeadSquar’S LIMITED WARRANTY AND LIABILITY<br></br>
                5.1. Except for the express warranties set forth above and to the extent permitted by law LeadSquar expressly disclaims all other warranties of any kind with respect to the Service, whether express or implied, including without limitation any warranties for merchantability, fitness for a particular purpose, that the Services will be uninterrupted, completely secure and/or free of software errors.
                5.2. LeadSquar furthermore expressly disclaims any responsibility in relation to (i) any claims made in relation to Ads, campaigns or any Contents or (ii) any claims made in relation to the publication of any such Ads, campaigns or Contents on any websites such as, including but not limited to, streaming sites, File Sharing Sites, and sites with adult content.
                5.3. The extent of the LeadSquar liability hereunder shall be limited by the amount of service costs for the reporting period for which breach by LeadSquar of its obligations was declared and involved infliction of losses to the Advertiser. Only actual damage shall be subject to reimbursement.
                5.4. IN NO EVENT SHALL LeadSquar BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM YOUR USE OF THE SITE, OPERATION OF A PROGRAM, OR YOUR DISPLAY OF ANY PROGRAM CREATIVE ON YOUR MEDIA, INCLUDING BUT NOT LIMITED TO BROKEN IMAGES, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES, EVEN IF LeadSquar HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE INFORMATION, CONTENT AND SERVICES AT THE PROGRAM OR IN SERVICE ARE PROVIDED ON AN “AS IS” BASIS WITH NO WARRANTY. YOU USE THE SEVICE, RUN PROGRAM AND GET SERVICES OF INDEPENDENT CONTRACTORS (PUBLISHERS) AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, LeadSquar DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE OPERATION OF PROGRAM, THE INFORMATION, SERVICES, AND CONTENT INCLUDED AT THE PROGRAM OR IN SERVICE AND PROVIDED BY LeadSquar, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. LeadSquar DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION ON ITS WEBSITE OR PROVIDED BY LeadSquar IS ACCURATE, COMPLETE OR CURRENT.
                <br></br><br></br>
                6. FRAUDULENT ACTIVITY<br></br>
                6.1. You are expressly prohibited from using any means, devices or arrangements to commit fraud, violate any applicable law, interfere with other affiliates or falsify information in connection with the Services or exceed your permitted access to LeadSquar website or Program.
                You are prohibited from any practice of disguising (cloaking) an Ads with different content or landing page and you are forbidden from using any preference/method resulting to the re-direction of the user to your landing page when such user has at least once previously chosen through a certain action to leave your page.
                LeadSquar shall have the right, in any event described under this clause, to ban Your Advertiser Account, to withhold account balance and to take all necessary legal actions to restore the damage caused by this violation. In any case LeadSquar shall make all determinations about fraudulent activity in its sole discretion.
                <br></br><br></br>
                7. INDEMNIFICATION<br></br>
                7.1. Advertiser agrees to indemnify and hold LeadSquar, its affiliates, subsidiaries, successors and assigns harmless from any and all claims, actions, judgments or liabilities arising out of or in connection with Advertiser´s Campaign, any breach of this Agreement by Advertiser and/or of any representation, warranty or agreement in this Agreement.
                <br></br><br></br>
                8. REJECTION OF ADVERTISER’S CAMPAIGN CONTENT<br></br>
                8.1. Each Party will make every effort to uphold the highest ethical and commercial standards. If LeadSquar requests that Advertisements should be removed from or not placed in any context that harms the goodwill or reputation of LeadSquar, Advertiser will promptly comply with such request.
                8.2. LeadSquar has, in its sole discretion and without any liability, the right to deny any Advertiser’s advertising material or Content that includes or based on any inappropriate or illegal content.
                If Advertiser provides software for campaign, it shall be free from any spy- or malicious software and comply with the terms and conditions under this Agreement. In confirmation of this fact the Advertiser can provide duly executed SSL-, or Code sign certificate.
                Advertiser will defend, indemnify and hold LeadSquar or its affiliates and representatives harmless from any damages, liabilities, costs, and expenses (incl. attorneys’ fees) resulting from any claim, judgment or proceeding brought by a third party.
                In case where advertisements are placed in such locations, LeadSquar reserves the right to withhold payment for the entire campaign, withhold account balance and any other remuneration and/or submit an immediate legal action against Advertiser and/or set a financial penalty, based on the damages caused to LeadSquar.
                8.3. In order to be eligible to become an Advertiser of software or other application (API), Your software or application (API) must meet the following criteria:
                • not to generate or facilitate unsolicited bulk commercial email;
                • not to violate, or encourage the violation of, the legal rights of others;
                • not to be used in any unlawful, invasive, infringing, defamatory, or fraudulent purpose;
                • not to distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other items of a destructive or deceptive nature (i.e. malware);
                • it must not to alter, disable, interfere with or circumvent any aspect of the software of third parties or advertisement services particularly.
                Advertiser will make all reasonable efforts to prevent unauthorized use of its software or application and to terminate any unauthorized use. Advertiser will promptly notify LeadSquar of any unauthorized use of, or access to, the software or application of which it becomes aware.
                Advertising software shall be installed only with the consent of the user, and shall provide ability of its removal without special additional programs.
                8.4. Advertiser further acknowledges and accepts that LeadSquar may stop a Campaign in case Advertiser’s website includes inappropriate content as described above.
                8.5. In order to ensure compliance with this section, Advertiser must notify LeadSquar in writing of any changes to the content on Advertiser’s website, which could be deemed inappropriate content.
                <br></br><br></br>
                9. LIABILITY OF THE ADVERTISER<br></br>
                9.1. In case of unilateral refusal from the advertising campaign by the Advertiser for less than 3 business days prior campaign starts the Advertiser shall pay a fine in amount of 30% of prepayment.
                9.2. In case of breach of payment time limits by the Advertiser in comparison to the deadline specified in the invoice, the Advertiser shall pay a fine in the amount of 1% of the amount not paid for each day of delay.
                9.3. In case of breach of conditions specified in the clause 3.11 the Advertiser shall pay to LeadSquar a penalty in the amount calculated by dividing average daily cost of lead amount (defined on the basis of statistics data for the period from starting performance of the request) by 24 for each hour of unavailability or failure of integration on the side of the Advertiser. The Parties agree that on revealing the fact of breach of conditions specified in the clause 3.11, the period of integration’s inoperability on the side of the Advertiser shall be calculated from the moment of the last lead fixation in LeadSquar Network.
                <br></br><br></br>
                10. PERSONAL DATA PROTECTION<br></br>
                10.1. This Agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the laws of the country in which the company was registered. Hereby LeadSquar CY LTD and Oristane Management LTD - shall be governed by and construed in accordance with the laws of Cyprus, LeadSquar PTE LTD shall be governed by and construed in accordance with the laws of Singapore, LeadSquar LP shall be governed by and construed in accordance with the laws of the Province of Alberta and Federal Laws of Canada.
                10.2. Each Party irrevocably agrees, for the sole benefit of LeadSquar that, subject as provided below, the courts of the each below country shall have exclusive jurisdiction over any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this agreement or its subject matter or formation. Nothing in this clause shall limit the right of LeadSquar to take proceedings against Advertiser in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
                10.3. Each Party confirms that it has complied, and will continue to comply with its obligations relating to Personal Data that apply to it under applicable Data Protection Laws.
                10.4. Where applicable LeadSquar (or its partners) warrants that it has provided adequate notices to and obtained valid consents from Data Subjects in each case, to the extent necessary for LeadSquar to Process their Personal Data in connection with the Agreement, including, without limitation for direct marketing activities and international transfers of Personal Data to third countries. LeadSquar is obliged to notify the Advertiser in writing within 24 (twenty four) hours from receipt of Data Subject’s objection to or withdrawal of Data Subject’s consent to process their Personal Data or other information including, without limitation for direct marketing activities and international transfers of Personal Data to third countries.
                10.5. Each Party is obliged to place Privacy Policy and ensure that said Privacy Policy describes processing of personal data by respective Party and complies with the standards implemented by Data Protection Laws at all the platforms and web-sites to which the Party is an administrator or owner and which the Party intends to use for the rendering of services under the Agreement.
                10.6. Each Party will limit access to Personal Data to those personnel who require such access only as necessary to fulfill such party’s obligation under the Agreement.
                10.7. Each party will maintain appropriate administrative, physical, organizational and technical safeguards aimed at maintaining an appropriate level of security.
                10.8. Each Party will provide the other Party with all necessary assistance in connection with communications from, or requests made by Data Subjects in relation to their rights under Data Protection Laws, and supervisory authorities, in each case as they relate to Data Subject Personal Data.
                10.9. Each Party to the best extent possible will provide the other Party assistance in complying with the Data Protection Laws.
                10.10. LeadSquar and the Advertiser each agree and acknowledge that where a Party processes Personal Data and alone determines the purposes and means of such Processing, such Party shall be considered as a data controller (as defined under applicable Data Protection Laws). In cases where LeadSquar processes any Personal Data on the Advertiser’s behalf when performing its obligations under this Agreement, it shall be considered as a Processor.
                10.11. LeadSquar shall only process Personal Data for the following permitted purposes in relation to advertising campaigns:
                10.11.1. for providing services under this Agreement;
                10.11.2. for fraud detection purposes including creating fraud reports to be shared with the other Party;
                10.11.3. for reporting purposes including reports to be shared with the other Party;
                10.11.4. for determining performance of campaigns distributed through LeadSquar inventory or network and billing purposes.
                10.12. As a Processor LeadSquar shall:
                10.12.1. process the Personal Data only to the extent necessary for the purposes of the Agreement and otherwise in accordance with the documented instructions of the Advertiser (Controller);
                10.12.2. not process the Personal Data in any country outside the European Economic Area other than in accordance with the terms of this section. If LeadSquar is required by applicable laws or under the terms of the Agreement to transfer the Personal Data outside of the European Economic Area, LeadSquar shall execute appropriate documentation as required under Data Protection Laws (unless LeadSquar is barred from making such notification under the relevant applicable law);
                10.12.3. ensure that all persons authorized by it to process the Personal Data are committed to confidentiality or are under a statutory obligation of confidentiality under applicable law;
                10.12.4. have at all times during the term of the Agreement appropriate technical and organizational measures to ensure a level of security appropriate to the risk to protect any Personal Data, with particular regard to its accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access. Where applicable technical and organizational measures may include the following measures, but not limited to:
                ▪ restriction of access to data centers, systems and server rooms as necessary to ensure protection of Personal Data;
                ▪ monitoring of unauthorized access;
                ▪ written procedures for employees, contractors and visitors covering confidentiality and security of information;
                ▪ restricting access to systems depending on the sensitivity/criticality of such systems;
                ▪ use of password protection where such functionality is available;
                ▪ maintaining records of the access granted to which individuals;
                ▪ ensuring prompt deployment of updates, bug-fixes and security patches for all systems;
                ▪ providing anonymization (encryption, pseudonymization) measures where applicable and required by Data Protection Laws.
                10.12.5. where LeadSquar does engage another Processor (Sub-processor), similar obligations to those set out in this Agreement shall be imposed by LeadSquar on the Sub-processor in a written contract. For the avoidance of doubt, terms indicated this section shall also apply to any Sub-processor. LeadSquar shall remain liable to the Advertiser for the performance of Sub-processor’s obligations;
                10.12.6. cease processing the Personal Data immediately upon the termination or expiry of Agreement or, if sooner, on cessation of the contractual activity to which it relates and, at the Advertiser’s election, delete or return all Personal Data to the Advertiser, and delete all existing copies unless applicable law requires their retention;
                10.12.7. LeadSquar shall not retain Personal Data for longer than necessary to meet the permitted purposes hereunder or use the same for any purposes other than such permitted purposes;
                10.12.8. if requested by the Advertiser, LeadSquar shall without delay, rectify the Personal Data, to ensure it remains accurate, complete and current or delete the same upon notification by the Advertiser to honor any Data Subject’s request. The Advertiser agrees to notify LeadSquar of such requests immediately;
                10.12.9. at the earliest opportunity, without undue delay, and in any event within 48 hours after having become aware, notify the Advertiser of any unauthorized or unlawful processing of any Personal Data and of any loss or destruction or other damage and shall take such steps consistent with good industry practice to mitigate the detrimental effects of any such incident on the Data Subjects and cooperate with the Advertiser in dealing with such incident and its consequences;
                10.12.10. LeadSquar acknowledges that the Advertiser is under certain record keeping obligations under the Data Protection Laws, and agrees to provide the Advertiser with all reasonable assistance and information required by the Advertiser to satisfy such record keeping obligations.
                10.13. Each Party shall defend, indemnify and hold harmless the other Party (Indemnified Party) against any and all claims, demands, actions, awards, judgments, settlements, costs, expenses, liabilities, damages and losses (including all interest, fines, penalties, management time and legal and other professional costs and expenses) incurred by Indemnified Party arising from Indemnifying Party’s (and/or its representatives) failure to comply with Data Protection Laws. In no event shall LeadSquar total liability to the Advertiser under this article exceed EUR 1,000.
                <br></br><br></br>
                11. APPLICABLE LAW AND DISPUTE RESOLUTION<br></br>
                11.1. This Agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the laws of the Province of Quebec and Federal Laws of Canada without giving effect to conflict of laws principles.
                11.2. Each Party irrevocably agrees, for the sole benefit of LeadSquar that, subject as provided below, the courts of the Province of Quebec and the courts of Canada shall have exclusive jurisdiction over any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this agreement or its subject matter or formation. Nothing in this clause shall limit the right of LeadSquar to take proceedings against Advertiser in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
                <br></br><br></br>
                12. CANCELLATION<br></br>
                12.1. Either Party may cancel the ads campaign and terminate this Agreement with 48 hours’ written notice to the other Party.
                12.2. LeadSquar shall be entitled, with immediate effect, to stop Advertiser’s Campaign or to prematurely terminate this Agreement in writing where: (a) Advertiser uses the Service or Program in a manner that entails the perpetration of a crime; (b) Advertiser uses the Service or Program in a manner that occasions losses or the risk of loss for LeadSquar or any third Party; (c) it may be reasonably assumed that Campaign violates governing law; (d) notwithstanding reminders, Advertiser fails to pay agreed fees or any other remuneration to LeadSquar within a stated time; (e) Advertiser otherwise fails to comply with this Agreement and such breach of contract is material; or (f) Advertiser is placed into insolvent liquidation or is otherwise insolvent.
                In this case, LeadSquar shall have the right to block your account immediately and to withhold the remaining funds at your account as a fine.
                12.3. You acknowledge and agree that in case of Your account been deleted at any reason it means that user data would be erased too.
                <br></br><br></br>
                13. MISCELLANIOUS<br></br>
                13.1. LeadSquar may assign this Agreement to a subsidiary or business successor. You may not assign this Agreement without the prior written consent of LeadSquar, which shall not be unreasonably withheld.
                13.2. This Agreement is the principal document in legal relationship of the Parties, and shall be deemed an entire agreement of the Parties. In case of contradictions in using Service or Program, the Agreement shall prevail in any case. Any documents placed on Your websites shall not be binding on LeadSquar.
                13.3. The relationship between the Parties will be that of independent contractors and nothing in this Agreement is intended to nor will establish any relationship of partnership, joint venture, employment, franchise, agency or other form of legal association between the Parties. Neither Party will have, nor represent to any third party that it does have, any power or authority to bind the other Party or incur any obligations on the other Party’s behalf.
                13.4. The failure of a Party hereto at any time or times to require performance of any provision hereof shall in no manner affect its right at a later time to enforce the same. No waiver by a party of any condition or of any breach of any term, covenant or representation contained in this Agreement shall be effective unless in writing, and no waiver in any one or more instances shall be deemed to be a further or continuing waiver of any such condition or breach in other instances or a waiver of any other condition or breach of any other term, covenant or representation.
                13.5. The representations and warranties of Advertiser set forth in this Agreement shall survive closing for a period of one year from the termination date.
                13.6. No claim for a breach of any representation or warranty by LeadSquar shall be actionable or payable if the breach in question results from or is based on a condition, state of facts or other matter, which was disclosed to Advertiser and/or actually known by Advertiser prior to termination.
                13.7. All claims related to the use of the Service or Program shall be submitted by the Advertiser within 30 days from the end of the reporting period only. In the case of missing the specified term, LeadSquar reserves the right not to process the complaint, and all the services shall be deemed rendered properly and subject to payment.
                13.8. Headings to sections and subsections in this Agreement are for the convenience of the Parties only and are not intended to be a part of or affect the meaning or interpretation hereof.
                13.9. The Parties agree that any notice, which shall be given in accordance with the legislation or this Agreement can be transferred by sending scanned copies of the originals signed and sealed by the Parties by e-mail using e-mail addresses usually used by the Parties during their business relations. The Parties recognize and equate such scanned copies with original documents duly executed and valid, and waive any claims to the contrary. The Parties recognize these documents as appropriate evidence that can be used as such in resolving disputes, which includes any court procedures. The exchange of scanned signed copies does not exclude the obligation of the Parties to send each other originals of the relevant documents by post or by courier.
                13.10. The Parties shall inform each other within the shortest possible time on changes of address, bank details, telephone numbers, e-mail addresses and authorized representatives. Anything done by a Party using the last notified to it details of the other Party shall be considered as done properly, and all the costs and risks shall be borne by a Party that failed to notify the other Party immediately about changes in its details.

                <br></br><br></br>
                <br></br><br></br>
                PUBLISHER AGREEMENT
                <br></br><br></br>
                Publisher Agreement (the “Agreement”)
                LeadSquar CY LTD (Cyprus), LeadSquar LP (Canada), LeadSquar PTE LTD (Singapore), or Oristane Management LTD (Cyprus) (the, “LeadSquar”, “We”, etc.) being an advertising network that provide services for products monetization and promotion, connecting publishers and advertisers through such service globally (the, “Service”), and
                You (the, “Publisher”, “You”, “Yours”, etc.) being the webmaster or the owner of the website(s) and having sufficient authority to enter into this Agreement, that seeks a service to attract advertisers including to such website(s) (the, “Publisher’s web site”, “Your website”, “Site”, etc.),
                WHEREAS,
                LeadSquar has offered its service to the Publisher through www.LeadSquar.com website (the, “Program”, etc.) and Publisher’s personal account, and you decided to utilise the Service,
                NOW,
                LeadSquar and Publisher hereby agree as follows:
                BY CHECKING THE BOX AND CLICKING "I ACCEPT" BUTTON, AS APPLICABLE, OR BY CONTINUING TO PARTICIPATE IN THE SERVICE FOLLOWING OUR PUBLICATION OF THE REVISED VERSION OF THIS AGREEMENT ON OUR WEBSITE, YOU (A) CONFIRM THAT YOU ARE AWARE AND COMPLY WITH THIS AGREEMENT AND AGREE TO BE BOUND BY THIS PUBLISHER AGREEMENT; (B) ACKNOWLEDGE AND AGREE THAT YOU HAVE INDEPENDENTLY EVALUATED THE DESIRABILITY OF PARTICIPATING IN THE PROGRAM AND ARE NOT RELYING ON ANY REPRESENTATION, GUARANTEE, OR STATEMENT OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT; (C) HEREBY REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED AND LAWFULLY ABLE TO ENTER INTO THIS AGREEMENT AND THAT YOU ARE AND WILL REMAIN IN COMPLIANCE WITH THIS AGREEMENT; AND (D) AGREE TO RECEIVE DIRECTLY TO YOUR E-MAIL NEWSLETTERS, TRAFFIC MONETIZATION INSIGHTS, LATEST PROMOTIONS, CASE-STUDIES AND TUTORIALS FROM US.
                Out of concern for the environment, this Agreement is concluded exclusively in electronic form and no paper copy will be produced. This Agreement concluded in electronic form shall be deemed equivalent to a paper document bearing a handwritten signature and a seal. At the request of the Party, this Agreement or any additional documents to it, accounting and other documents signed by electronic document exchange shall be replaced by a paper document signed by handwritten signature and sealed within 20 business days from the date from the receipt of the Party's written request. In this case, the costs for sending the requested documents shall be borne by the Party, which sent the relevant request.
                <br></br><br></br>
                1. DEFINITIONS<br></br>
                1.1. “Ad(s), Advertisement(s)” – means graphical, interactive, rich media and video, or other online advertisements, including, without limitation, banners, buttons, towers, skyscrapers, pop-ups, pop-unders and video advertisements or similar generated by you or advertiser’s web-servers in response to a query from LeadSquar.
                1.2. “Publisher” – means a party that has decided to enter into this Agreement and to assign LeadSquar to provide online services in accordance with the terms and conditions of this Agreement.
                1.3. “Publisher Account” / “Account” – means the Publisher’s account at LeadSquar website www.LeadSquar.com.
                1.4. “Content” – means textual, visual, or aural content that is encountered as part of the Publisher’s websites. It may include documents, data, applications, e-services, images, audio and video files, personal web pages, archived e-mail messages, and etc.
                1.5. “Effective Date” – means the date of adoption by Publisher terms of this Agreement or in the absence of its signature, the date when the Publisher set up a Publisher Account with LeadSquar.
                1.6. “LeadSquar Network” – means LeadSquar’s digital advertising network available at www.LeadSquar.com, including advertisers and publishers.
                1.7. “LeadSquar Network Property” – means any website, application, content, property or any other media owned, operated, or provided by a company within the LeadSquar Network upon which Ads are placed.
                1.8. “Confidential Information” – will include any information, whether provided in writing, orally, visually, electronically or by other means, related to the Services and/or business of a party and is treated as confidential or secret by the party, including but not limited to (i) all information marked as “Confidential,” “Proprietary,” or similar legend by the disclosing party (“Discloser”) when given to the receiving party (“Recipient”); and (ii) information and data provided by the Discloser, which under the circumstances surrounding the disclosure should be reasonably deemed confidential or proprietary.
                <br></br><br></br>
                2. THE SERVICE<br></br>
                2.1. In order to become a Publisher, you must first accurately submit an application for LeadSquar account at our website and be in compliance with this Agreement or register as a Publisher by contacting LeadSquar directly for acceptance, and not use any aliases or other means to mask your true identity or contact information. After we review your application, we will notify of your acceptance or rejection as LeadSquar Publisher. We may accept or reject your account registration at any time at our sole discretion for any reason. LeadSquar reserves the right to add, edit, remove or reclaim any account details (including your submissions) with or without your consent if deemed appropriate at LeadSquar sole discretion.
                2.2. By filing your account application or registering as a Publisher you confirm your understanding and unreserved acceptance of this Agreement and other terms and conditions of LeadSquar, including, but not limited to the Privacy Policy, published at our website concerning the Services, and confirm you are a duly authorized signatory, has full legal capacity and all the necessary authority to bind the individual, company or other entity, and hereby submitting a legally binding electronic signature and entering into a legally binding contract.
                2.3. In order to be eligible to become LeadSquar Publisher, all websites must meet the following criteria:
                • Be content-based, not simply a list of links or advertisements, nor can the site be centered around making money off of our advertisers;
                • Be fully functional at all levels; no “under construction” sites or sections; and
                • Shall comply with the applicable legislation.
                2.4. The content of the Publisher’s website(s) or its affiliated website(s) or websites where it places Ads can not include any material that infringes the rights of any third party or is in violation of any law, as bound by the law or determined by us in our sole discretion, including but not limited to the following:
                • Intellectual property rights;
                • Racial, ethnic, political, hate-mongering or otherwise objectionable content;
                • Investment, money-making opportunities or advice not permitted under law;
                • Gratuitous violence or profanity;
                • Material that defames, abuses, or threatens physical harm to others;
                • Promotion of illegal substances or activities (e.g. illegal online gambling, “how to build a bomb”, counterfeiting money, etc.);
                • Software Pirating (e.g., Warez, P2P, Bit torrent, Hotline, etc.);
                • Hacking or Phreaking;
                • Fraud, unofficial, untrue, false, misleading, invented, re-produced information, facts, news, offers, solutions, guidelines related to or aiming to treat in any way and at any level vulnerabilities of all kind, including but not limited to any physical, mental, psychological, social, religious, economic, scientific vulnerabilities;
                • Any illegal activity whatsoever;
                • Any spoofing, redirecting, or trafficking from adult-related websites in an effort to gain traffic; or
                • Any other inappropriate activity as determined by us in our sole discretion.
                2.5. LeadSquar has the following Non Acceptable Business rules for Publishers:
                • Where there are known or perceived links to terrorist organisations, military, arms and/or ammunition manufacture or sales;
                • Where there is knowledge or suspicion of money laundering or terrorist financing;
                • Where it is known or there are reasonable grounds for suspicion that a criminal offence has taken place;
                • Where the client or any of the clients associated parties are subject to any sanctions measures;
                • Where the client is undertaking an activity or trade within, from or into a country where that activity is subject to embargo and/or trade control restrictions;
                • Producers/publishers of racist/pornographic/pressure group material or extreme political propaganda;
                • Regulated entities that do not have the appropriate licensing;
                • Extreme political and/or charitable organisations.
                2.6. Publisher understands and accepts that LeadSquar does not allow and prohibits the multiple account opening for each Publisher. Publisher agrees not to fill in an account application and/or register as a Publisher more than one time and/or hold more than one account with LeadSquar for any reason and/or in order to benefit in any way from any marketing promotional program/project and/or offer available for the Publisher by LeadSquar.
                2.7. LeadSquar may allow multi account applications/registrations/openings/holdings for a Publisher if this is specifically and clearly predefined as accepted/permitted in any specific marketing promotional program/project and/or offer and/or in exceptional cases, at any time and for any reason LeadSquar considers acceptable and solely at its own discretion, if the Publisher submits such request by sending an email to contact@LeadSquar.com.
                2.8. You may not transfer your account to anyone without explicit written permission of LeadSquar and you may not use anyone else’s account or password at any time without the express permission and consent of the holder of that account. LeadSquar cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations.
                2.9. Minimum Eligibility Requirements.
                In order to be eligible to become a Publisher, all websites, affiliated websites and e-mail distribution lists (collectively the "Media") must meet the following criteria, at a minimum: All Publishers that wish to send advertisements via email must have the consent of the consumer to send such email and each Publisher shall maintain records evidencing such consent including, without limitation:
                ● I: Member opt-in date
                ● II: Registration source
                ● III: First name
                ● IV: Last name
                ● V: Address
                ● VI: Email address
                ● VII: Any other information collected and will supply such records to LeadSquar within one business day of request thereof
                Unless otherwise approved in writing by LeadSquar, Publishers may not offer incentives to users as means to enhance the performance of any Program (as defined below); incentives include but are not limited to awarding them cash, points, prizes, contest entries, etc.; Publisher's policies must be compliant with state and federal laws and regulations including but not limited to the CAN-SPAM Act of 2003 Spawning process pop-ups are prohibited; and Such other criteria as LeadSquar may from time to time determine, in its sole discretion.
                2.10. Special Rules Governing Email Campaigns.
                2.10.1. In the event that LeadSquar or Publisher receives a complaint from any recipient of a Program transmitted by Publisher, upon our request Publisher will immediately provide LeadSquar with appropriate records verifying that recipient's consent to receive email transmissions from Publisher. Such records include, but may not necessarily be limited to, the Internet address of Publisher's opt-in/opt-out website, the date of the recipient's action, and Publisher's privacy policy.
                2.10.2. Publisher may not use an advertiser's name (including any abbreviation thereof) in the originating email address line or subject line of any email transmission.
                2.10.3. No Misleading Headers or Other Masking of Email Origin. An email may not include falsification of header information, false registrations for email accounts or IP addresses used in connection with email ads, and retransmissions of an email ad for the purpose of concealing its origin. Publisher and/or their email delivery providers are prohibited from relaying or retransmitting emails from a computer or computer network that was accessed without authorization.
                2.10.4. Subject lines may not be false or misleading such that it would likely mislead a reasonable recipient as to the contents or subject matter of the message. Publisher may only use approved Specified Advertising Materials, including subject lines available provided by LeadSquar or subject lines for which Publisher has documented approval from LeadSquar.
                2.10.5. Specified Advertising Materials consisting of email messages must contain clear identification. Messages containing advertisements or solicitations must identify themselves as such, and do so by "clear and conspicuous" means, for example, by stating in the message body "This advertisement is brought to you by (Your Company)". Further, the sender must identify itself as the initiator and sender of the email including company name, email and physical address.
                2.10.6. Effective Method of Opting Out of Future Mailings. Senders of commercial emails must give recipients an effective means of requesting not to receive future email ads from that sender. At a minimum, the publisher must give the recipient the ability to send a reply message to unsubscribe, opt out via postal letter and provide a functioning unsubscribe link that must remain in operation for 30 days from the date of the original email transmission.
                2.10.7. All unsubscribe requests must be implemented within 10 business days from their receipt. You may not sell or transfer an email address once someone has opted out of receiving future communications, whether from only the advertiser or globally.
                2.10.8. No Random or Invalid Generation of Email Addresses. Publisher is responsible for knowing the source of its email list. Email addresses may not be obtained by the use of a program for random generation of email addresses, and/or "scraping" websites or online services. Publisher must have full opt-in data for all recipients in its database.
                2.11. Non-Solicitation.
                Publisher agrees that Publisher will not approach or attempt to engage in a contract with any of LeadSquar's clients (each such client, individually a "Client" and all such clients, collectively "Clients") directly or indirectly via a Client's ad agency, broker or any other person or entity. LeadSquar will promptly reply in writing to any inquiries received from Publisher regarding the status of any person or entity as a LeadSquar Client so as to aid Publisher in its efforts to comply with the non-solicitation provisions of this Agreement. Because LeadSquar will be irreparably harmed by Publisher's conduct, and because the true extent of such harm will be impossible to quantify, monetary damages will not be an adequate remedy for any such conduct. Publisher agrees that LeadSquar shall be entitled to injunctive relief precluding Publisher from taking or continuing any action or conduct in violation of this provision, to be issued by any court of competent jurisdiction upon a showing of any such violative conduct by Publisher.
                2.12. Links.
                Publisher agrees to use the Links in the exact form that we deliver them to Publisher. Publisher agrees not to modify, alter, delete, or adapt the Links in any manner without LeadSquar's written approval. Links must be served from the LeadSquar server, unless otherwise permitted in writing by LeadSquar. Publisher shall not take any actions to impede the action of or to disable any such links. Publisher agrees to, if request by LeadSquar, modify or alter Links or Tracking devices in the manner requested by LeadSquar. Publisher further agrees that it shall in no event modify or interfere with Tracking devices unless specifically instructed to do so by LeadSquar in accordance with the previous sentence.
                <br></br><br></br>
                3. PLACEMENT OF ADS<br></br>
                3.1. Publisher will not place advertisement on pornographic/offensive, and/or warez, and/or illegal MP3 sites/directories, and/or P2P/Bit-Torrent sites, and/or Spyware or malicious code of any sort and/or alternatively questionable areas. In the case whereby advertisements are placed in such sites/directories, LeadSquar reserves the right to withhold payment for the entire campaign and/or submit an immediate legal action against the Publisher and/or set a monetary fine in the amount based on the damages caused to LeadSquar.
                3.2. LeadSquar do not check or control the activities or contents at websites where you place Ads, but all the services may be rejected and we reserve the right to delete your account, withhold and freeze all fees and remunerations if you engage in fraudulent or illegal activity.
                <br></br><br></br>
                4. PUBLISHER EARNINGS<br></br>
                4.1. Cost of using Service depends on the amount and scope of advertising campaigns carried out by the Publisher during the reporting period based on ads placements generated by LeadSquar reporting system (stats), available in your personal account. All reported statistics for the purposes of billing and general delivery reporting are based on LeadSquar reporting system only.
                4.2. In the event that Publisher believes that there is a discrepancy in LeadSquar’s reporting system, Publisher must provide LeadSquar with a reasoned report of such discrepancy within three (3) calendar days from receipt of LeadSquar’s reports. Otherwise, LeadSquar shall not be liable for such discrepancy, and will calculate earnings on basis of its reporting system. If the Parties are unable to reach an agreement regarding the discrepancy, then LeadSquar stats and reports shall prevail.
                4.3. LeadSquar is entitled to make adjustments in Publisher’s account in one of the following cases:
                • To pay promotions and bonuses
                • Due to technical reasons
                • Due to Publisher’s fraudulent activity
                • On the basis of additional agreements with You
                • Due to Advertiser’s complaints or refunds
                <br></br><br></br>
                5. PAYMENTS<br></br>
                5.1. Publisher will be paid per the terms of each Campaign. LeadSquar shall pay any amounts due approximately 15 days after the cashout request done at the platform, less any taxes required to be withheld under applicable law, provided that LeadSquar may, in its discretion, withhold payments until such time as the Advertiser has paid LeadSquar for any Campaign. In addition to any other remedies that may be available to LeadSquar, in the event of any breaches by Publisher of these Terms and Conditions, Publisher shall forfeit its rights to any amounts owed by LeadSquar to Publisher. LeadSquar reserves the right to withhold or reduce any payments owed to Publisher as a consequence of any offsets taken by Advertisers for fraudulent traffic, invalid Events, technical errors, tracking discrepancies and the like. LeadSquar shall compile, calculate and electronically deliver data required to determine Publisher's billing and compensation. Any questions regarding the data provided by LeadSquar need to be submitted in writing within 10 business days of receipt, otherwise the information will be deemed accurate and accepted as such by Publisher.
                5.2. LeadSquar will not pay for any Events that occur before a Campaign is initiated, or after a Campaign terminates. Invoices submitted to LeadSquar and payments made to Publisher shall be based on the events as reported by LeadSquar. LeadSquar will not be responsible to compensate Publisher for events that are not recorded due to Publisher's error.
                5.3. LeadSquar provides the ability to perform payments by using payment service providers. Publisher shall have the right to select any payment service provider available. You agree that LeadSquar is not responsible for any actions made by the payment service provider including but not limited to any additional transaction fees, banking commissions or currency fees applied to your transaction. All payments shall include the above-mentioned fees and commission, if applicable.
                5.4. Publisher is responsible for all applicable taxes associated with provided Services, other than taxes based on LeadSquar income. Publisher shall indemnify LeadSquar against all losses suffered or incurred by the LeadSquar arising out of or in connection with any payment made to the Publisher.
                5.5. Publisher responsible to supply valid payment details in personal account of our Service, if details are wrong or if the Publisher change its payment details, it is the Publisher’s responsibility to notify by mail 14 days before payment due date. Publisher will bear payments fees if required. In any event, all payments will be made at the payment details specified in your personal account in our Service.
                5.6. All payments are processed automatically. We may, in our sole discretion, refuse to process a payment (and may place a payment hold) on any part of your account for any reason, block your account and terminate this Agreement, including if we have a reasonable suspicion that you have breached any clause of this Agreement. We also reserve the right to set-off any amount you owe us, including for breaches of this Agreement. We assume no responsibility for paying any taxes on payments made to you, and you acknowledge and agree that it is your complete and sole responsibility to pay for all taxes as a consequence of your participation in the Program.
                5.7. Hereby you represent and warrant to provide LeadSquar with all the documentation or its equivalents, needed for identification of the parties, ascertainment of the legal fact and fulfillment of its obligations under this Agreement, within 15 business days from the date of request. In certain cases, we may withhold all payments until we will receive relevant documentation from you.
                5.8. You on your own shall ensure the ability to receive payments from LeadSquar to specified bank account or at relevant payment provider. If the receipt of remuneration or other payment is delayed or failed because of your non-compliance with this clause 6 (including if the failure or delay is caused by a third party payment service provider you are using), LeadSquar shall not be responsible for violation of terms of payment.
                5.9. If you believe that any fault in transaction has taken place, you agree to notify us immediately, and We will make all possible efforts to eliminate delays or errors in payment processing. Unless your claim been submitted within 30 days after the charge, you will have waived, to the fullest extent permitted by law, all claims against LeadSquar related to the transaction. If you experience a technical failure or interruption of services that causes your funding transaction to fail, you may request that your transaction be completed at a later time.
                5.10. By entering into this Agreement, you agree to receive Publisher’s revenue as from LeadSquar, or from its affiliates, subsidiaries, agents, sub-contractors or distributors.
                <br></br><br></br>
                6. REPRESENTATIONS, WARRANTIES AND COVENANTS<br></br>
                • You represent, warrant and covenant that: all advertising materials created by you, all the websites where you place advertising materials is in compliance with all applicable laws and terms and conditions of this Agreement, and does not contain or promote, nor links to another website that contains, libelous, defamatory, abusive, violent, prejudicial, obscene, infringing, sexually explicit or illegal content, including copyright ownership infringements and unlawful use of intellectual property;
                • You agree not to promote via advertising materials, website or link to websites containing any pornographic, racial, ethnic, political, software pirating (e.g. Warez) or hacking, hate-mongering, or otherwise objectionable content;
                • You agree not to engage in any illegal activity, in accordance with applicable law, whatsoever, is not allowed;
                • You represent and warrant that you own or have legal rights to use and distribute all content, copyrighted material of the advertising materials created by you; you agree not to use deceit when marketing advertiser’s offers or presenting these offers to consumers; you have the right, power, and authority to enter into this Agreement and grant the rights specified herein;
                • You will not attempt in any way to alter, modify, eliminate, conceal, or otherwise render inoperable or ineffective the website tags, source codes, links, pixels, modules or other data provided by or obtained from LeadSquar that allows LeadSquar to measure ad performance and provide its service (“Site Data”);
                • If instructed to do so by LeadSquar and/or if this Agreement terminates, you will immediately remove and discontinue the use of any Site Data;
                • You acknowledge that LeadSquar does not represent, warrant, or make any specific or implied promises as to the successful use of Service;
                • You agree to display the creative exactly as it appears on the Program and will not alter any creative that has been submitted to the Site;
                • You agree to display the creative exactly as it appears on the Service and will not alter any creative that has been placed through the Service;
                • If you are notified that fraudulent activities may be occurring on your website, and you do not take any actions to stop the fraudulent activities, then you are responsible for all associated costs and legal fees resulting in these fraudulent activities;
                • You represent, warrant and covenant that you will not take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large loan on our technology infrastructure or otherwise make excessive demands on it;
                • You may not disable, circumvent or otherwise interfere with security related features of our Service or features that prevent or restrict use or copying of any part of our Service, or which enforce limitations on the use of our Service;
                • Hereby You irrevocably authorize LeadSquar to transfer a request received by LeadSquar to provide information for the payment directly to your financial institution available;
                • You represent, warrant and covenant that your advertising materials and websites where you place advertising materials does not contain any sexual or erotic material that depicts persons under the age of eighteen (18) or in a manner that suggests that they are under the age of eighteen (18);
                • If any errors or undesirable results occur due to no fault of LeadSquar, LeadSquar shall not be responsible for losses and you may not be compensated;
                • Publisher undertakes to ensure that its servers or servers of websites where advertising materials are placed support the traffic directed to ad campaign through our Service. LeadSquar takes no responsibility for all the consequences in the event the servers cannot support the traffic directed to a website. You shall test the website to insure its correct appearance in different web browsers, devices or systems and optimize it if necessary.
                • You acknowledge that every case of violation of the terms of this Agreement will lead to material and business standing losses of LeadSquar in the amount of at least US $ 1,000. Therefore, we reserve the right to recover damages caused in the specified amount, or the amount of actually incurred losses, in the event of your breach of contract. Such losses may be deducted from the balance of your personal account in the Service.
                <br></br><br></br>
                7. FRAUDULENT ACTIVITY<br></br>
                7.1. YOU MAY NOT CHEAT, DEFRAUD OR MISLEAD US OR ADVERTISER(S), OR ATTEMPT TO CHEAT, DEFRAUD OR MISLEAD US OR ADVERTISER(S), IN ANY MANNER.
                You are expressly prohibited from using any means, devices or arrangements to commit fraud, violate any applicable law, interfere with other affiliates or falsify information in connection with the Services or generating of remuneration or exceed your permitted access to LeadSquar Service. You are forbidden from using any preference/method resulting to the re-direction of the user to your landing page when such user has at least once previously chosen through a certain action to leave your page. These prohibited activities include but not limited to: framing an ad-banner’s click-through destination, invisible iframe, auto-spawning of browsers, running “spiders”/”bots”, and automatic redirecting of users or any other technique of generating automatic or fraudulent click-through and/or impressions. Ads may not be placed on an automatically reloaded page. In any case LeadSquar shall make all determinations about fraudulent activity in its sole discretion.
                7.2. If Publisher is suspected in any fraudulent activity LeadSquar shall have the right to ban Your Publisher Account, to withhold account balance and to take all necessary legal actions to restore the damage caused by this violation. All advertising campaigns carried out on Publishers websites with fraudulent activities are not subject for payment.
                <br></br><br></br>
                8. LIMITATION OF LIABILITY; DISCLAIMER OF WARRANTY<br></br>
                IN NO EVENT SHALL LeadSquar BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM YOUR USE OF THE SERVICE, OPERATION OF A PROGRAM, OR ADVERTISING MATERIALS CREATED OR PLACED BY YOU, OR YOUR DISPLAY OF ANY PROGRAM CREATIVE ON ANY WEBSITE, INCLUDING BUT NOT LIMITED TO BROKEN IMAGES, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES, EVEN IF LeadSquar HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. LeadSquar IS ONLY THE TOOL FOR ADS CAMPAIGNS CONNECTING ADVERTISERS AND PUBLISHERS THROUGH ITS SERVICE. THE INFORMATION, CONTENT AND OTHER LeadSquar SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITH NO WARRANTY. YOU USE THE SERVICE, RUN PROGRAMS AND ACCEPT SERVICES TASKS OF ADVERTISER(S) AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, LeadSquar DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE OPERATION OF THE SERVICE, THE INFORMATION, AND CONTENT INCLUDED ON THE SERVICE AND PROVIDED BY LeadSquar, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. LeadSquar DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION ON THIS SERVICE OR WEBSITE OR PROVIDED BY LeadSquar IS ACCURATE, COMPLETE OR CURRENT.
                <br></br><br></br>
                9. INDEMNITY<br></br>
                You shall indemnify, defend and hold LeadSquar harmless from and against any and all claims, allegations, liabilities, costs and expenses (including reasonable attorneys´ fees) which may be incurred by or to the third parties arising out of your: (a) improper use of the Service; (b) improper operation of a Program; or (c) breach or violation of any clause of this Agreement or other mutual agreement of its parties.
                <br></br><br></br>
                10. GOVERNING LAW AND JURISDICTION<br></br>
                10.1. This Agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the laws of the Province of Alberta and Federal Laws of Canada without giving effect to conflict of laws principles.
                10.2. Each Party irrevocably agrees, for the sole benefit of LeadSquar that, subject as provided below, the courts of the Province of Alberta and the courts of Canada shall have exclusive jurisdiction over any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this agreement or its subject matter or formation. Nothing in this clause shall limit the right of LeadSquar to take proceedings against Advertiser in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.
                <br></br><br></br>
                11. INTELLECTUAL PROPERTY RIGHTS<br></br>
                11.1. Hereby we grant you a non-exclusive, non-transferable, revocable right to use LeadSquar Service and to access our website through our Service only in accordance with the terms and conditions of this Agreement.
                11.2. You may not alter, modify, manipulate or create derivative works of LeadSquar or any our graphics, creative, copy or other materials owned by, or licensed to LeadSquar in any way. We may revoke your license anytime by giving you written notice. Except as expressly stated herein, nothing in this Agreement is intended to grant you any rights to any of LeadSquar’ trademarks, service marks, copyrights, patents or trade secrets. You agree that we may use any suggestion, comment or recommendation you choose to provide to LeadSquar without compensation. All rights not expressly granted in this Agreement are reserved by LeadSquar.
                11.3. Your use of the Service shall be governed by and subject to the laws and regulations regarding copyright ownership and terms of use of intellectual property. You represent, warrant and covenant that you do not upload, download, display, perform, transmit, or otherwise distribute any object in violation of any third party’s copyrights, trademarks, or other intellectual property rights. You represent, warrant and covenant that you abide by the laws regarding copyright ownership and use of intellectual property and you shall be solely responsible for any violations of any relevant laws and for any infringements of third party rights caused by you.
                11.4. ALL THE PARTIES HEREBY AGREE THAT LeadSquar DOES NOT HAVE ANY AUTHORITY OR ABILITY TO CONTROL CONTENT MADE BY THE PUBLISHER OR AT WEBSITE(S) WHERE PUBLISHER PLACES ADS AND FOR THIS REASON, WE CANNOT BEAR ANY RESPONSIBILITY REGARDING BREACHING OF ANY THIRD PARTY’S INTELLECTUAL PROPERTY RIGHTS OR OTHER RIGHTS.
                <br></br><br></br>
                12. TERMINATION<br></br>
                12.1. This Agreement shall commence upon your acceptance and remain in effect until terminated. This Agreement may be terminated by either Party upon one business days´ notice. This Agreement shall terminate immediately upon the dissolution or insolvency of either Party, or the breach of this Agreement by you. LeadSquar reserves the right, in its sole and absolute discretion, to terminate a campaign and remove any advertisements at any time for any reason.
                <br></br><br></br>
                13. MISCELLANEOUS<br></br>
                13.1. LeadSquar may assign this Agreement to a subsidiary or business successor. You may not assign this Agreement without the prior written consent.
                13.2. This Agreement contains the sole and entire agreement and understanding between the Parties relating to the subject matter herein, and merges all prior discussions, whether through officers, directors, salespersons, employees or consultants.
                13.3. This Agreement is the principal document in legal relationship of the Parties, and shall be deemed an entire agreement of the Parties. In case of contradictions in using Service or Program, this Agreement shall prevail in any case. Any documents placed on Your websites shall not be binding on LeadSquar.
                13.4. If any provision of this Agreement is held to be invalid, illegal or unenforceable for any reason, such invalidity, illegality or unenforceability shall not affect any other provisions of this Agreement, and this Agreement shall be construed as if such invalid, illegal or unenforceable provision had not been contained herein.
                13.5. Each Party is an independent contractor and not a partner, joint venture or employee of the other. All notices shall be sent to the addresses submitted by you when signing up for the Service by certified mail, fax, email or courier.
                13.6. LeadSquar reserves the right to change any terms and conditions of this Agreement at any time unilaterally. The Publisher shall be informed of such amendments by relevant notice in personal account or through the information being made available on LeadSquar’s website. The Publisher shall be deemed to have received such notice within the next business day of the notice being sent by e-mail or made available in Publisher’s personal account on LeadSquar’s website. You may refer to contract revisions in our website – www.LeadSquar.com. The terms and conditions of this Publisher Agreement (as published on www.LeadSquar.com (the “Terms”) bind the parties from the date signed or the date service is provided and shall apply to each and any services provided by LeadSquar. This Agreement shall take precedence over any other terms and conditions issued or stated or referenced to apply relating to the services provided by LeadSquar.
                13.7. Representations and warranties of Publisher set forth in this Agreement hereof shall survive closing for a period of one year from the termination date.
                13.8. No claim for a breach of any representation or warranty by LeadSquar shall be actionable or payable if the breach in question results from or is based on a condition, state of facts or other matter which was disclosed to Publisher and/or actually known by Publisher prior to termination.
                13.9. Publisher acknowledges and agrees that entering into this Agreement it has not relied and is not relying on any representations, warranties or other statements whatsoever, whether written or oral other than those expressly set out in this Agreement, Privacy Policy or other terms and conditions published at www.LeadSquar.com and that it will not have any right or remedy rising out of any representation, warranty or other statement not expressly set out in this Agreement.
                13.10. All claims related to the use of the Service or Program shall be submitted by the Publisher within 30 days from the end of the Reporting Period only. In the case of missing the specified term, LeadSquar reserves the right not to process the complaint, and all the services shall be deemed rendered properly.
                13.11. Headings to sections and subsections in this Agreement are for the convenience of the parties only and are not intended to be a part of or affect the meaning or interpretation hereof.
                13.12. You agree on using of any communication method (email message/SMS message/phone) with contact details provided in your personal account.

                This agreement was last updated on 27.10.2024.
            </p>
        </div>
    )
}
