import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div style={{padding: '50px 162px 50px 162px'}}>
            <h1 style={{fontSize: '22px', color: '#CAFF33'}}>Privacy Policy</h1>
            <br></br>
            <p style={{color: 'white', fontSize: '14px'}}>
                The purpose of this Privacy Policy (hereinafter referred as “Policy”) is to inform you as to what information may be collected by:
                <br></br><br></br> 
                Company name LEAD SQUER D.O.O. <br></br> Email info@LeadSquar.com
                <br></br><br></br> 
                (hereinafter referred as “LeadSquar”, “we”, “us”, “our”)from you when you visit our Website https://LeadSquar.com/ (hereinafter referred as “this Website” or “our Website”), how such information will be used by us, with whom such information may be shared, your choices regarding the collection, use, and distribution of such information, your ability to edit, update, correct, or delete such information and the security procedures that we have implemented to protect your privacy.
                <br></br><br></br> 
                This Policy applies where we are acting as a data controller with respect to your personal data and therefore determine the purposes and means of the processing of that personal data.
                <br></br><br></br> 
                We take all necessary efforts to ensure that our processing of your personal data meets the standards of EU General Data Protection Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016 (GDPR) as we believe these are the highest data protection standards (regardless of whether the controller is registered).
                <br></br><br></br> 
                If you do not agree to all the terms and conditions of this Policy, please do not use our Website.
                <br></br><br></br> 
                Collection of Information
                <br></br><br></br> 
                We obtain your personal data mainly through any information you provide directly to us. Personal data collected directly from you, including:
                <br></br><br></br> 
                your name (or username);
                <br></br><br></br> 
                e-mail address;
                <br></br><br></br> 
                messengers details (Skype, Telegram);
                <br></br><br></br> 
                your professional background;
                <br></br><br></br> 
                payment information.
                <br></br><br></br> 
                We hope that you will provide us with this information. However, if you do not want to disclose your personal data to us, please, do not submit it. This means that we will not be able to provide you the service you have requested.
                <br></br><br></br> 
                We may also collect non-personally identifiable information from you when you visit the Website (e.g. IP address, geographical location, browser type and version, operating system, the date and time of visits, the pages viewed, time spent at our Website etc.). This information is collected and analyzed in the aggregate in order to improve the functioning and content of the Website. You can find more information in section about Cookies of this Policy.
                <br></br><br></br> 
                We may expand the list of personal data requested from you by amending this Policy.
                <br></br><br></br> 
                Use of Information
                <br></br><br></br> 
                We use information provided by you for a variety of purposes:
                <br></br><br></br> 
                registering you as Publisher or Advertiser on our Website, providing you with your personal account to use and receive our services;
                <br></br><br></br> 
                communicating with you;
                <br></br><br></br> 
                fulfilment of KYC / AML procedures;
                <br></br><br></br> 
                understand and meet your needs and preferences;
                <br></br><br></br> 
                develop new and enhance existing service offerings;
                <br></br><br></br> 
                monitoring and analyzing trends, statistics, usage and activities in connection with our Website;
                <br></br><br></br> 
                marketing purposes (add you to our email subscription list, send you promotional materials, offers by means of email advertising, etc.);
                <br></br><br></br> 
                meet legal and regulatory requirements.
                <br></br><br></br> 
                Non-personally identifiable information may be used to analyze trends and statistics, enhance the Website’s operation, and serve content and advertisements.
                <br></br><br></br> 
                Legal Grounds
                <br></br><br></br> 
                All mentioned personal data and information may be processed on the following legal basis:
                <br></br><br></br> 
                Performing the contract we have with you;
                <br></br><br></br> 
                Legitimate interest in monitoring and improving our Website and services, and proper administration of our Website.
                <br></br><br></br> 
                Consent. For example, in relation to marketing purposes and sending you any promotional materials.
                <br></br><br></br> 
                In anyway, please, remember that by submitting your personal data on our Website you agree that we are collecting, processing, using, storing and disclosing your personal data.
                <br></br><br></br> 
                Processing
                <br></br><br></br> 
                We will process your personal data legally and fairly and not use it outside the purposes of which we have informed you. So far as we are able we shall ensure that all of your personal data is accurate and up to date. Personal data processing will be carried out the following life cycle (collection, systematization, accumulation, storage, update (update, modify, exploit, distribute), including transfer), depersonalization, blocking, destruction of personal data.
                <br></br><br></br> 
                Storage of personal data
                <br></br><br></br> 
                We take reasonable steps to ensure that the personal information we collect about you remains accurate, timely, and secure. We deploy various security measures such as encryption and authentication tools in line with the current state of the art to protect and maintain the security, integrity and availability of your personal data. Among other things, we use the following measures:
                <br></br><br></br> 
                Strict criteria for authorisation to access your personal data on a “need-to-know” basis only and exclusively for the specified purpose;
                <br></br><br></br> 
                Transfer of acquired data in secured form;
                <br></br><br></br> 
                Storage of confidential data in our secure servers;
                <br></br><br></br> 
                Firewall safeguarding of IT systems to provide protection against unauthorised access, and
                <br></br><br></br> 
                Continuous monitoring of access to IT systems to detect and prevent the misuse of personal data.
                <br></br><br></br> 
                If you have received a password from us in order to be able to access certain parts of our Website, you are responsible for keeping this password confidential and for compliance with all other security procedures which we make you aware from time to time. We ask you not to share your password with anyone.
                <br></br><br></br> 
                Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure and while we strive to protect your personal information, we cannot guarantee or warrant its complete security. We shall not be responsible for losses that you or any person may suffer as a result of a breach of confidentiality in respect to your use of the Website or any information you transmit to the Website. Also, we do not assume any responsibility for the data you submit to or receive from us through the Internet or for any unauthorized access or use of that information and we cannot and do not guarantee that information communicated by you to us or sent to you by us will be received or that it will not be altered before or after its transmission to us.
                <br></br><br></br> 
                You agree to not hold us and our respective past, present and future employees, officers, directors, contractors, consultants, advisers, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (collectively, the “our Parties”) liable for any loss or damage of any sort incurred as a result of any misappropriation, interception, modification, deletion, destruction or use of information provided through our Website.
                <br></br><br></br> 
                We will keep your personal data for as long as is necessary for the purpose for which we initially collected them. If you want to stop us processing your personal data, please, contact us.
                <br></br><br></br> 
                Of course, if we speak about any financial obligations between us or legal actions, so we will keep your personal data the end of the period in which legal action or investigations might arise in respect of the services provided.
                <br></br><br></br> 
                Disclosure of personal data
                <br></br><br></br> 
                We may disclose information that we collect about you should we have reason to believe that:
                <br></br><br></br> 
                it is necessary to identify, contact, or bring legal action against persons or entities who may be causing harm to you, us, or others;
                <br></br><br></br> 
                it is necessary because of official inquiry of governmental authorities;
                <br></br><br></br> 
                it is necessary securing compliance with law's provision or with requirements of judicial proceedings concerning us;
                <br></br><br></br> 
                we may also disclose information when we believe the law requires it.
                <br></br><br></br> 
                Access or corrections to your information
                <br></br><br></br> 
                You are able to correct, update or change your personal data provided to us by sending us an e-mail or by making the necessary amendments in your personal account (feedback forms, chats).
                <br></br><br></br> 
                In addition, you may request us to delete your personal data from our database. Upon your e-mail request to terminate the processing of your personal data, we will terminate processing your personal data. Our contact information is listed below. We will use commercially reasonable efforts to accommodate all such requests.
                <br></br><br></br> 
                You may also opt out of receiving promotional communications from us at any time by following the instructions in those communications. If you opt out, we may still send you non-promotional communications, such as technical notices, support or administrative notifications or information about changes to this Policy.
                <br></br><br></br> 
                You also has other rights, which are provided to you by data protection law, including but not limited to: right to have free access to your personal data that have been provided to you and that have been subject to processing; to be informed about amendments made to the terms of this Policy in an efficient manner before the implementation of new amendments or, otherwise, of the new information processing policy; right to be forgotten; right to restrict processing, right to withdraw consent if it was given, etc.
                <br></br><br></br> 
                If you have any request, complaint or claim to us in relation to processing of your personal data by us, please, send it to our contact e-mail from your contact e-mail, which was indicated by you on our Website. You e-mail must contain:
                <br></br><br></br> 
                your name and other personal data, which was provided by you on our Website to help us to identify you;
                <br></br><br></br> 
                description of the facts that give rise to the request, claim or complaint and the purpose sought (update, correction or deletion, or fulfillment of duties).
                <br></br><br></br> 
                We consider all requests from you within 14 days. Before answering the claim, will shall verify your identity and find your personal data in our database.If the claim or information provided is incomplete, we will request additional information for your identification, which shall be provided by you within 5 days of receipt of the claim to remedy the failures. If you fail to submit the required documentation or information within 5 days of the date of the initial claim, you shall be deemed to have waived the claim.Once your request has been received with complete information, the 14 days’ term will start again. When it is not possible to answer your request within such term, we will inform you about the reasons for the delay and of the date when your request will be dealt with, which in no case may exceed 30 days following the expiration of the first term.
                <br></br><br></br> 
                Cookies
                <br></br><br></br> 
                A cookie is a small piece of data sent from a Website and stored on your computer by the your web browser while you are browsing. Our Website use cookies to distinguish you from other users of our Websites. This helps us to provide you with a good experience when you browse our Website, to track your online browsing habits on the Internet, allows us to target advertising, which may be interesting to you us and allows us to improve our Website. Detailed information about cookies on our Website you can find in pop-up window when firstly visit our Website.
                <br></br><br></br> 
                Most web browsers are set to accept cookies as a default. You may wish to opt out by turning off cookies (please refer to the help menu on your browser).
                <br></br><br></br> 
                Protection of Children
                <br></br><br></br> 
                The Website is a general audience website that is designed or targeted to people at least 18 years of age. We do not knowingly collect, use, or disseminate any personal data from children under the age 18. If, however, we become aware that personal data regarding a child under the age of 18 has been collected on the Website we will make reasonable efforts to delete it from our records immediately.
                <br></br><br></br> 
                Modifications to Policy
                <br></br><br></br> 
                This Policy may be amended from time to time. We reserves the right to change this Privacy Policy at any time. Any changes to this Policy will be effective immediately upon posting the latest version on our Website. We will notify you appropriately when we make changes to the privacy policy and we will amend its revision date so that you know when we last amended it. We do however encourage you to review this statement periodically so as to always be informed about how we are processing and protecting your personal information.
                <br></br><br></br> 
                We may notify you of changes to this policy by email or through the private messaging system on our Website.
                <br></br><br></br> 
                If you disagree with the changes in our Policy, please do not use the Website after the posting of such changes online. By using the Website following the posting of changes to this Policy, you agree to all such changes.
                <br></br><br></br> 
                Questions or comments or how to contact us
                <br></br><br></br> 
                If you have any questions about this Policy, please contact us at info@LeadSquar.com.
                <br></br><br></br> 
                This Privacy Policy is effective as of  27.10.2024.
            </p>
        </div>
    )
}
