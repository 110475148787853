import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import Spinner from '../../Spinner';
import DateRangeCalendar from '../../DateRangeCalendar';
import SimpleDropdown from '../../SimpleDropdown';
import { leadStatuses } from "../../../modules/scppModule";
import { countriesFull } from '../../../modules/countryModules';
import moment from 'moment';

export default function Export(props) {
    const [offers, setOffers] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [countryFilter, setCountryFilter] = React.useState({ name: 'Country', code: null });
    const [typeFilter, setTypeFilter] = React.useState({ name: 'Type', value: null });
    const [offersFilter, setOffersFilter] = React.useState({ name: 'Offers', value: null });
    const [statusFilter, setStatusFilter] = React.useState({ name: 'Country', value: null });
    const [dateRange, setDateRange] = React.useState({ start: null, end: null });

    const affiliateIdRef = React.useRef(null);
    const advertiserIdRef = React.useRef(null);

    const getOffers = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {

            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                res.data?.data?.map(offer => {
                    setOffers(prev => {
                        return [
                            ...prev,
                            { name: offer.Name + ' - ' + offer.Type + ' - ' + offer.Country, value: offer.ID }
                        ]
                    });
                });
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const exportLeads = () => {
        setBtnSpinner(true);

        let data = {
            UserID: props.userData?.ID,
            UserType: props.UserType,
            Type: typeFilter.value,
            OfferID: offersFilter.value,
            Country: countryFilter.code,
            Status: statusFilter.value,
            startDate: dateRange.start,
            endDate: dateRange.end,
        }

        if(props.UserType === 'admin'){
            data.AffiliateID = affiliateIdRef.current.value;
            data.AdvertiserID = advertiserIdRef.current.value;
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/exportData`,
            data: data,
            responseType: 'blob',
            ...backendModule.axiosConfig
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `AffiliateID_${props.userData?.ID}-${moment(dateRange.start).format('DD.MM.YYYY.')}-${moment(dateRange.end).format('DD.MM.YYYY.')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            return setError('Error occurred...');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    React.useEffect(() => {
        getOffers();
    }, []);
    return (
        <div className='component__export'>
            <div className='component__export__wrapper'>
                <div className='component__export__wrapper__header'>
                    <h1>Export data</h1>
                    <img onClick={props.onClose} src='images/closeModal.png' alt='' />
                </div>
                {!spinner && <div className='component__export__wrapper__filters'>
                    <DateRangeCalendar width='220px' range={dateRange} setRange={setDateRange} />
                    <SimpleDropdown search='true' headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px', width: '220px' }} heading='Country - all' data={[{ name: 'Country - all', code: null }, ...countriesFull]} setSelected={setCountryFilter} />
                    <SimpleDropdown search='true' headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px', width: '220px' }} heading='Offers - all' data={[{ name: 'Offers - all', value: null }, ...offers]} setSelected={setOffersFilter} />
                    <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px', width: '220px' }} heading='Type - all' data={[{ name: 'LOW', value: 'LOW' }, { name: 'FULL', value: 'FULL' }, { name: 'Type - all', value: null }]} setSelected={setTypeFilter} />
                    <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px', width: '220px' }} heading='Status - all' data={[{ name: 'Status - all', value: null }, ...leadStatuses]} setSelected={setStatusFilter} />
                    {props.UserType === 'admin' && <input ref={advertiserIdRef} type='number' placeholder='Advertiser ID' />}
                    {props.UserType === 'admin' && <input ref={affiliateIdRef} type='number' placeholder='Affiliate ID' />}
                    <div onClick={() => exportLeads()} className='component__export__wrapper__filters__button'>
                        {!btnSpinner && <p>Export</p>}
                        {btnSpinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    </div>
                </div>}
                {spinner && <Spinner style={{ width: '44px',height: '44px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                {error && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
            </div>
        </div>
    )
}
