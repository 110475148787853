import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { offerStatuses, categories, payoutsModels, offersPrivacy } from "../../modules/scppModule";
import { countriesFull } from '../../modules/countryModules';
import { animateBox } from "../../modules/componentAnimation";
import animateModule from "../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import YesNoModal from "../../components/Modals/YesNoModal";
import SimpleDropdown from '../../components/SimpleDropdown';
import Spinner from '../../components/Spinner';

export default function AdminOffers() {
  const [categoryFilter, setCategoryFilter] = React.useState({ name: 'Categories', value: 'all' });
  const [poModelFilter, setPoModelFilter] = React.useState({ name: 'PO Models', value: 'all' });
  const [privacyFilter, setPrivacyFilter] = React.useState({ name: 'Privacy', value: 'all' });
  const [typeFilter, setTypeFilter] = React.useState({ name: 'Type', value: 'all' });
  const [statusFilter, setStatusFilter] = React.useState({ name: 'Status', value: 0, enabled: 'no' });
  const [countryFilter, setCountryFilter] = React.useState({ name: 'Country', code: 'all' });
  const [error, setError] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [paginationSpinner, setPaginationSpinner] = React.useState(false);
  const [paginationMessage, setPaginationMessage] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState(null);
  const [offers, setOffers] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState(false);

  const searchRef = React.useRef(null);

  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__toAnimate"));
  };

  const getOffers = (offset, limit) => {
    setPaginationMessage(null);
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/offers/getAllOffers`,
      data: {
        filters: [
          statusFilter.enabled === 'no' ? { name: 'Status', op: 'eq', value: 0 } : statusFilter.enabled === 'yes' ? { name: 'Status', op: 'eq', value: statusFilter.value } : null,
          categoryFilter.value !== 'all' ? { name: 'Category', op: 'eq', value: categoryFilter.value } : null,
          poModelFilter.value !== 'all' ? { name: 'PayoutModel', op: 'eq', value: poModelFilter.value } : null,
          privacyFilter.value !== 'all' ? { name: 'Privacy', op: 'eq', value: privacyFilter.value } : null,
          typeFilter.value !== 'all' ? { name: 'Type', op: 'eq', value: typeFilter.value } : null,
          countryFilter.code !== 'all' ? { name: 'Country', op: 'eq', value: countryFilter.code } : null,
          String(searchValue).includes('N:') ? { name: 'Name', op: 'like', value: String(searchValue).replace('N:', '') } : null,
          String(searchValue).includes('ID:') ? { name: 'ID', op: 'eq', value: String(searchValue).replace('ID:', '') } : null
        ],
        offset: offset,
        limit: limit
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data?.data);
      } else {
        setError('Unknown error occurred');
      }
    }).catch(() => {
      setError('Unknown error occurred');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getOffersPagination = (offset, limit) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/offers/getAllOffers`,
      data: {
        filters: [
          statusFilter.enabled === 'no' ? { name: 'Status', op: 'eq', value: 0 } : statusFilter.enabled === 'yes' ? { name: 'Status', op: 'eq', value: statusFilter.value } : null,
          categoryFilter.value !== 'all' ? { name: 'Category', op: 'eq', value: categoryFilter.value } : null,
          poModelFilter.value !== 'all' ? { name: 'PayoutModel', op: 'eq', value: poModelFilter.value } : null,
          privacyFilter.value !== 'all' ? { name: 'Privacy', op: 'eq', value: privacyFilter.value } : null,
          typeFilter.value !== 'all' ? { name: 'Type', op: 'eq', value: typeFilter.value } : null,
          countryFilter.code !== 'all' ? { name: 'Country', op: 'eq', value: countryFilter.code } : null,
          String(searchValue).includes('N:') ? { name: 'Name', op: 'like', value: String(searchValue).replace('N:', '') } : null,
          String(searchValue).includes('ID:') ? { name: 'ID', op: 'eq', value: String(searchValue).replace('ID:', '') } : null
        ],
        offset: offset,
        limit: limit
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        if(res.data.data.length === 0){
          setPaginationMessage('There are no more offers');
        }
        else{
          setOffers(prev => [...prev, ...res.data.data]);
        }
      } else {
        setPaginationMessage('Unknown error occurred');
      }
    }).catch(() => {
      setPaginationMessage('Unknown error occurred');
    }).finally(() => {
      setPaginationSpinner(false);
    });
  }

  const returnCategory = (ID) => {
    return categories.find(cg => cg.value === Number(ID))?.name;
  }
  const returnPrivacy = (ID) => {
    return offersPrivacy.find(pv => pv.value === Number(ID))?.name;
  }
  const returnPayoutModel = (ID) => {
    return payoutsModels.find(pm => pm.value === Number(ID))?.name;
  }

  React.useEffect(() => {
    getOffers(0, 12);
  }, [categoryFilter, countryFilter, poModelFilter, privacyFilter, searchValue, typeFilter, statusFilter]);

  return (
    <div className='route__adminOffers'>
      <div className='route__adminOffers__header'>
        <h1>Offers</h1>
        <div className='route__adminOffers__header__filters'>
          <div onClick={() => setActiveFilters(f => !f)} className='route__adminOffers__header__filters__button'>
            <img src='images/filters.png' alt='' />
            <p>{!activeFilters ? 'Show filters' : 'Hide filters'}</p>
          </div>
          <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <CreateOfferModal refresh={getOffers} />)} className='route__adminOffers__header__filters__button'>
            <img src='images/create.png' alt='' />
            <p>Create new offer</p>
          </div>
        </div>
        <div className={activeFilters ? 'route__adminOffers__header__filters__box route__adminOffers__header__filters__box__active' : 'route__adminOffers__header__filters__box'}>
          <div className='route__adminOffers__header__filters__box__search'>
            <img onClick={() => setSearchValue(searchRef.current.value)} src='images/search.png' alt='' />
            <input ref={searchRef} type='text' placeholder='Search... N:name ID:id' />
          </div>
          <SimpleDropdown search='true' headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Country' data={[{ name: 'Country - all', code: 'all' }, ...countriesFull]} setSelected={setCountryFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Categories' data={[...categories, { name: 'Categories - all', value: 'all' }]} setSelected={setCategoryFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='PO Models' data={[...payoutsModels, { name: 'PO Models - all', value: 'all' }]} setSelected={setPoModelFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Privacy' data={[...offersPrivacy, { name: 'Privacy - all', value: 'all' }]} setSelected={setPrivacyFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Type' data={[{ name: 'LOW', value: 'LOW' }, { name: 'FULL', value: 'FULL' }, { name: 'Type - all', value: 'all' }]} setSelected={setTypeFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Status' data={[{ name: 'Active', value: 0, enabled: 'yes' }, { name: 'Paused', value: 1, enabled: 'yes' }]} setSelected={setStatusFilter} />
        </div>
      </div>
      <div className='route__adminOffers__offersGrid'>
        <div className='route__adminOffers__offersGrid__content'>
          {
            !spinner && !error ? offers?.map(offer => {
              return <div className='route__adminOffers__offersGrid__content__singleOffer'>
                <div className='route__adminOffers__offersGrid__content__singleOffer__top'>
                  <img src={`${backendModule.backendURL}/${offer.ImagePath}`} alt='' />
                  <div className='route__adminOffers__offersGrid__content__singleOffer__top__right'>
                    <h1>{offer.Type} - {offer.Name} - {offer.Country}</h1>
                    <span className='route__adminOffers__offersGrid__content__singleOffer__top__right__span'>
                      <p className='route__adminOffers__offersGrid__content__singleOffer__top__right__span__id'>ID: {offer.ID}</p>
                      <p className='route__adminOffers__offersGrid__content__singleOffer__top__right__span__privacy'>{returnPrivacy(offer.Privacy)}</p>
                      <p className='route__adminOffers__offersGrid__content__singleOffer__top__right__span__category'>{returnCategory(offer.Category)}</p>
                    </span>
                  </div>
                </div>
                <div className='route__adminOffers__offersGrid__content__singleOffer__middle'>
                  <div className='route__adminOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Country</label>
                    <div className='route__adminOffers__offersGrid__content__singleOffer__middle__field__country'>
                      <span class={`fi fi-${String(offer.Country).toLowerCase()}`}></span>
                      <p>{offer.Country}</p>
                    </div>
                  </div>
                  <div className='route__adminOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Price</label>
                    <p>{offer.Price} {offer.Currency}</p>
                  </div>
                  <div className='route__adminOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Payout</label>
                    <p>${offer.Payout}</p>
                  </div>
                  <div className='route__adminOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Squers</label>
                    <p>{offer.Squers}</p>
                  </div>
                </div>
                <div className='route__adminOffers__offersGrid__content__singleOffer__bottom'>
                  <div onClick={() => animateNavigate(`/admin/offers/offerpage?offerID=${offer.ID}`)} className='route__adminOffers__offersGrid__content__singleOffer__bottom__btn'>
                    <p>INFO</p>
                  </div>
                  <div onClick={(e) => animateBox(e, <CreateOfferModal editData={offer} mode='edit' refresh={getOffers} />)} className='route__adminOffers__offersGrid__content__singleOffer__bottom__btn'>
                    <p>EDIT</p>
                  </div>
                  <div className='route__adminOffers__offersGrid__content__singleOffer__bottom__model'>
                    <p>{returnPayoutModel(offer.PayoutModel)}</p>
                  </div>
                </div>
              </div>
            }) : null
          }
          {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
          {error && <p style={{ color: 'red', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
        </div>
      </div>
      {offers?.length > 11 && <div className='route__adminOffers__offersGrid__pagination'>
        {!paginationSpinner && !paginationMessage ? <div onClick={() => { setPaginationSpinner(true); getOffersPagination(offers?.length, 12) }} className='route__adminOffers__offersGrid__pagination__button'>
          <p>Load more offers</p>
        </div> : null}
        {paginationSpinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        {paginationMessage && <p style={{color: 'maroon', fontSize: '15px'}}>{paginationMessage}</p>}
      </div>}
    </div>
  )
}

const CreateOfferModal = (props) => {
  const [privacy, setPrivacy] = React.useState(props.mode === 'edit' ? {value:props.editData?.Privacy} : null);
  const [category, setCategory] = React.useState(props.mode === 'edit' ? {value:props.editData?.Category} : null);
  const [payoutModel, setPayoutModel] = React.useState(props.mode === 'edit' ? {value:props.editData?.PayoutModel} : null);
  const [status, setStatus] = React.useState(props.mode === 'edit' ? {value:props.editData?.Status} : null);
  const [isFeatured, setIsFeatured] = React.useState(props.mode === 'edit' ? {value: props.editData?.IsFeatured} : null);
  const [country, setCountry] = React.useState(props.mode === 'edit' ? {code:props.editData?.Country} : null);
  const [type, setType] = React.useState(props.mode === 'edit' ? {value:props.editData?.Type} : null);
  const [image, setImage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [error, setError] = React.useState(null);

  const nameRef = React.useRef(null);
  const priceRef = React.useRef(null);
  const currencyRef = React.useRef(null);
  const payoutRef = React.useRef(null);
  const approveRateRef = React.useRef(null);
  const squersRef = React.useRef(null);
  const networkFeeRef = React.useRef(null);
  const dailyCapRef = React.useRef(null);
  const totalCapRef = React.useRef(null);
  const customPayoutsRef = React.useRef(null);
  const blockedAffiliatesRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const advertiserIDref = React.useRef(null);
  const advertiserOfferIDref = React.useRef(null);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  }

  const createOffer = () => {
    if(props.mode !== 'edit'){
      if (
        privacy !== null &&
        status !== null &&
        category !== null &&
        payoutModel !== null &&
        isFeatured !== null &&
        country !== null &&
        type !== null
      ) {
        setSpinner(true);
      } else {
        return setError('Info missing!');
      }
    }

    const data = new FormData();
    data.append('Name', nameRef.current.value);
    data.append('Price', priceRef.current.value);
    data.append('Currency', currencyRef.current.value);
    data.append('Payout', payoutRef.current.value);
    data.append('ApproveRate', approveRateRef.current.value);
    data.append('Squers', squersRef.current.value);
    data.append('NetworkFee', networkFeeRef.current.value);
    data.append('DailyCap', dailyCapRef.current.value);
    data.append('TotalCap', totalCapRef.current.value);
    data.append('CustomPayouts', customPayoutsRef.current.value);
    data.append('BlockedAffiliates', blockedAffiliatesRef.current.value);
    data.append('Description', descriptionRef.current.value);
    data.append('AdvertiserID', advertiserIDref.current.value);
    data.append('AdvertiserOfferID', advertiserOfferIDref.current.value);
    data.append('Privacy', privacy.value);
    data.append('Status', status.value);
    data.append('Category', category.value);
    data.append('PayoutModel', payoutModel.value);
    data.append('IsFeatured', isFeatured.value);
    data.append('Country', country.code);
    data.append('Type', type.value);

    if (image) {
      data.append('image', image);
    }

    if(props.mode === 'edit'){
      data.append('ID', props.editData?.ID);
    }

    axios({
      method: "POST",
      url: props.mode !== 'edit' ? `${backendModule.backendURL}/offers/addOffer` : `${backendModule.backendURL}/offers/editOffer`,
      data: data,
      ...backendModule.axiosConfig,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(res => {
      if (res.data.status === "ok") {
        props.refresh(0, 12);
        props.onClose();
      } else {
        setError('Unknown error occurred');
      }
    }).catch(() => {
      setError('Unknown error occurred');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const deleteOffer = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/offers/removeOffer`,
      data: {
        ID: props.editData?.ID
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        props.refresh(0, 12);
        props.onClose();
      } else {
        setError('Unknown error occurred');
      }
    }).catch(() => {
      setError('Unknown error occurred');
    }).finally(() => {

    });
  }

  return <div className='component__createOffer'>
    <div className='component__createOffer__wrapper'>
      <div className='component__createOffer__wrapper__header'>
        <h1>Create new offer</h1>
        <img onClick={props.onClose} src='images/closeModal.png' alt='' />
      </div>
      <div className='component__createOffer__wrapper__body'>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Offer name</label>
          <input ref={nameRef} type='text' defaultValue={props.mode === 'edit' ? props.editData?.Name : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Price</label>
          <input ref={priceRef} type='number' defaultValue={props.mode === 'edit' ? props.editData?.Price : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Currency</label>
          <input ref={currencyRef} type='text' defaultValue={props.mode === 'edit' ? props.editData?.Currency : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Payout</label>
          <input ref={payoutRef} type='number' defaultValue={props.mode === 'edit' ? props.editData?.Payout : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Approve Rate</label>
          <input ref={approveRateRef} type='number' defaultValue={props.mode === 'edit' ? props.editData?.ApproveRate : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Squers</label>
          <input ref={squersRef} type='number' defaultValue={props.mode === 'edit' ? props.editData?.Squers : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Network Fee</label>
          <input ref={networkFeeRef} type='number' defaultValue={props.mode === 'edit' ? props.editData?.NetworkFee : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Daily Cap</label>
          <input ref={dailyCapRef} type='number' defaultValue={props.mode === 'edit' ? props.editData?.DailyCap : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Total Cap</label>
          <input ref={totalCapRef} type='number' defaultValue={props.mode === 'edit' ? props.editData?.TotalCap : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Custom Payouts</label>
          <input ref={customPayoutsRef} type='text' defaultValue={props.mode === 'edit' ? props.editData?.CustomPayouts : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Blocked affiliates</label>
          <input ref={blockedAffiliatesRef} type='text' defaultValue={props.mode === 'edit' ? props.editData?.BlockedAffiliates : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Advertiser ID</label>
          <input ref={advertiserIDref} type='text' defaultValue={props.mode === 'edit' ? props.editData?.AdvertiserID : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Advertiser Offer ID</label>
          <input ref={advertiserOfferIDref} type='text' defaultValue={props.mode === 'edit' ? props.editData?.AdvertiserOfferID : null} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Offer image</label>
          <input accept="image/*" type='file' name="image" onChange={handleImageChange} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Privacy</label>
          <SimpleDropdown headTextStyle={{ color: '#1A1A1A' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #1A1A1A', borderRadius: '5px' }} heading='Privacy' data={offersPrivacy} setSelected={setPrivacy} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Category</label>
          <SimpleDropdown headTextStyle={{ color: '#1A1A1A' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #1A1A1A', borderRadius: '5px' }} heading='Category' data={categories} setSelected={setCategory} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Status</label>
          <SimpleDropdown headTextStyle={{ color: '#1A1A1A' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #1A1A1A', borderRadius: '5px' }} heading='Status' data={offerStatuses} setSelected={setStatus} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Payout model</label>
          <SimpleDropdown headTextStyle={{ color: '#1A1A1A' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #1A1A1A', borderRadius: '5px' }} heading='Payout model' data={payoutsModels} setSelected={setPayoutModel} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Is featured</label>
          <SimpleDropdown headTextStyle={{ color: '#1A1A1A' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #1A1A1A', borderRadius: '5px' }} heading='Is Featured' data={[{ name: 'Yes', value: true }, { name: 'No', value: false }]} setSelected={setIsFeatured} />
        </div>
        <div className='component__createOffer__wrapper__body__field'>
          <label>Country</label>
          <SimpleDropdown headTextStyle={{ color: '#1A1A1A' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #1A1A1A', borderRadius: '5px' }} heading='Country' data={countriesFull} setSelected={setCountry} />
        </div>
        <div style={{gridColumn: 'span 4'}} className='component__createOffer__wrapper__body__field'>
          <label>Type</label>
          <SimpleDropdown headTextStyle={{ color: '#1A1A1A' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #1A1A1A', borderRadius: '5px' }} heading='Type' data={[{ name: 'FULL', value: 'FULL' }, { name: 'LOW', value: 'LOW' }]} setSelected={setType} />
        </div>
      </div>
      <div className='component__createOffer__wrapper__description'>
        <div className='component__createOffer__wrapper__description__field'>
          <label>Description</label>
          <textarea defaultValue={props.mode === 'edit' ? props.editData?.Description : null} ref={descriptionRef} />
        </div>
      </div>
      <div className='component__createOffer__wrapper__buttons'>
        {error && <p>{error}</p>}
        <div onClick={(e) => animateBox(e, <YesNoModal heading='Delete offer?' buttonRightCallback={deleteOffer} />)} className='component__createOffer__wrapper__buttons__btn'>
          <p>Delete offer</p>
        </div>
        <div onClick={() => createOffer()} className='component__createOffer__wrapper__buttons__btn'>
          {!spinner && <p>{props.mode === 'edit' ? 'Save changes' : 'Create offer'}</p>}
          {spinner && <Spinner style={{ width: '18px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
        </div>
      </div>
    </div>
  </div>
}