import React from 'react';
import "./index.scss";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';

export default function AffiliatePromoteInstructions() {
    const [curUser, setCurUser] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const paramsString = `
        ApiKey: '${curUser?.data?.ApiKey}', 
        ClientName: nameInput.value, (STRING)
        ClientPhone: phoneInput.value, (STRING || NUMBER)
        AdvertiserID: ${queryParams.get('advertiserID')},
        OfferID: ${queryParams.get('offerID')}, 
        AffiliateID: ${queryParams.get('affiliateID')},
        SubID1: getQueryParam('sub_id1') ? getQueryParam('sub_id1') : null,
        SubID2: getQueryParam('sub_id2') ? getQueryParam('sub_id2') : null,
        SubID3: getQueryParam('sub_id3') ? getQueryParam('sub_id3') : null,
        FBpixelID: getQueryParam('fb_pixel') ? getQueryParam('fb_pixel') : null,
        TrackingID: getQueryParam('tracking_id') ? getQueryParam('tracking_id') : null
    `;

    const codeString = `document.addEventListener('DOMContentLoaded', function() {
    const form = document.getElementById('LeadSquar-form');
    const button = form.querySelector('button');

    function getQueryParam(param) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    button.addEventListener('click', function(event) {
        event.preventDefault(); 

        const nameInput = form.querySelector('input[name="name"]');
        const phoneInput = form.querySelector('input[name="phone"]');

        const data = {
            ApiKey: '${curUser?.data?.ApiKey}',                
            ClientName: nameInput.value,
            ClientPhone: phoneInput.value,
            AdvertiserID: ${queryParams.get('advertiserID')},
            OfferID: ${queryParams.get('offerID')},
            AffiliateID: ${queryParams.get('affiliateID')},
            SubID1: getQueryParam('sub_id1') ? getQueryParam('sub_id1') : null,
            SubID2: getQueryParam('sub_id2') ? getQueryParam('sub_id2') : null,
            SubID3: getQueryParam('sub_id3') ? getQueryParam('sub_id3') : null,
            FBpixelID: getQueryParam('fb_pixel_id') ? getQueryParam('fb_pixel_id') : null,
            TrackingID: getQueryParam('tracking_id') ? getQueryParam('tracking_id') : null,
            Source: getQueryParam('source') ? getQueryParam('source') : null
        }

        fetch("${backendModule.backendURL}/leads/addLead", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(data => {
            if(data.status === 200){
                //window.location.href='/thankyou.html';
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    });
});`;

    const htmlString = `
    <head>
        ...
        <script src="./send-lead.js"></script>  //path to javascript file with code from example
    </head>


    <form id="LeadSquar-form">
        <label for="name">Client full name</label>
        <input type="text" name="name">
        <label for="phone">Client phone number</label>
        <input type="text" name="phone">
        <button>ORDER BUTTON</button>
    </form>
    `;

    const getCurUser = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getUserByID`,
            data: {
                ID: queryParams.get('affiliateID')
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setCurUser(res.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getCurUser();
    }, []);

    return (
        <div className='route__promoteInstructions'>
            {!spinner && !error ? <div className='route__promoteInstructions__body'>
                <h1>Promote instructions</h1>
                <p><strong>NOTE:</strong> You can also contact the affiliate manager who will set up everything necessary for you.</p>
                <strong>Your Api Key: <p>{curUser?.data?.ApiKey}</p></strong>
                <strong>Request URL: <p>{`${backendModule.backendURL}/leads/addLead`} (POST)</p></strong>
                <div className='route__promoteInstructions__body__grid'>
                    <div className='route__promoteInstructions__body__grid__left'>
                        <h3>Request body params</h3>
                        <div className='route__promoteInstructions__body__grid__left__code'>
                            <pre>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: paramsString
                                            .replace(/</g, '&lt;')
                                            .replace(/>/g, '&gt;')
                                            .replace(/"/g, '&quot;')
                                            .replace(/'/g, '&#39;')
                                    }}
                                />
                            </pre>
                        </div>
                        <br></br>
                        <h3>Request example (Plain Javascript)</h3>
                        <div className='route__promoteInstructions__body__grid__left__code'>
                            <pre>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: codeString
                                            .replace(/</g, '&lt;')
                                            .replace(/>/g, '&gt;')
                                            .replace(/"/g, '&quot;')
                                            .replace(/'/g, '&#39;')
                                    }}
                                />
                            </pre>
                        </div>
                    </div>
                    <div className='route__promoteInstructions__body__grid__right'>
                        <h3>An example of an order form</h3>
                        <div className='route__promoteInstructions__body__grid__right__code'>
                            <pre>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: htmlString
                                            .replace(/</g, '&lt;')
                                            .replace(/>/g, '&gt;')
                                            .replace(/"/g, '&quot;')
                                            .replace(/'/g, '&#39;')
                                    }}
                                />
                            </pre>
                        </div>
                        <br></br>
                        <h3>Frequent responses</h3>
                        <div className='route__promoteInstructions__body__grid__right__code'>
                            <pre>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: '{\n status: "ok", \n data: {\n   LeadID: RETURNED_LEAD_ID,\n   PotentialPayout: YOUR_PAYOUT_FOR_THIS_OFFER\n }\n}'
                                            .replace(/</g, '&lt;')
                                            .replace(/>/g, '&gt;')
                                            .replace(/"/g, '&quot;')
                                            .replace(/'/g, '&#39;')
                                    }}
                                />
                            </pre>
                        </div>
                        <br></br>
                        <div className='route__promoteInstructions__body__grid__right__code'>
                            <pre>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: '{\n status: "error", \n data: "INFO_MISSING"\n}'
                                            .replace(/</g, '&lt;')
                                            .replace(/>/g, '&gt;')
                                            .replace(/"/g, '&quot;')
                                            .replace(/'/g, '&#39;')
                                    }}
                                />
                            </pre>
                        </div>
                        <br></br>
                        <div className='route__promoteInstructions__body__grid__left__code'>
                            <pre>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: '{\n status: "error", \n data: "INVALID_API_KEY"\n}'
                                            .replace(/</g, '&lt;')
                                            .replace(/>/g, '&gt;')
                                            .replace(/"/g, '&quot;')
                                            .replace(/'/g, '&#39;')
                                    }}
                                />
                            </pre>
                        </div>
                        <br></br>
                        <div className='route__promoteInstructions__body__grid__left__code'>
                            <pre>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: '{\n status: "error", \n data: "DB_ERROR"\n}'
                                            .replace(/</g, '&lt;')
                                            .replace(/>/g, '&gt;')
                                            .replace(/"/g, '&quot;')
                                            .replace(/'/g, '&#39;')
                                    }}
                                />
                            </pre>
                        </div>
                    </div>
                </div>
            </div> : null}
            {error && <p style={{ color: 'maroon' }}>{error}</p>}
            {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        </div>
    )
}
