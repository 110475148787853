import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';

export default function AdvertiserBlacklist(props) {
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [blacklist, setBlacklist] = React.useState([]);
    const [message, setMessage] = React.useState(null);

    const blacklistRef = React.useRef(null);

    const getBlacklist = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/blacklists/getBlacklist`,
            data: {
                AdvertiserID: props.userData?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if(res.data.status === 'ok'){
                setBlacklist(res.data.data);
            }else{
                setError('Error occurred...')
            }
        }).catch(() => {
            return setError('Error occurred...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const createBlacklist = () => {
        if(!blacklistRef.current.value){
            return setMessage('Please write some phone numbers');
        }else{
            setBtnSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/blacklists/addBlacklist`,
            data: {
                AdvertiserID: props.userData?.ID,
                PhoneNumbers: blacklistRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if(res.data.status === 'ok'){
                setBlacklist(res.data.data);
            }else{
                setError('Error occurred...')
            }
        }).catch(() => {
            return setError('Error occurred...');
        }).finally(() => {
            setBtnSpinner(false);
            setMessage('Success!');
            getBlacklist();
        });
    }

    const updateBlacklist = () => {
        if(!blacklistRef.current.value){
            return setMessage('Please write some phone numbers');
        }else{
            setBtnSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/blacklists/updateBlacklist`,
            data: {
                AdvertiserID: props.userData?.ID,
                PhoneNumbers: blacklistRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if(res.data.status === 'ok'){
                setBlacklist(res.data.data);
            }else{
                setError('Error occurred...')
            }
        }).catch(() => {
            return setError('Error occurred...');
        }).finally(() => {
            setBtnSpinner(false);
            setMessage('Success!');
            getBlacklist();
        });
    }

    React.useEffect(() => {
        getBlacklist();
    }, []);

    return (
        <div className='route__advertiserBlacklist'>
            <div className='route__advertiserBlacklist__header'>
                <h1>Blacklist</h1>
            </div>
            {!spinner && <div className='route__advertiserBlacklist__body'>
                <p>
                    Down below you can write all the phone numbers that you do not want to receive as leads, you need to specify all possible combinations, for example with area code, without area code, etc. Write each number on a new line
                </p>
                <textarea 
                ref={blacklistRef}
                defaultValue={blacklist[0]?.PhoneNumbers}
                placeholder='/*Example*/ 
                +38761222333 
                0038761222333
                061222333' />
                <div
                onClick={() => {blacklist.length > 0 ? updateBlacklist() : createBlacklist()}} 
                className='route__advertiserBlacklist__body__button'>
                    {!btnSpinner && <p>Save numbers</p>}
                    {btnSpinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                </div>
                {message && <p style={{fontSize: '15px', color: 'green'}}>{message}</p>}
            </div>}
            {error && <p style={{color: 'maroon', fontSize: '15px'}}>{error}</p>}
            {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        </div>
    )
}
