import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';
import SimpleDropdown from '../../components/SimpleDropdown';
import DateRangeCalendar from '../../components/DateRangeCalendar';
import Export from '../../components/Modals/Export';
import { leadStatuses } from "../../modules/scppModule";
import { countriesFull } from '../../modules/countryModules';
import { animateBox } from "../../modules/componentAnimation";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import moment from 'moment';

export default function AdminLeads(props) {
    const [leads, setLeads] = React.useState([]);
    const [offers, setOffers] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [offersSpinner, setOffersSpinner] = React.useState(false);
    const [paginationSpinner, setPaginationSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [paginationMessage, setPaginationMessage] = React.useState(null);
    const [countryFilter, setCountryFilter] = React.useState({ name: 'Country', code: 'all' });
    const [typeFilter, setTypeFilter] = React.useState({ name: 'Type', value: 'all' });
    const [offersFilter, setOffersFilter] = React.useState({ name: 'Offers', value: 'all' });
    const [statusFilter, setStatusFilter] = React.useState({ name: 'Country', value: 'all' });
    const [sort, setSort] = React.useState({ name: 'Created DESC', value: 'createdAt', order: 'DESC' });
    const [searchValue, setSearchValue] = React.useState(null);
    const [activeFilters, setActiveFilters] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({ start: null, end: null });
    const [additionalInfoActive, setAdditionalInfoActive] = React.useState({ index: null, state: false });

    const searchRef = React.useRef(null);

    const sortArray = [
        { name: 'Created ASC', value: 'createdAt', order: 'ASC' },
        { name: 'Created DESC', value: 'createdAt', order: 'DESC' },
    ];

    const handleAdditionalInfoBox = (idx) => {
        if (additionalInfoActive.state) {
            setAdditionalInfoActive({ index: idx, state: false });
        } else {
            setAdditionalInfoActive({ index: idx, state: true });
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setSearchValue(searchRef.current.value);
        }
    };

    const returnCurrentStatus = (statusID) => {
        return leadStatuses?.find(ls => ls.value === statusID);
    }

    const getLeads = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllLeads`,
            data: {
                ApiKey: props.userData?.ApiKey,
                limit: 20,
                offset: 0,
                filters: [
                    countryFilter.code !== 'all' ? { name: 'OfferCountry', op: 'eq', value: countryFilter.code } : null,
                    offersFilter.value !== 'all' ? { name: 'OfferID', op: 'eq', value: offersFilter.value } : null,
                    typeFilter.value !== 'all' ? { name: 'OfferType', op: 'eq', value: typeFilter.value } : null,
                    statusFilter.value !== 'all' ? { name: 'Status', op: 'eq', value: statusFilter.value } : null,
                    String(searchValue).includes('AI:') ? { name: 'AdditionalInfo', op: 'like', value: String(searchValue).replace('AI:', '') } : null,
                    String(searchValue).includes('ID:') ? { name: 'ID', op: 'like', value: String(searchValue).replace('ID:', '') } : null
                ],
                orders: sort.order !== 'any' ? [[sort.value, sort.order]] : null,
                startDate: dateRange.start !== null ? dateRange.start : null,
                endDate: dateRange.end !== null ? dateRange.end : null
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setLeads(res.data.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const getLeadsPagination = (limit, offset) => {
        setPaginationSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllLeads`,
            data: {
                ApiKey: props.userData?.ApiKey,
                limit: limit,
                offset: offset,
                filters: [
                    countryFilter.code !== 'all' ? { name: 'OfferCountry', op: 'eq', value: countryFilter.code } : null,
                    offersFilter.value !== 'all' ? { name: 'OfferID', op: 'eq', value: offersFilter.value } : null,
                    typeFilter.value !== 'all' ? { name: 'OfferType', op: 'eq', value: typeFilter.value } : null,
                    statusFilter.value !== 'all' ? { name: 'Status', op: 'eq', value: statusFilter.value } : null,
                    String(searchValue).includes('AI:') ? { name: 'AdditionalInfo', op: 'like', value: String(searchValue).replace('AI:', '') } : null,
                    String(searchValue).includes('ID:') ? { name: 'ID', op: 'like', value: String(searchValue).replace('ID:', '') } : null
                ],
                orders: sort.order !== 'any' ? [[sort.value, sort.order]] : null,
                startDate: dateRange.start !== null ? dateRange.start : null,
                endDate: dateRange.end !== null ? dateRange.end : null
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 0) {
                    setPaginationMessage('There are no more leads');
                }
                else {
                    setLeads(prev => [...prev, ...res.data.data]);
                }
            } else {
                setPaginationMessage('Unknown error occurred');
            }
        }).catch(() => {
            setPaginationMessage('Unknown error occurred');
        }).finally(() => {
            setPaginationSpinner(false);
        });
    }

    const getOffers = () => {
        setOffersSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {

            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                res.data?.data?.map(offer => {
                    setOffers(prev => {
                        return [
                            ...prev,
                            { name: offer.Name + ' - ' + offer.Type + ' - ' + offer.Country, value: offer.ID }
                        ]
                    });
                });
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setOffersSpinner(false);
        });
    }

    React.useEffect(() => {
        getLeads();
    }, [countryFilter, offersFilter, typeFilter, statusFilter, sort, searchValue]);

    React.useEffect(() => {
        if (dateRange.start !== null && dateRange.end !== null) {
            getLeads();
        }
    }, [dateRange]);

    React.useEffect(() => {
        getOffers();
    }, []);

    return (
        <div className='route__adminLeads'>
            <div className='route__adminLeads__header'>
                <h1>Leads</h1>
                <div className='route__adminLeads__header__filters'>
                    <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <Export userData={props.userData} UserType='admin' />)} className='route__affiliateLeads__header__filters__button'>
                        <img src='images/export.png' alt='' />
                        <p>Export</p>
                    </div>
                    <div onClick={() => setActiveFilters(f => !f)} className='route__adminLeads__header__filters__button'>
                        <img src='images/filters.png' alt='' />
                        <p>{!activeFilters ? 'Show filters' : 'Hide filters'}</p>
                    </div>
                </div>
                <div className={activeFilters ? 'route__adminLeads__header__filters__box route__adminLeads__header__filters__box__active' : 'route__adminLeads__header__filters__box'}>
                    <div className='route__adminLeads__header__filters__box__search'>
                        <img onClick={() => setSearchValue(searchRef.current.value)} src='images/search.png' alt='' />
                        <input onKeyDown={handleKeyDown} ref={searchRef} type='text' placeholder='Search ID:id || AI:aid-' />
                    </div>
                    <SimpleDropdown search='true' headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Country' data={[{ name: 'Country - all', code: 'all' }, ...countriesFull]} setSelected={setCountryFilter} />
                    {!offersSpinner && <SimpleDropdown search='true' headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Offer' data={[{ name: 'Offers - all', value: 'all' }, ...offers]} setSelected={setOffersFilter} />}
                    <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Type' data={[{ name: 'LOW', value: 'LOW' }, { name: 'FULL', value: 'FULL' }, { name: 'Type - all', value: 'all' }]} setSelected={setTypeFilter} />
                    <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Status' data={[{ name: 'Status - all', value: 'all' }, ...leadStatuses]} setSelected={setStatusFilter} />
                    <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Sort by' data={sortArray} setSelected={setSort} />
                    <DateRangeCalendar range={dateRange} setRange={setDateRange} />
                </div>
            </div>
            <div className='route__adminLeads__table'>
                <div className='route__adminLeads__table__headers'>
                    <p>ID</p>
                    <p>AffiliateID</p>
                    <p>Created</p>
                    <p>Status</p>
                    <p>Country</p>
                    <p>Offer name</p>
                    <p>Name</p>
                    <p>Phone</p>
                </div>
                <div className='route__adminLeads__table__content'>
                    {!spinner && !error ? leads?.map((lead, index) => {
                        return <div>
                            <div onClick={() => handleAdditionalInfoBox(index)} style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__adminLeads__table__content__row'>
                                <p>{lead.ID}</p>
                                <p>{lead.AffiliateID}</p>
                                <p>{moment(lead.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                                <p
                                    className='route__adminLeads__table__content__row__status'
                                    style={{ background: returnCurrentStatus(lead.Status).background, border: returnCurrentStatus(lead.Status).border, color: returnCurrentStatus(lead.Status).textColor }}
                                >
                                    {returnCurrentStatus(lead.Status).name}
                                </p>
                                <div className='route__adminLeads__table__content__row__country'>
                                    <p>{lead.OfferCountry}</p>
                                    <span class={`fi fi-${String(lead.OfferCountry).toLowerCase()}`}></span>
                                </div>
                                <p>{lead.OfferType} - {lead.OfferName} - {lead.OfferCountry}</p>
                                <p>{String(lead.ClientName).length > 15 ? String(lead.ClientName).slice(0, 15) + '...' : String(lead.ClientName)}</p>
                                <p>{String(lead.ClientPhone).length > 15 ? String(lead.ClientPhone).slice(0, 15) + '...' : String(lead.ClientPhone)}</p>
                                <img 
                                src='images/arrowDown.png' alt='' 
                                className={additionalInfoActive.state && additionalInfoActive.index === index ? 'route__adminLeads__table__content__row__arrow route__adminLeads__table__content__row__arrow__active'
                                    : 'route__adminLeads__table__content__row__arrow'}
                                />
                            </div>
                            <div
                                style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }}
                                className={additionalInfoActive.state && additionalInfoActive.index === index ? 'route__adminLeads__table__content__row__additionalInfo route__adminLeads__table__content__row__additionalInfo__active'
                                    : 'route__adminLeads__table__content__row__additionalInfo'}
                            >
                                <p><b>Additional Info:</b> {lead.AdditionalInfo}</p>
                                <p><b>Sub_id1:</b> {lead.SubID1}</p>
                                <p><b>Sub_id2:</b> {lead.SubID2}</p>
                                <p><b>Sub_id3:</b> {lead.SubID3}</p>
                                <p><b>Sub_id4:</b> {lead.SubID4}</p>
                                <p><b>FB pixel ID:</b> {lead.FBpixelID}</p>
                                <p><b>Tracking ID:</b> {lead.TrackingID}</p>
                                <p><b>Source:</b> {lead.Source}</p>
                                <p><b>Landing Price:</b> {lead.OfferPrice} {lead.OfferCurrency}</p>
                                <p><b>Payout:</b> {lead.OfferPayout} USD</p>
                                <p><b>Last processed:</b> {moment(lead.updatedAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                            </div>
                        </div>
                    }) : null}
                    {leads.length > 19 && <div className='route__adminLeads__table__content__pagination'>
                        {!paginationSpinner && !paginationMessage ? <div onClick={() => getLeadsPagination(20, leads?.length)} className='route__adminLeads__table__content__pagination__btn'>
                            <p>Load more leads</p>
                        </div> : null}
                        {paginationMessage && <p style={{ color: 'maroon', marginLeft: 'auto', marginRight: 'auto', display: 'block', fontSize: '14px' }}>{paginationMessage}</p>}
                        {paginationSpinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    </div>}
                    {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    {error && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
                    {leads.length === 0 && <p style={{ padding: '10px' }}>No data yet</p>}
                </div>
            </div>
        </div>
    )
}
