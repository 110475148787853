import React from 'react';
import "./index.scss";
import SimpleDropdown from '../../../components/SimpleDropdown';
import { countriesFull } from "../../../modules/countryModules";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import Spinner from '../../../components/Spinner';

export default function Register() {
  const [type, setType] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  const usernameRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const confirmPasswordRef = React.useRef(null);
  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const zipRef = React.useRef(null);
  const experienceRef = React.useRef(null);
  const skypeRef = React.useRef(null);
  const telegramRef = React.useRef(null);
  const whatsappRef = React.useRef(null);
  const checkboxRef = React.useRef(null);

  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__contentWrapper"));
  };

  const createNewUser = () => {

    if (
      !usernameRef.current.value ||
      !passwordRef.current.value ||
      !confirmPasswordRef.current.value ||
      !firstNameRef.current.value ||
      !lastNameRef.current.value ||
      !emailRef.current.value ||
      !zipRef.current.value ||
      !experienceRef.current.value ||
      !country
    ) {
      return setMessage('Please fill in the required fields');
    } else {
      if (passwordRef.current.value !== confirmPasswordRef.current.value) {
        return setMessage('Passwords do not match');
      } else {
        if(String(usernameRef.current.value).length < 8){
          return setMessage('Username must be at least 8 characters long');
        }else{
          if(String(passwordRef.current.value).length < 8){
            return setMessage('Password must be at least 8 characters long');
          }else{
            if (checkboxRef.current.checked) {
              setSpinner(true);
            } else {
              return setMessage('Please, accept the terms of use');
            }
          }
        }
      }
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/createNewUser`,
      data: {
        username: usernameRef.current.value,
        password: passwordRef.current.value,
        email: emailRef.current.value,
        FirstName: firstNameRef.current.value,
        LastName: lastNameRef.current.value,
        Country: country.code,
        ZIP: zipRef.current.value,
        Experience: experienceRef.current.value,
        Skype: skypeRef.current.value,
        Telegram: telegramRef.current.value,
        Whatsapp: whatsappRef.current.value,
        flags: { isAdvertiser: type === 'advertiser' ? true : false, isAffiliate: type === 'affiliate' ? true : false }
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setSuccess(true);
      }
      else {
        setMessage('Server error occurred');
      }
    }).catch(() => {
      setMessage('Server error occurred');
    }).finally(() => {
      setSpinner(false);
    });
  };

  return (
    <div className='route__register'>
      {!type && <div className='route__register__container'>
        <img className='route__register__container__abstract' src='images/registerabstract.svg' alt='' />
        <h1>Choose account type</h1>
        <div className='route__register__container__option'>
          <div onClick={() => setType('affiliate')} className='route__register__container__option__button'>
            <p>Sign Up as Affiliate</p>
          </div>
          <p>I want to promote your offers. I have traffic.</p>
        </div>
        <div onClick={() => setType('advertiser')} className='route__register__container__option'>
          <div className='route__register__container__option__button'>
            <p>Sign Up as Advertiser</p>
          </div>
          <p>I have my own offers. I need traffic.</p>
        </div>
      </div>}
      {type === 'affiliate' && !success && <div className='route__register__container'>
        <h1>Affiliate</h1>
        <p style={{color: 'white', background: '#8b1a1a'}}>{message}</p>
        <div className='route__register__container__form'>
          <input ref={usernameRef} type='text' placeholder='Username' />
          <input ref={passwordRef} type='password' placeholder='Password' />
          <input ref={confirmPasswordRef} type='password' placeholder='Confirm password' />
          <input ref={firstNameRef} type='text' placeholder='First Name' />
          <input ref={lastNameRef} type='text' placeholder='Last Name' />
          <input ref={emailRef} type='text' placeholder='E-mail' />
          <input ref={zipRef} type='text' placeholder='ZIP code' />
          <input ref={experienceRef} type='text' placeholder='Experience in years' />
          <SimpleDropdown search={true} heading='Country' className='route__register__container__form__dropdownHead' data={countriesFull} setSelected={setCountry} xCallback={() => { }} />
          <input ref={skypeRef} type='text' placeholder='Skype (optional)' />
          <input ref={telegramRef} type='text' placeholder='Telegram (optional)' />
          <input ref={whatsappRef} type='text' placeholder='Whatsapp (optional)' />
        </div>
        <div className='route__register__container__form__agree'>
          <span>
            <input ref={checkboxRef} type='checkbox' />
            <p>I agree with: <a onClick={() => animateNavigate('/terms')}>Terms and Conditions</a> and <a onClick={() => animateNavigate('/privacypolicy')}>Privacy Policy</a>.</p>
          </span>
          <div onClick={() => createNewUser()} className='route__register__container__form__agree__button'>
            {!spinner && <p>Finish registration</p>}
            {spinner && <Spinner color='black' style={{ width: '20px' }} />}
          </div>
        </div>
      </div>}
      {type === 'advertiser' && !success && <div className='route__register__container'>
        <h1>Advertiser</h1>
        <p style={{color: 'white', background: '#8b1a1a'}}>{message}</p>
        <div className='route__register__container__form'>
          <input ref={usernameRef} type='text' placeholder='Username' />
          <input ref={passwordRef} type='password' placeholder='Password' />
          <input ref={confirmPasswordRef} type='password' placeholder='Confirm password' />
          <input ref={firstNameRef} type='text' placeholder='First Name' />
          <input ref={lastNameRef} type='text' placeholder='Last Name' />
          <input ref={emailRef} type='text' placeholder='E-mail' />
          <input ref={zipRef} type='text' placeholder='ZIP code' />
          <input ref={experienceRef} type='text' placeholder='Experience in years' />
          <SimpleDropdown search={true} heading='Country' className='route__register__container__form__dropdownHead' data={countriesFull} setSelected={setCountry} xCallback={() => { }} />
          <input ref={skypeRef} type='text' placeholder='Skype (optional)' />
          <input ref={telegramRef} type='text' placeholder='Telegram (optional)' />
          <input ref={whatsappRef} type='text' placeholder='Whatsapp (optional)' />
        </div>
        <div className='route__register__container__form__agree'>
          <span>
            <input ref={checkboxRef} type='checkbox' />
            <p>I agree with: <a onClick={() => animateNavigate('/terms')}>Terms and Conditions</a> and <a onClick={() => animateNavigate('/privacypolicy')}>Privacy Policy</a>.</p>
          </span>
          <div onClick={() => createNewUser()} className='route__register__container__form__agree__button'>
            {!spinner && <p>Finish registration</p>}
            {spinner && <Spinner color='black' style={{ width: '20px' }} />}
          </div>
        </div>
      </div>}
      {success && <div className='route__register__container'>
        <img className='route__register__container__successImg' src='images/successmessage.svg' alt='' />
        <p className='route__register__container__success'>You have successfully registered, please wait for our manager to contact you to approve your account, after that you can log in. Good luck!</p>
        <p className='route__register__container__success'>Your manager's telegram: dzenan_LeadSquar</p>
      </div>}
    </div>
  )
}
