import React from 'react';
import "./index.scss";
import axios from "axios";
import moment from 'moment';
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';
import DateRangePicker from '../../components/DateRangeCalendar';
import {
    Chart,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip,
    Legend
} from 'chart.js';

Chart.register(CategoryScale, LinearScale, LineController, LineElement, PointElement, Tooltip, Legend);
let myChart;

export default function AdvertiserStatistics(props) {
    const [error, setError] = React.useState(null);
    const [chartError, setChartError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);
    const [chartSpinner, setChartSpinner] = React.useState(true);
    const [tableData, setTableData] = React.useState([]);
    const [selectedOffer, setSelectedOffer] = React.useState([]);
    const [search, setSearch] = React.useState(null);
    const [dropdown, setDropdown] = React.useState(false);
    const [offers, setOffers] = React.useState([]);
    const [labels, setLabels] = React.useState(null);
    const [expectData, setExpectData] = React.useState(null);
    const [leadsData, setLeadsData] = React.useState(null);
    const [confirmedData, setConfirmedData] = React.useState(null);
    const [rejectData, setRejectData] = React.useState(null);
    const [trashData, setTrashData] = React.useState(null);
    const [duplicateData, setDuplicateData] = React.useState(null);
    const [approveRateData, setApproveRateData] = React.useState(null);
    const [payoutsData, setPayoutsData] = React.useState(null);

    const searchRef = React.useRef(null);
    const chartRef = React.useRef(null);

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const [dateRange, setDateRange] = React.useState({ start: sevenDaysAgo, end: today, firstTime: true });

    const getTableData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistics/getTableStatistics`,
            data: {
                AdvertiserID: props.userData?.ID,
                startDate: dateRange.start,
                endDate: dateRange.end,
                OfferID: selectedOffer?.ID ? selectedOffer.ID : null
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setTableData(res.data.data);
                setLabels(res.data.data.map(stat => stat.date));
                setExpectData(res.data.data.map(stat => stat.expect));
                setLeadsData(res.data.data.map(stat => stat.leads));
                setConfirmedData(res.data.data.map(stat => stat.confirmed));
                setRejectData(res.data.data.map(stat => stat.reject));
                setTrashData(res.data.data.map(stat => stat.trash));
                setDuplicateData(res.data.data.map(stat => stat.trashDuplicate));
                setApproveRateData(res.data.data.map(stat => stat.approvedRateWithoutTrash));
                setPayoutsData(res.data.data.map(stat => stat.confirmedUSD));
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
            setChartSpinner(false);
        });
    }

    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                filters: [{ name: 'Name', op: 'like', value: search }],
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data?.data);
                setDropdown(true);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const searchOfffer = (event) => {
        if (event.key === "Enter") {
            const searchQuery = searchRef.current.value.toLowerCase();
            setSearch(searchQuery);
        }
    };

    React.useEffect(() => {
        if (!chartSpinner && labels && expectData && leadsData && confirmedData) {
            if(myChart){
                myChart.destroy();
            }
            const ctx = chartRef.current.getContext('2d');
            myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Cost',
                            data: payoutsData,
                            borderColor: '#008080',
                            fill: false,
                            hidden: true
                        },
                        {
                            label: 'Approve rate',
                            data: approveRateData,
                            borderColor: 'green',
                            fill: false,
                            hidden: true
                        },
                        {
                            label: 'Total Leads',
                            data: leadsData,
                            borderColor: '#9b59b6',
                            fill: false,
                            hidden: false
                        },
                        {
                            label: 'Confirmed Leads',
                            data: confirmedData,
                            borderColor: '#2ecc71',
                            fill: false,
                            hidden: false
                        },
                        {
                            label: 'Expect leads',
                            data: expectData,
                            borderColor: '#3498db',
                            fill: false,
                            hidden: false
                        },
                        {
                            label: 'Reject leads',
                            data: rejectData,
                            borderColor: '#e74c3c',
                            fill: false,
                            hidden: true
                        },
                        {
                            label: 'Trash leads',
                            data: trashData,
                            borderColor: '#8b4513',
                            fill: false,
                            hidden: true
                        },
                        {
                            label: 'Duplicate leads',
                            data: duplicateData,
                            borderColor: '#d35400',
                            fill: false,
                            hidden: true
                        },
                    ]
                },
                options: {
                    scales: {
                        x: {
                            type: 'category',
                            title: {
                                display: true,
                                text: 'Date'
                            }
                        },
                        y: {
                            type: 'linear',
                            title: {
                                display: false
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.dataset.label || '';
                                    const value = context.raw;
                                    return `${label}: ${value}`;
                                }
                            }
                        },
                        legend: {
                            labels: {
                                font: {
                                    size: window.innerWidth < 660 ? '10' : '14',
                                    family: 'Figtree',
                                    weight: '600',
                                },
                                color: '#555',
                                boxWidth: 10,
                                padding: 10,
                            },
                        },
                    }
                }
            });
        }
    }, [chartSpinner, labels, expectData, leadsData, confirmedData]);

    React.useEffect(() => {
        if (search) {
            getOffers();
        } else {
            setDropdown(false);
        }
    }, [search]);

    React.useEffect(() => {
        if (dateRange.start !== null && dateRange.end !== null && !dateRange.firstTime) {
            getTableData();
        }
    }, [dateRange]);

    React.useEffect(() => {
        getTableData();
    }, [selectedOffer]);

    return (
        <div className='route__advStatistics'>
            <div className='route__advStatistics__header'>
                <h1>Statistics</h1>
                <div className='route__advStatistics__header__filters'>
                    <div className='route__advStatistics__header__filters__dropdown'>
                        {selectedOffer.length === 0 && <input placeholder='Search offer by name (press enter)' ref={searchRef} onKeyDown={searchOfffer} type='text' />}
                        {selectedOffer?.Name ? <p onClick={() => { setSelectedOffer([]); setSearch(null) }}>{selectedOffer?.Type}-{selectedOffer?.Name}-{selectedOffer?.Country}-#{selectedOffer?.ID}</p> : null}
                        <div className={dropdown ? 'route__advStatistics__header__filters__dropdown__body route__advStatistics__header__filters__dropdown__body__active' : 'route__advStatistics__header__filters__dropdown__body'}>
                            {
                                !spinner && !error ? offers.map(item => {
                                    return <div onClick={() => { setSelectedOffer(item); setDropdown(false) }} className='route__advStatistics__header__filters__dropdown__body__item'>
                                        <p>{item.Type}-{item.Name}-{item.Country}-#{item.ID}</p>
                                    </div>
                                }) : null
                            }
                            {spinner && <Spinner style={{ width: '24px', height: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                            {error && <p style={{ color: 'maroon', fontSize: '12px' }}>{error}</p>}
                        </div>
                    </div>
                    <DateRangePicker setRange={setDateRange} range={dateRange} />
                </div>
            </div>
            <div className='ls___chart__container' style={{ width: '100%' }}>
                {!chartSpinner && labels && expectData && leadsData && confirmedData && !chartError ? <canvas width='100%' height='500px' ref={chartRef} className='ls___chart'></canvas> : null}
                {chartSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                {chartError && <p style={{ color: 'maroon', fontSize: '15px' }}>{chartError}</p>}
            </div>
            <div className='route__advStatistics__table'>
                <div className='route__advStatistics__table__headers'>
                    <p>Date</p>
                    <p>Approve rate</p>
                    <p>Approve(trash)</p>
                    <p>Clicks</p>
                    <p>Leads</p>
                    <p>Expect</p>
                    <p>Confirmed</p>
                    <p>Reject</p>
                    <p>Trash</p>
                    <p>Duplicate</p>
                    <p>Error</p>
                    <p>Cost </p>
                </div>
                <div className='route__advStatistics__table__content'>
                    {!spinner && !error ? tableData?.map((record, index) => {
                        return <div style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__advStatistics__table__content__row'>
                            <p>{moment(record.date).format('DD.MM.YYYY.')}</p>
                            <p>{isNaN(Number(record.approvedRateWithoutTrash)) ? 0.00 : Number(record.approvedRateWithoutTrash).toFixed(2)} %</p>
                            <p>{isNaN(Number(record.approvedRateWithTrash)) ? 0.00 : Number(record.approvedRateWithTrash).toFixed(2)} %</p>
                            <p>{record.totalClicks}</p>
                            <p>{record.leads}</p>
                            <p>{record.expect}</p>
                            <p>{record.confirmed}</p>
                            <p>{record.reject}</p>
                            <p>{record.trash}</p>
                            <p>{record.trashDuplicate}</p>
                            <p>{record.error}</p>
                            <p>${record.confirmedUSD}</p>
                        </div>
                    }) : null}
                    {spinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    {error && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
                    {tableData.length === 0 && <p style={{ padding: '10px' }}>No data yet</p>}
                </div>
            </div>
        </div>
    )
}
