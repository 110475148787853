import React from "react";
import "./index.css";
import PropTypes from "prop-types";

const Spinner = (props) => {
    const propColor = props.color ?? "#00A3FF";
    const styles = {};

    switch (props.align) {
        case "left": break;
        case "center": {
            styles["margin"] = "0 auto";
            styles["maxWidth"] = "100%";
            styles["width"] = "100%";
            break;
        }
        case "right": {
            styles["left"] = "auto";
        }
        default: break;
    }

    return (
        <svg
            className="customComponents__spinner"
            viewBox="0 0 50 50"
            style={{ ...styles, ...props.style }}
        >
            <rect
                className="customComponents__spinner__path"
                style={{ fill: propColor }}
                x="15" y="15" width="20" height="20"
            />
        </svg>
    );
};

Spinner.propTypes = {
    color: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
};

export default Spinner;
