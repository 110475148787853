import React from 'react';
import "./index.scss";
import Spinner from "../../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../../actions/timestampActions";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
  const [spinner, setSpinner] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [logged, setLogged] = React.useState(false);

  const curDispatch = useDispatch();

  const passRef = React.useRef(null);
  const usernameRef = React.useRef(null);

  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__contentWrapper"));
  };

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.setUserData(res.data.data.UserInfo);
        if(!res.data.data.UserInfo.AccessConfirmed){
          props.setAccessConfirmed(false);
        }
        else{
          props.setAccessConfirmed(true);
        }
        if(res.data.data.UserInfo?.Flags?.isAdvertiser){
          return animateNavigate('/advertiser/offers');
        }else if(res.data.data.UserInfo?.Flags?.isAffiliate){
          return animateNavigate('/affiliate/dashboard');
        }else if(res.data.data.UserInfo?.Flags?.isAdmin){
          return animateNavigate('/admin/dashboard');
        }
      }
      else {
        return setLogged(false);
      }
    }).catch(() => {
      return setLogged(false);
    }).finally(() => {

    });
  };

  const loginHandler = () => {
    if (!usernameRef.current.value || !passRef.current.value) {
      return setMessage('Popunite sva polja!');
    }

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/login`,
      data: {
        username: usernameRef.current.value,
        password: passRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        curDispatch(timestampActions.updateTimestamp());
        checkLogin();
        props.setLogged(true);
      } else {
        return setMessage('Prijava nije uspjela');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div className='route__login'>
      <div className='route__login__wrapper'>
        <img className='route__login__wrapper__abstract' src='images/registerabstract.svg' alt='' />
        <h1>Login</h1>
        <input ref={usernameRef} type='text' placeholder='Username' />
        <input ref={passRef} type='password' placeholder='Password' />
        <div className='route__login__wrapper__bottom'>
          <p>Forgot password?</p>
          <div className='route__login__wrapper__bottom__button' onClick={() => loginHandler()}>
            {spinner && <Spinner color='black' style={{ width: '34px' }} align='center' />}
            {!spinner && <p>Login</p>}
          </div>
        </div>
        {message && <p style={{ color: 'red' }}>{message}</p>}
      </div>
    </div>
  )
}
