import React from 'react';
import "./index.scss";
import Spinner from '../../components/Spinner';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

export default function Dashboard(props) {
  const [spinner, setSpinner] = React.useState(true);
  const [offersSpinner, setOffersSpinner] = React.useState(true);
  const [newsSpinner, setNewsSpinner] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [offersError, setOffersError] = React.useState(null);
  const [newsError, setNewsError] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [offers, setOffers] = React.useState([]);
  const [news, setNews] = React.useState([]);

  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__toAnimate"));
  };

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/statistics/getDashboardStatistics`,
      data: {
        AffiliateID: props.userData?.ID
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data.data);
      } else {
        setError('Unknown error occurred');
      }
    }).catch(() => {
      setError('Unknown error occurred');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getOffers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/offers/getAllOffers`,
      data: {
        filters: [{ name: 'IsFeatured', op: 'eq', value: true }],
        offset: 0,
        limit: 5
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data.data);
      } else {
        setOffersError('top offers error occurred');
      }
    }).catch(() => {
      setOffersError('top offers error occurred');
    }).finally(() => {
      setOffersSpinner(false);
    });
  }

  const getNews = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/news/getAllNews`,
      data: {
        offset: 0,
        limit: 5
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        setNews(res.data.data);
      } else {
        setNewsError('news error occurred');
      }
    }).catch(() => {
      setOffersError('news error occurred');
    }).finally(() => {
      setNewsSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
    getOffers();
    getNews();
  }, []);

  return (
    <div className='route__dashboard'>
      {!spinner && !error ? <div className='route__dashboard__kpis'>
        <div className='route__dashboard__kpis__kpi'>
          <div className='route__dashboard__kpis__kpi__top'>
            <div className='route__dashboard__kpis__kpi__top__left'>
              <img src='images/click.png' alt='' />
              <h1>Unique clicks</h1>
            </div>
            <span
              className={data?.today?.changeInClicks?.statusChange === 'positive' ? 'route__dashboard__kpis__kpi__top__change__positive' : data?.today?.changeInClicks?.statusChange === 'negative' ? 'route__dashboard__kpis__kpi__top__change__negative' : 'route__dashboard__kpis__kpi__top__change__neutral'}
            >
              <p>{data?.today?.changeInClicks?.percentage}%</p>
            </span>
          </div>
          <div className='route__dashboard__kpis__kpi__bottom'>
            <div className='route__dashboard__kpis__kpi__bottom__left'>
              <label>Today</label>
              <p>{data?.today?.totalClicks}</p>
            </div>
            <div className='route__dashboard__kpis__kpi__bottom__right'>
              <span><p>Yesterday</p> <strong>{data?.yesterday?.totalClicks}</strong></span>
              <span><p>Current month</p> <strong>{data?.currentMonth?.totalClicks}</strong></span>
              <span><p>Last month</p> <strong>{data?.lastMonth?.totalClicks}</strong></span>
            </div>
          </div>
        </div>
        <div className='route__dashboard__kpis__kpi'>
          <div className='route__dashboard__kpis__kpi__top'>
            <div className='route__dashboard__kpis__kpi__top__left'>
              <img src='images/approverate.png' alt='' />
              {window.innerWidth > 1110 && <h1>Approve rate with trash</h1>}
              {window.innerWidth <= 1110 && <h1>Approve rate (w. tr.)</h1>}
            </div>
            <span
              className={data?.today?.changeInApproveRate?.statusChange === 'positive' ? 'route__dashboard__kpis__kpi__top__change__positive' : data?.today?.changeInApproveRate?.statusChange === 'negative' ? 'route__dashboard__kpis__kpi__top__change__negative' : 'route__dashboard__kpis__kpi__top__change__neutral'}
            >
              <p>{data?.today?.changeInApproveRate?.percentage}%</p>
            </span>
          </div>
          <div className='route__dashboard__kpis__kpi__bottom'>
            <div className='route__dashboard__kpis__kpi__bottom__left'>
              <label>Today</label>
              <p>{data?.today?.approveRate}%</p>
            </div>
            <div className='route__dashboard__kpis__kpi__bottom__right'>
              <span><p>Yesterday</p> <strong>{data?.yesterday?.approveRate}%</strong></span>
              <span><p>Current month</p> <strong>{data?.currentMonth?.approveRate}%</strong></span>
              <span><p>Last month</p> <strong>{data?.lastMonth?.approveRate}%</strong></span>
            </div>
          </div>
        </div>
        <div className='route__dashboard__kpis__kpi'>
          <div className='route__dashboard__kpis__kpi__top'>
            <div className='route__dashboard__kpis__kpi__top__left'>
              <img src='images/confirmed.png' alt='' />
              <h1>Confirmed leads</h1>
            </div>
            <span
              className={data?.today?.changeInApprovedLeads?.statusChange === 'positive' ? 'route__dashboard__kpis__kpi__top__change__positive' : data?.today?.changeInApprovedLeads?.statusChange === 'negative' ? 'route__dashboard__kpis__kpi__top__change__negative' : 'route__dashboard__kpis__kpi__top__change__neutral'}
            >
              <p>{data?.today?.changeInApprovedLeads?.percentage}%</p>
            </span>
          </div>
          <div className='route__dashboard__kpis__kpi__bottom'>
            <div className='route__dashboard__kpis__kpi__bottom__left'>
              <label>Today</label>
              <p>{data?.today?.approvedLeads}</p>
            </div>
            <div className='route__dashboard__kpis__kpi__bottom__right'>
              <span><p>Yesterday</p> <strong>{data?.yesterday?.approvedLeads}</strong></span>
              <span><p>Current month</p> <strong>{data?.currentMonth?.approvedLeads}</strong></span>
              <span><p>Last month</p> <strong>{data?.lastMonth?.approvedLeads}</strong></span>
            </div>
          </div>
        </div>
        <div className='route__dashboard__kpis__kpi'>
          <div className='route__dashboard__kpis__kpi__top'>
            <div className='route__dashboard__kpis__kpi__top__left'>
              <img src='images/expect.png' alt='' />
              <h1>Expect leads</h1>
            </div>
            <span
              className={data?.today?.changeInExpectLeads?.statusChange === 'positive' ? 'route__dashboard__kpis__kpi__top__change__positive' : data?.today?.changeInExpectLeads?.statusChange === 'negative' ? 'route__dashboard__kpis__kpi__top__change__negative' : 'route__dashboard__kpis__kpi__top__change__neutral'}
            >
              <p>{data?.today?.changeInExpectLeads?.percentage}%</p>
            </span>
          </div>
          <div className='route__dashboard__kpis__kpi__bottom'>
            <div className='route__dashboard__kpis__kpi__bottom__left'>
              <label>Today</label>
              <p>{data?.today?.expectLeads}</p>
            </div>
            <div className='route__dashboard__kpis__kpi__bottom__right'>
              <span><p>Yesterday</p> <strong>{data?.yesterday?.expectLeads}</strong></span>
              <span><p>Current month</p> <strong>{data?.currentMonth?.expectLeads}</strong></span>
              <span><p>Last month</p> <strong>{data?.lastMonth?.expectLeads}</strong></span>
            </div>
          </div>
        </div>
        <div className='route__dashboard__kpis__kpi'>
          <div className='route__dashboard__kpis__kpi__top'>
            <div className='route__dashboard__kpis__kpi__top__left'>
              <img src='images/leads.png' alt='' />
              <h1>Leads</h1>
            </div>
            <span
              className={data?.today?.changeInLeads?.statusChange === 'positive' ? 'route__dashboard__kpis__kpi__top__change__positive' : data?.today?.changeInLeads?.statusChange === 'negative' ? 'route__dashboard__kpis__kpi__top__change__negative' : 'route__dashboard__kpis__kpi__top__change__neutral'}
            >
              <p>{data?.today?.changeInLeads?.percentage}%</p>
            </span>
          </div>
          <div className='route__dashboard__kpis__kpi__bottom'>
            <div className='route__dashboard__kpis__kpi__bottom__left'>
              <label>Today</label>
              <p>{data?.today?.totalLeads}</p>
            </div>
            <div className='route__dashboard__kpis__kpi__bottom__right'>
              <span><p>Yesterday</p> <strong>{data?.yesterday?.totalLeads}</strong></span>
              <span><p>Current month</p> <strong>{data?.currentMonth?.totalLeads}</strong></span>
              <span><p>Last month</p> <strong>{data?.lastMonth?.totalLeads}</strong></span>
            </div>
          </div>
        </div>
        <div className='route__dashboard__kpis__kpi'>
          <div className='route__dashboard__kpis__kpi__top'>
            <div className='route__dashboard__kpis__kpi__top__left'>
              <img src='images/revenue.png' alt='' />
              <h1>Revenue</h1>
            </div>
            <span
              className={data?.today?.changeInRevenue?.statusChange === 'positive' ? 'route__dashboard__kpis__kpi__top__change__positive' : data?.today?.changeInRevenue?.statusChange === 'negative' ? 'route__dashboard__kpis__kpi__top__change__negative' : 'route__dashboard__kpis__kpi__top__change__neutral'}
            >
              <p>{data?.today?.changeInRevenue?.percentage}%</p>
            </span>
          </div>
          <div className='route__dashboard__kpis__kpi__bottom'>
            <div className='route__dashboard__kpis__kpi__bottom__left'>
              <label>Today</label>
              <p>${data?.today?.revenue}</p>
            </div>
            <div className='route__dashboard__kpis__kpi__bottom__right'>
              <span><p>Yesterday</p> <strong>${data?.yesterday?.revenue}</strong></span>
              <span><p>Current month</p> <strong>${data?.currentMonth?.revenue}</strong></span>
              <span><p>Last month</p> <strong>${data?.lastMonth?.revenue}</strong></span>
            </div>
          </div>
        </div>
      </div> : null}
      {error && <p style={{ color: 'marron', fontSize: '15px' }}>{error}</p>}
      {spinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
      <div className='route__dashboard__bottom'>
        {!newsSpinner && !newsError ? <div className='route__dashboard__bottom__left'>
          <h1>LeadSquar news</h1>
          <div className='route__dashboard__bottom__left__news'>
            {news?.map(article => {
              return <div onClick={() => animateNavigate(`/affiliate/news/read?articleID=${article.ID}`)} className='route__dashboard__bottom__left__news__article'>
                <i>{moment(article.createdAt).format('DD.MM.YYYY.')}</i>
                <h1>{article.Heading}</h1>
                <small>{article.Category}</small>
                <p>{article.Description}</p>
              </div>
            })}
          </div>
        </div> : null}
        {newsSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        {newsError && <p style={{ color: 'marron', fontSize: '15px' }}>{newsError}</p>}
        {!offersSpinner && !offersSpinner ? <div className='route__dashboard__bottom__right'>
          <h1>Top offers</h1>
          <div className='route__dashboard__bottom__right__offers'>
            {offers?.map(offer => {
              return <div onClick={() => animateNavigate(`/affiliate/offers/offerpage?offerID=${offer.ID}`)} className='route__dashboard__bottom__right__offers__offer'>
                <imG src={`${backendModule.backendURL}/${offer.ImagePath}`} alt='' />
                <div className='route__dashboard__bottom__right__offers__offer__info'>
                  <h1>{offer.Type} - {offer.Name} - {offer.Country}</h1>
                  <span>ID: {offer.ID}</span>
                  <p>Payout: ${offer.Payout}</p>
                </div>
              </div>
            })}
          </div>
        </div> : null}
        {offersSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        {offersError && <p style={{ color: 'marron', fontSize: '15px' }}>{offersError}</p>}
      </div>
    </div>
  )
}
