import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { offerStatuses, categories, payoutsModels, offersPrivacy } from "../../modules/scppModule";
import { countriesFull } from '../../modules/countryModules';
import { animateBox } from "../../modules/componentAnimation";
import animateModule from "../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import YesNoModal from "../../components/Modals/YesNoModal";
import SimpleDropdown from '../../components/SimpleDropdown';
import Spinner from '../../components/Spinner';

export default function AdminOffers(props) {
  const [categoryFilter, setCategoryFilter] = React.useState({ name: 'Categories', value: 'all' });
  const [poModelFilter, setPoModelFilter] = React.useState({ name: 'PO Models', value: 'all' });
  const [privacyFilter, setPrivacyFilter] = React.useState({ name: 'Privacy', value: 'all' });
  const [typeFilter, setTypeFilter] = React.useState({ name: 'Type', value: 'all' });
  const [statusFilter, setStatusFilter] = React.useState({ name: 'Status', value: 0, enabled: 'no' });
  const [countryFilter, setCountryFilter] = React.useState({ name: 'Country', code: 'all' });
  const [error, setError] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [paginationSpinner, setPaginationSpinner] = React.useState(false);
  const [paginationMessage, setPaginationMessage] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState(null);
  const [offers, setOffers] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState(false);

  const searchRef = React.useRef(null);

  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__toAnimate"));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchValue(searchRef.current.value);
    }
  };

  const getOffers = (offset, limit) => {
    setPaginationMessage(null);
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/offers/getAllOffers`,
      data: {
        filters: [
          { name: 'AdvertiserID', op: 'eq', value: props.userData?.ID },
          statusFilter.enabled === 'no' ? { name: 'Status', op: 'eq', value: 0 } : statusFilter.enabled === 'yes' ? { name: 'Status', op: 'eq', value: statusFilter.value } : null,
          categoryFilter.value !== 'all' ? { name: 'Category', op: 'eq', value: categoryFilter.value } : null,
          poModelFilter.value !== 'all' ? { name: 'PayoutModel', op: 'eq', value: poModelFilter.value } : null,
          privacyFilter.value !== 'all' ? { name: 'Privacy', op: 'eq', value: privacyFilter.value } : null,
          typeFilter.value !== 'all' ? { name: 'Type', op: 'eq', value: typeFilter.value } : null,
          countryFilter.code !== 'all' ? { name: 'Country', op: 'eq', value: countryFilter.code } : null,
          String(searchValue).includes('N:') ? { name: 'Name', op: 'like', value: String(searchValue).replace('N:', '') } : null,
          String(searchValue).includes('ID:') ? { name: 'ID', op: 'eq', value: String(searchValue).replace('ID:', '') } : null
        ],
        offset: offset,
        limit: limit
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data?.data);
      } else {
        setError('Unknown error occurred');
      }
    }).catch(() => {
      setError('Unknown error occurred');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getOffersPagination = (offset, limit) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/offers/getAllOffers`,
      data: {
        filters: [
          { name: 'AdvertiserID', op: 'eq', value: props.userData?.ID },
          statusFilter.enabled === 'no' ? { name: 'Status', op: 'eq', value: 0 } : statusFilter.enabled === 'yes' ? { name: 'Status', op: 'eq', value: statusFilter.value } : null,
          categoryFilter.value !== 'all' ? { name: 'Category', op: 'eq', value: categoryFilter.value } : null,
          poModelFilter.value !== 'all' ? { name: 'PayoutModel', op: 'eq', value: poModelFilter.value } : null,
          privacyFilter.value !== 'all' ? { name: 'Privacy', op: 'eq', value: privacyFilter.value } : null,
          typeFilter.value !== 'all' ? { name: 'Type', op: 'eq', value: typeFilter.value } : null,
          countryFilter.code !== 'all' ? { name: 'Country', op: 'eq', value: countryFilter.code } : null,
          String(searchValue).includes('N:') ? { name: 'Name', op: 'like', value: String(searchValue).replace('N:', '') } : null,
          String(searchValue).includes('ID:') ? { name: 'ID', op: 'eq', value: String(searchValue).replace('ID:', '') } : null
        ],
        offset: offset,
        limit: limit
      },
      ...backendModule.axiosConfig,
    }).then(res => {
      if (res.data.status === "ok") {
        if (res.data.data.length === 0) {
          setPaginationMessage('There are no more offers');
        }
        else {
          setOffers(prev => [...prev, ...res.data.data]);
        }
      } else {
        setPaginationMessage('Unknown error occurred');
      }
    }).catch(() => {
      setPaginationMessage('Unknown error occurred');
    }).finally(() => {
      setPaginationSpinner(false);
    });
  }

  const returnCategory = (ID) => {
    return categories.find(cg => cg.value === Number(ID))?.name;
  }
  const returnPrivacy = (ID) => {
    return offersPrivacy.find(pv => pv.value === Number(ID))?.name;
  }
  const returnPayoutModel = (ID) => {
    return payoutsModels.find(pm => pm.value === Number(ID))?.name;
  }

  React.useEffect(() => {
    getOffers(0, 12);
  }, [categoryFilter, countryFilter, poModelFilter, privacyFilter, searchValue, typeFilter, statusFilter]);

  return (
    <div className='route__advertiserOffers'>
      <div className='route__advertiserOffers__header'>
        <h1>Offers</h1>
        <div className='route__advertiserOffers__header__filters'>
          <div onClick={() => setActiveFilters(f => !f)} className='route__advertiserOffers__header__filters__button'>
            <img src='images/filters.png' alt='' />
            <p>{!activeFilters ? 'Show filters' : 'Hide filters'}</p>
          </div>
        </div>
        <div className={activeFilters ? 'route__advertiserOffers__header__filters__box route__advertiserOffers__header__filters__box__active' : 'route__advertiserOffers__header__filters__box'}>
          <div className='route__advertiserOffers__header__filters__box__search'>
            <img onClick={() => setSearchValue(searchRef.current.value)} src='images/search.png' alt='' />
            <input onKeyDown={handleKeyDown} ref={searchRef} type='text' placeholder='Search... N:name ID:id' />
          </div>
          <SimpleDropdown search='true' headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Country' data={[{ name: 'Country - all', code: 'all' }, ...countriesFull]} setSelected={setCountryFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Categories' data={[...categories, { name: 'Categories - all', value: 'all' }]} setSelected={setCategoryFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='PO Models' data={[...payoutsModels, { name: 'PO Models - all', value: 'all' }]} setSelected={setPoModelFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Privacy' data={[...offersPrivacy, { name: 'Privacy - all', value: 'all' }]} setSelected={setPrivacyFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Type' data={[{ name: 'LOW', value: 'LOW' }, { name: 'FULL', value: 'FULL' }, { name: 'Type - all', value: 'all' }]} setSelected={setTypeFilter} />
          <SimpleDropdown headTextStyle={{ color: '#A0AEC0' }} headStyle={{ height: '30px', background: 'transparent', border: '1px solid #A0AEC0', borderRadius: '5px' }} heading='Status' data={[{ name: 'Active', value: 0, enabled: 'yes' }, { name: 'Paused', value: 1, enabled: 'yes' }]} setSelected={setStatusFilter} />
        </div>
      </div>
      <div className='route__advertiserOffers__offersGrid'>
        <div className='route__advertiserOffers__offersGrid__content'>
          {
            !spinner && !error ? offers?.map(offer => {
              return <div className='route__advertiserOffers__offersGrid__content__singleOffer'>
                <div className='route__advertiserOffers__offersGrid__content__singleOffer__top'>
                  <img src={`${backendModule.backendURL}/${offer.ImagePath}`} alt='' />
                  <div className='route__advertiserOffers__offersGrid__content__singleOffer__top__right'>
                    <h1>{offer.Type} - {offer.Name} - {offer.Country}</h1>
                    <span className='route__advertiserOffers__offersGrid__content__singleOffer__top__right__span'>
                      <p className='route__advertiserOffers__offersGrid__content__singleOffer__top__right__span__id'>ID: {offer.ID}</p>
                      <p className='route__advertiserOffers__offersGrid__content__singleOffer__top__right__span__privacy'>{returnPrivacy(offer.Privacy)}</p>
                      <p className='route__advertiserOffers__offersGrid__content__singleOffer__top__right__span__category'>{returnCategory(offer.Category)}</p>
                    </span>
                  </div>
                </div>
                <div className='route__advertiserOffers__offersGrid__content__singleOffer__middle'>
                  <div className='route__advertiserOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Country</label>
                    <div className='route__advertiserOffers__offersGrid__content__singleOffer__middle__field__country'>
                      <span class={`fi fi-${String(offer.Country).toLowerCase()}`}></span>
                      <p>{offer.Country}</p>
                    </div>
                  </div>
                  <div className='route__advertiserOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Price</label>
                    <p>{offer.Price} {offer.Currency}</p>
                  </div>
                  <div className='route__advertiserOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Payout</label>
                    <p>${offer.Payout}</p>
                  </div>
                  <div className='route__advertiserOffers__offersGrid__content__singleOffer__middle__field'>
                    <label>Squers</label>
                    <p>{offer.Squers}</p>
                  </div>
                </div>
                <div className='route__advertiserOffers__offersGrid__content__singleOffer__bottom'>
                  <div onClick={() => animateNavigate(`/advertiser/offers/offerpage?offerID=${offer.ID}`)} className='route__advertiserOffers__offersGrid__content__singleOffer__bottom__btn'>
                    <p>INFO</p>
                  </div>
                  <div className='route__advertiserOffers__offersGrid__content__singleOffer__bottom__model'>
                    <p>{returnPayoutModel(offer.PayoutModel)}</p>
                  </div>
                </div>
              </div>
            }) : null
          }
          {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
          {error && <p style={{ color: 'red', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
        </div>
      </div>
      {offers?.length > 11 && <div className='route__advertiserOffers__offersGrid__pagination'>
        {!paginationSpinner && !paginationMessage ? <div onClick={() => { setPaginationSpinner(true); getOffersPagination(offers?.length, 12) }} className='route__advertiserOffers__offersGrid__pagination__button'>
          <p>Load more offers</p>
        </div> : null}
        {paginationSpinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        {paginationMessage && <p style={{ color: 'maroon', fontSize: '15px' }}>{paginationMessage}</p>}
      </div>}
    </div>
  )
}