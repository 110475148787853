import React from 'react';
import "./index.scss";
import moment from 'moment';

export default function UrgentNews(props) {
    return (
        <div className='component__urgentNews'>
            <div className='component__urgentNews__wrapper'>
                <div className='component__urgentNews__wrapper__header'>
                    <h1>Urgent news!</h1>
                    <img onClick={() => props.setModal(false)} src='images/closeModal.png' alt='' />
                </div>
                <div className='component__urgentNews__wrapper__body'>
                    <small>{moment(props.article[0]?.createdAt).format('DD.MM.YYYY.')}</small>
                    <h1>{props.article[0]?.Heading}</h1>
                    <p dangerouslySetInnerHTML={{ __html: props.article[0]?.Text }}></p>
                </div>
            </div>
        </div>
    )
}
