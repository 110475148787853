export const leadStatuses=[ 
  {name: 'Accepted', value: 0, background: '#f5f5f5', border: '1px solid #d3d3d3', textColor: '#6b6b6b'},
  {name: 'Expect', value: 1, background: '#e6f1f8', border: '1px solid #c1d9e8', textColor: '#4a6d8c'},
  {name: 'Confirmed', value: 2, background: '#e6f7e6', border: '1px solid #c1e6c1', textColor: '#4a7a4f'},
  {name: 'Reject', value: 3, background: '#fff5e6', border: '1px solid #ffd0a6', textColor: '#cc7a3e'},
  {name: 'Trash', value: 4, background: '#f9e6e6', border: '1px solid #d1a1a1', textColor: '#7a3d3d'},
  {name: 'Duplicate', value: 5, background: '#f9e6e6', border: '1px solid #d1a1a1', textColor: '#7a3d3d'},
  {name: 'Test', value: 6, background: '#f9e6e6', border: '1px solid #d1a1a1', textColor: '#7a3d3d'},
  {name: 'Error', value: 7, background: '#fbe0e0', border: '1px solid #f1a1a1', textColor: '#9e2a2f'}
];

export const offerStatuses=[ 
  {name: 'Active', value: 0},
  {name: 'Paused', value: 1}
];

export const categories=[ 
  {name: 'Health', value: 0},
  {name: 'Potency', value: 1},
  {name: 'Weight loss', value: 2},
  {name: 'Kitchen', value: 3},
  {name: 'Ecom', value: 4},
  {name: 'Crypto', value: 5}
];

export const payoutsModels=[ 
  {name: 'COD', value: 0},
  {name: 'CPL', value: 1},
  {name: 'CPS', value: 2},
  {name: 'CPI', value: 3},
  {name: 'Revenue Share', value: 4},
  {name: 'CPC', value: 5}
];

export const offersPrivacy=[ 
  {name: 'Public access', value: 0},
  {name: 'Apply needed', value: 1},
  {name: 'Private', value: 2}
];

export const paymentStatuses = [
  {name: 'Pending', value: 0, background: '#fff5e6', border: '1px solid #ffd0a6', textColor: '#cc7a3e'},
  {name: 'Approved', value: 1, background: '#e6f1f8', border: '1px solid #c1d9e8', textColor: '#4a6d8c'},
  {name: 'Rejected', value: 2, background: '#f9e6e6', border: '1px solid #d1a1a1', textColor: '#7a3d3d'},
  {name: 'Paid', value: 3, background: '#e6f7e6', border: '1px solid #c1e6c1', textColor: '#4a7a4f'}
]