import React from 'react';
import "./index.scss";
import animateModule from "../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Footer() {
    const mainNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".route__contentWrapper"));
    };

    const location = useLocation();

    const allowedPaths = ["/", "/login", "/register"];

    const shouldShowFooter = allowedPaths.includes(location.pathname);

    return shouldShowFooter ? (
        <div className='component__footer'>
            <div className='component__footer__left'>
                <div className='component__footer__left__logo'>
                    <img src='images/logo.png' alt='' />
                    <h1>LeadSquar</h1>
                </div>
                <p>
                    LeadSquar is a CPA platform founded in Bosnia and Herzegovina (Balkans), after more than 4 years of successful business in affiliate marketing we became advertisers, not long after that we are bringing our CPA network to the light of day!
                </p>
            </div>

            <div className='component__footer__middle'>
                <h1>Important links</h1>
                <p onClick={() => animateNavigate('/register')}>Sign Up</p>
                <p onClick={() => animateNavigate('/login')}>Log In</p>
                <p onClick={() => animateNavigate('/privacypolicy')}>Privacy policy</p>
                <p onClick={() => animateNavigate('/terms')}>Terms</p>
            </div>

            <div className='component__footer__right'>
                <h1>Connect with us</h1>
                <div className='component__footer__right__social'>
                    <img src='images/linkedin.png' alt='' />
                    <img src='images/tweeter.png' alt='' />
                    <img src='images/instagram.png' alt='' />
                </div>
                <span>
                    <img src='images/email.png' alt='' />
                    <p>leadsquar@gmail.com</p>
                </span>
                <span>
                    <img src='images/footer__telegram.png' alt='' />
                    <p>@dzenan_leadsquar</p>
                </span>
            </div>
        </div>
    ) : null
}
