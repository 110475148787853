import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';
import { animateBox } from '../../modules/componentAnimation';
import { paymentStatuses } from "../../modules/scppModule";
import moment from 'moment';

export default function AdminPayments(props) {
    const [kpiSpinner, setKpiSpinner] = React.useState(true);
    const [spinner, setSpinner] = React.useState(true);
    const [paginationSpinner, setPaginationSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [kpiError, setKpiError] = React.useState(null);
    const [paginationMessage, setPaginationMessage] = React.useState(null);
    const [payments, setPayments] = React.useState([]);
    const [kpiData, setKpiData] = React.useState([]);
    const [detailsActive, setDetailsActive] = React.useState({ index: null, state: false });

    const getPayments = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAllPayments`,
            data: {
                limit: 20,
                offset: 0,
                orders: [["createdAt", "DESC"]]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setPayments(res.data.data);
            } else {
                setError('History error occurred');
            }
        }).catch(() => {
            setError('History error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const getPaymentsPagination = (limit, offset) => {
        setPaginationSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAllPayments`,
            data: {
                limit: limit,
                offset: offset,
                orders: [["createdAt", "DESC"]]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 0) {
                    setPaginationMessage('There are no more leads');
                }
                else {
                    setPayments(prev => [...prev, ...res.data.data]);
                }
            } else {
                setPaginationMessage('Unknown error occurred');
            }
        }).catch(() => {
            setPaginationMessage('Unknown error occurred');
        }).finally(() => {
            setPaginationSpinner(false);
        });
    }

    const getAmountByStatus = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAmountByStatus`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setKpiError(null);
                setKpiData(res.data.data);
            }
            else {
                setKpiError('Statistics error occurred');
            }
        }).catch(() => {
            setKpiError('Statistics error occured');
        }).finally(() => {
            setKpiSpinner(false);
        });
    }

    const returnCurrentStatus = (ID) => {
        return paymentStatuses.find(ps => ps.value === ID);
    }

    const handleDetailsBox = (idx) => {
        if (detailsActive.state) {
            setDetailsActive({ index: idx, state: false });
        } else {
            setDetailsActive({ index: idx, state: true });
        }
    }

    React.useEffect(() => {
        getPayments();
        getAmountByStatus();
    }, []);

    return (
        <div className='route__adminPayments'>
            <div className='route__adminPayments__header'>
                <h1>Payment requests</h1>
            </div>
            <div className='route__adminPayments__body'>
                <div className='route__adminPayments__body__table'>
                    <div className='route__adminPayments__body__table__headers'>
                        <p>ID</p>
                        <p>Affiliate ID</p>
                        <p>Date</p>
                        <p>Amount</p>
                        <p>Status</p>
                        <p>Method</p>
                        <p>Notation</p>
                    </div>
                    <div className='route__adminPayments__body__table__content'>
                        {!spinner && !error ? payments?.map((payment, index) => {
                            return <div>
                                <div onClick={() => handleDetailsBox(index)} style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__adminPayments__body__table__content__row'>
                                    <p>{payment.ID}</p>
                                    <p>{payment.AffiliateID}</p>
                                    <p>{moment(payment.createdAt).format('DD.MM.YYYY.')}</p>
                                    <p>${payment.Amount}</p>
                                    <p
                                        className='route__adminPayments__body__table__content__row__status'
                                        style={{ background: returnCurrentStatus(payment.Status).background, border: returnCurrentStatus(payment.Status).border, color: returnCurrentStatus(payment.Status).textColor }}
                                    >
                                        {returnCurrentStatus(payment.Status).name}
                                    </p>
                                    <p>{payment.Method}</p>
                                    <p>{payment.Notation}</p>
                                </div>
                                <div
                                    style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }}
                                    className={detailsActive.state && detailsActive.index === index ? 'route__adminPayments__body__table__content__row__details route__adminPayments__body__table__content__row__details__active'
                                        : 'route__adminPayments__body__table__content__row__details'}
                                >
                                    <p><b>Payment Details:</b> {payment.Details}</p>
                                    <div onClick={(e) => animateBox(e, <ChangeStatusModal refresh={() => {getPayments(); getAmountByStatus();}} data={payment} />)} className='route__adminPayments__body__table__content__row__details__button'>
                                        <p>Change status</p>
                                    </div>
                                </div>
                            </div>
                        }) : null}
                        {payments.length > 19 && <div className='route__adminPayments__body__table__content__pagination'>
                            {!paginationSpinner && !paginationMessage ? <div onClick={() => getPaymentsPagination(20, payments?.length)} className='route__adminPayments__body__table__content__pagination__btn'>
                                <p>Load more</p>
                            </div> : null}
                            {paginationMessage && <p style={{ color: 'maroon', marginLeft: 'auto', marginRight: 'auto', display: 'block', fontSize: '14px' }}>{paginationMessage}</p>}
                            {paginationSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                        </div>}
                        {spinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                        {error && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
                        {payments.length === 0 && <p style={{ padding: '10px' }}>No data yet</p>}
                    </div>
                </div>
                {!kpiSpinner && !kpiError ? <div className='route__adminPayments__body__kpis'>
                    <div className='route__adminPayments__body__kpis__pending'>
                        <label>Pending</label>
                        <span className='route__adminPayments__body__kpis__pending__bottom'>
                            <p>${kpiData?.Pending}</p>
                            <img src='images/dollars.png' alt='' />
                        </span>
                    </div>
                    <div className='route__adminPayments__body__kpis__approved'>
                        <label>Approved</label>
                        <span className='route__adminPayments__body__kpis__pending__bottom'>
                            <p>${kpiData?.Approved}</p>
                            <img src='images/dollars.png' alt='' />
                        </span>
                    </div>
                    <div className='route__adminPayments__body__kpis__rejected'>
                        <label>Rejected</label>
                        <span className='route__adminPayments__body__kpis__pending__bottom'>
                            <p>${kpiData?.Rejected}</p>
                            <img src='images/dollars.png' alt='' />
                        </span>
                    </div>
                    <div className='route__adminPayments__body__kpis__paid'>
                        <label>Paid</label>
                        <span className='route__adminPayments__body__kpis__pending__bottom'>
                            <p>${kpiData?.Paid}</p>
                            <img src='images/dollars.png' alt='' />
                        </span>
                    </div>
                </div> : null}
                {kpiSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                {kpiError ? <p style={{ color: 'maroon', fontSize: '15px' }}>{kpiError}</p> : null}
            </div>
        </div>
    )
}


const ChangeStatusModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);

    const changeStatus = (status) =>{
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/changePaymentStatus`,
            data: {
                AffiliateID: props.data?.AffiliateID,
                Status: status,
                PaymentID: props.data?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.onClose();
            }
            else {
                setError('Statistics error occurred');
            }
        }).catch(() => {
            setError('Statistics error occured');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='component__changeStatus'>
        <div className='component__changeStatus__wrapper'>
            <div className='component__changeStatus__wrapper__header'>
                <h1>Payment status</h1>
                <img onClick={props.onClose} src='images/closeModal.png' alt='' />
            </div>
            <div className='component__changeStatus__wrapper__body'>
                <label>Affiliate ID: {props.data?.AffiliateID}</label>
                <div className='component__changeStatus__wrapper__body__list'>
                    {paymentStatuses.map(status =>{
                        return <div
                        onClick={() => changeStatus(status.value)} 
                        className='component__changeStatus__wrapper__body__list__status'
                        style={{background: status.background, border: status.border, color: status.textColor}}
                        >
                            {!spinner && <p>{status.name}</p>}
                            {spinner && <Spinner style={{ width: '24px', height: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}