import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { offerStatuses, categories, payoutsModels, offersPrivacy } from "../../modules/scppModule";
import { useLocation } from 'react-router-dom';
import animateModule from "../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import Spinner from '../../components/Spinner';

export default function OfferPage(props) {
    const [offerData, setOfferData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [customPO, setCustomPO] = React.useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const mainNavigate = useNavigate();
    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".route__toAnimate"));
    };

    const returnCategory = (ID) => {
        return categories.find(cg => cg.value === Number(ID))?.name;
    }
    const returnPrivacy = (ID) => {
        return offersPrivacy.find(pv => pv.value === Number(ID))?.name;
    }
    const returnPayoutModel = (ID) => {
        return payoutsModels.find(pm => pm.value === Number(ID))?.name;
    }

    const getOfferData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getOfferByID`,
            data: {
                ID: queryParams.get('offerID')
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setOfferData(res.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getOfferData();
    }, []);

    React.useEffect(() => {
        if (String(offerData?.data?.CustomPayouts).length > 2 && !spinner) {
            try {
                if (JSON.parse(offerData?.data?.CustomPayouts)?.find(cp => cp.affiliateID === props.userData?.ID)) {
                    try {
                        setCustomPO(JSON.parse(offerData?.data?.CustomPayouts));
                    } catch { }
                }
            }catch { throw new Error('custom_po_error_occurred');}
        }
    }, [offerData]);

    return (
        <div className='route__offerPage'>
            <div className='route__offerPage__createStream'>
                {props.mode === 'affiliate' || props.mode === 'admin' ? <div onClick={() => animateNavigate(`/affiliate/offers/offerpage/promote-instructions?offerID=${queryParams.get('offerID')}&affiliateID=${props.userData?.ID}&advertiserID=${offerData?.data?.AdvertiserID}`)} className='route__offerPage__createStream__button'>
                    <img src='images/promote.png' alt='' />
                    <p>Promote this offer</p>
                </div> : null}
                <div onClick={() => animateNavigate(`/${props.mode}/offers`)} className='route__offerPage__createStream__button'>
                    <img src='images/back.png' alt='' />
                    <p>Back</p>
                </div>
            </div>
            <div className='route__offerPage__mainInfo'>
                <img src={`${backendModule.backendURL}/${offerData?.data?.ImagePath}`} alt='' />
                <div className='route__offerPage__mainInfo__middle'>
                    <h1>{offerData?.data?.Type} - {offerData?.data?.Name} - {offerData?.data?.Country}</h1>
                    <div className='route__offerPage__mainInfo__middle__ipc'>
                        <p className='route__offerPage__mainInfo__middle__ipc__id'>ID: {offerData?.data?.ID}</p>
                        <p className='route__offerPage__mainInfo__middle__ipc__privacy'>{returnPrivacy(offerData?.data?.Privacy)}</p>
                        <p className='route__offerPage__mainInfo__middle__ipc__category'>{returnCategory(offerData?.data?.Category)}</p>
                    </div>
                    <div className='route__offerPage__mainInfo__middle__geo'>
                        <p>Country:</p>
                        <div className='route__offerPage__mainInfo__middle__geo__flag'>
                            <span class={`fi fi-${String(offerData?.data?.Country).toLowerCase()}`}></span>
                            <p> - {offerData?.data?.Country}</p>
                        </div>
                    </div>
                </div>
                <div className='route__offerPage__mainInfo__right'>
                    <h1>Payment informations</h1>
                    {window.innerWidth > 557 && <div className='route__offerPage__mainInfo__right__table'>
                        <div className='route__offerPage__mainInfo__right__table__headers'>
                            <p>Price</p>
                            <p>Approve rate</p>
                            <p>Squers</p>
                            <p>Payout</p>
                            <p>Flow</p>
                            <p>Daily cap</p>
                        </div>
                        <div className='route__offerPage__mainInfo__right__table__content'>
                            <p>{offerData?.data?.Price} {offerData?.data?.Currency}</p>
                            <p>{offerData?.data?.ApproveRate}%</p>
                            <p>{offerData?.data?.Squers}</p>
                            <p>{customPO ? customPO.find(cp => cp.affiliateID === props.userData?.ID).payout : offerData?.data?.Payout ? offerData?.data?.Payout : offerData?.data?.Payout} USD</p>
                            <p>{returnPayoutModel(offerData?.data?.PayoutModel)}</p>
                            <p>{offerData?.data?.DailyCap ? offerData?.data?.DailyCap : 'No cap'}</p>
                        </div>
                    </div>}
                    {window.innerWidth < 558 && <div className='route__offerPage__mainInfo__right__table route__offerPage__mainInfo__right__table__mobile'>
                        <div className='route__offerPage__mainInfo__right__table__headers route__offerPage__mainInfo__right__table__mobile__headers'>
                            <p><b>Price:</b> <p>{offerData?.data?.Price} {offerData?.data?.Currency}</p></p>
                            <p><b>Approve rate:</b> <p>{offerData?.data?.ApproveRate}%</p></p>
                            <p><b>Squares:</b> <p>{offerData?.data?.Squers}</p></p>
                            <p><b>Payout:</b> <p>{customPO ? customPO.find(cp => cp.affiliateID === props.userData?.ID).payout : offerData?.data?.Payout ? offerData?.data?.Payout : offerData?.data?.Payout} USD</p></p>
                            <p><b>Flow:</b> <p>{returnPayoutModel(offerData?.data?.PayoutModel)}</p></p>
                            <p><b>Daily cap:</b> <p>{offerData?.data?.DailyCap ? offerData?.data?.DailyCap : 'No cap'}</p></p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className='route__offerPage__description'>
                <h1>Description</h1>
                <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: offerData?.data?.Description }}></p>
            </div>
            {spinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
            {error && <p style={{ fontSize: '15px', color: 'maroon' }}>{error}</p>}
        </div>
    )
}
