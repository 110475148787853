import React from 'react';
import "./index.scss";
import animateModule from "../../modules/animateModule";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampActions";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";

export default function Sidebar(props) {
  const [leadDropdown, setLeadDropdown] = React.useState(false);
  const [profileDropdown, setProfileDropdown] = React.useState(false);
  const [language, setLanguage] = React.useState('EN');
  const [mobSidebar, setMobSidebar] = React.useState(false);

  const mainLocation = useLocation();
  const mainNavigate = useNavigate();
  const curDispatch = useDispatch();

  const animateNavigate = to => {
    if (mainLocation.pathname === to) return;
    animateModule(mainNavigate, to, document.querySelector(".route__toAnimate"));
  };

  const logout = () => {
    axios({
      method: "GET",
      url: `${backendModule.backendURL}/auth/logout`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      curDispatch(timestampActions.updateTimestamp());
      animateNavigate('/');
    }).catch(() => {
    }).finally(() => {
    });
  }

  if (props.userData?.Flags?.isAdvertiser) {
    return (
      <div>
        {window.innerWidth < 1041 && <div className='component__sidebar__mobile'>
          <div className='component__sidebar__mobile__logo'>
            <img src='images/logo.png' alt='' />
            <p>LeadSquar</p>
          </div>
          <img onClick={() => setMobSidebar(s => !s)} src='images/apphamburger.png' alt='' />
        </div>}

        <div className={!mobSidebar ? 'component__sidebar' : 'component__sidebar component__sidebar__active'}>
          <div className='component__sidebar__logo'>
            <img src='images/logo.png' alt='' />
            <p>LeadSquar</p>
            {window.innerWidth < 1041 && <img onClick={() => setMobSidebar(false)} className='component__sidebar__logo__closeSidebar' src='images/sidebar__close.png' alt='' />}
          </div>
          <div className='component__sidebar__routes'>
            <div onClick={() => { animateNavigate('advertiser/offers'); setLeadDropdown(false) }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/advertiser/offers") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__offers.svg' alt='' />
              <p>Offers</p>
            </div>
            <div onClick={() => { setLeadDropdown(ld => !ld) }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/advertiser/leads") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__leads.png' alt='' />
              <p>Leads</p>
              <div className={leadDropdown ? 'component__sidebar__routes__item__dropdown component__sidebar__routes__item__dropdown__active' : 'component__sidebar__routes__item__dropdown'}>
                <p onClick={() => { animateNavigate('advertiser/leads'); }}>Leads</p>
                <p onClick={() => { animateNavigate('advertiser/leads-status-report'); }}>Leads status report</p>
                <p onClick={() => { animateNavigate('advertiser/blacklist'); }}>Blacklist</p>
              </div>
            </div>
            <div onClick={() => { animateNavigate('advertiser/statistics'); setLeadDropdown(false) }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/advertiser/statistics") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__statistics.png' alt='' />
              <p>Statistics</p>
            </div>
            <div onClick={() => { animateNavigate('advertiser/news'); setLeadDropdown(false) }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/advertiser/news") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__news.png' alt='' />
              <p>News</p>
            </div>
            <div onClick={() => { animateNavigate('/help/api-and-postback-documentation'); setLeadDropdown(false) }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/help/api-and-postback-documentation") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__api.png' alt='' />
              <p>API & Postback</p>
            </div>
            <div onClick={() => { animateNavigate('advertiser/balance') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/advertiser/balance") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__balance.png' alt='' />
              <p>Balance</p>
            </div>
          </div>
          <div className='component__sidebar__manager'>
            <h3>Your affiliate manager</h3>
            <span>
              <img src='images/sidebar__telegram.png' alt='' />
              <p>@dzenan_leadsquar</p>
            </span>
          </div>
          <div className='component__sidebar__balance'>
            <div className='component__sidebar__balance__left'>
              <p>Balance</p>
              <strong>${props.userData?.Balance}</strong>
            </div>
            <img src='images/sidebar__balance.png' alt='' />
          </div>
          <div onClick={() => setProfileDropdown(pd => !pd)} className='component__sidebar__profile'>
            <div className='component__sidebar__profile__left'>
              <img src='images/profileImage.webp' alt='' />
              <p>{props.userData?.FirstName}</p>
            </div>
            <div className='component__sidebar__profile__right'>
              <img src='images/sidebar__profileArrow.png' alt='' />
            </div>
            <div className={profileDropdown ? 'component__sidebar__profile__dropdown component__sidebar__profile__dropdown__active' : 'component__sidebar__profile__dropdown'}>
              <p onClick={() => { animateNavigate('advertiser/profile') }}>Profile</p>
              <p onClick={() => logout()}>Logout</p>
            </div>
          </div>
          <div className='component__sidebar__language'>
            <p>Change language</p>
            <div className='component__sidebar__language__list'>
              <img onClick={() => setLanguage('EN')} src='images/sidebar__uk.png' alt='' style={{ borderBottom: language === 'EN' ? '1px solid #CAFF33' : '1px solid transparent' }} />
              <img onClick={() => setLanguage('DE')} src='images/sidebar__germany.png' alt='' style={{ borderBottom: language === 'DE' ? '1px solid #CAFF33' : '1px solid transparent' }} />
              <img onClick={() => setLanguage('RU')} src='images/sidebar__russia.png' alt='' style={{ borderBottom: language === 'RU' ? '1px solid #CAFF33' : '1px solid transparent' }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (props.userData?.Flags?.isAffiliate) {
    return (
      <div className='sidebar__wrapper'>
        {window.innerWidth < 1041 && <div className='component__sidebar__mobile'>
          <div className='component__sidebar__mobile__logo'>
            <img src='images/logo.png' alt='' />
            <p>LeadSquar</p>
          </div>
          <img onClick={() => setMobSidebar(s => !s)} src='images/apphamburger.png' alt='' />
        </div>}

        <div className={!mobSidebar ? 'component__sidebar' : 'component__sidebar component__sidebar__active'}>
          <div className='component__sidebar__logo'>
            <img src='images/logo.png' alt='' />
            <p>LeadSquar</p>
            {window.innerWidth < 1041 && <img onClick={() => setMobSidebar(false)} className='component__sidebar__logo__closeSidebar' src='images/sidebar__close.png' alt='' />}
          </div>
          <div className='component__sidebar__routes'>
            <div onClick={() => { animateNavigate('affiliate/dashboard') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/dashboard") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__dashboard.png' alt='' />
              <p>Dashboard</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/offers') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/offers") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__offers.svg' alt='' />
              <p>Offers</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/leads') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/leads") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__leads.png' alt='' />
              <p>Leads</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/statistics') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/statistics") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__statistics.png' alt='' />
              <p>Statistics</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/tracking') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/tracking") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__tracking.png' alt='' />
              <p>Tracking</p>
            </div>
            <div onClick={() => { animateNavigate('/help/api-and-postback-documentation') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/help/api-and-postback-documentation") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__api.png' alt='' />
              <p>API & Postback</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/payments') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/payments") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__payments.png' alt='' />
              <p>Payments</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/balance') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/balance") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__balance.png' alt='' />
              <p>Balance</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/shop') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/squershop") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__shop.png' alt='' />
              <p>SquareShop</p>
            </div>
            <div onClick={() => { animateNavigate('affiliate/news') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/affiliate/news") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__news.png' alt='' />
              <p>News</p>
            </div>
          </div>
          <div className='component__sidebar__manager'>
            <h3>Your affiliate manager</h3>
            <span>
              <img src='images/sidebar__telegram.png' alt='' />
              <p>@dzenan_leadsquar</p>
            </span>
          </div>
          <div className='component__sidebar__balance'>
            <div className='component__sidebar__balance__left'>
              <p>Balance</p>
              <strong>${props.userData?.Balance}</strong>
            </div>
            <img src='images/sidebar__balance.png' alt='' />
          </div>
          <div className='component__sidebar__balance'>
            <div className='component__sidebar__balance__left'>
              <p>Squares</p>
              <strong>{props.userData?.Squers}</strong>
            </div>
            <img src='images/sidebar__squers.png' alt='' />
          </div>
          <div onClick={() => setProfileDropdown(pd => !pd)} className='component__sidebar__profile'>
            <div className='component__sidebar__profile__left'>
              <img src='images/profileImage.webp' alt='' />
              <p>{props.userData?.FirstName}</p>
            </div>
            <div className='component__sidebar__profile__right'>
              <img src='images/sidebar__profileArrow.png' alt='' />
            </div>
            <div className={profileDropdown ? 'component__sidebar__profile__dropdown component__sidebar__profile__dropdown__active' : 'component__sidebar__profile__dropdown'}>
              <p onClick={() => { animateNavigate('affiliate/profile') }}>Profile</p>
              <p onClick={() => logout()}>Logout</p>
            </div>
          </div>
          <div className='component__sidebar__language'>
            <p>Change language</p>
            <div className='component__sidebar__language__list'>
              <img onClick={() => setLanguage('EN')} src='images/sidebar__uk.png' alt='' style={{ borderBottom: language === 'EN' ? '1px solid #CAFF33' : '1px solid transparent' }} />
              <img onClick={() => setLanguage('DE')} src='images/sidebar__germany.png' alt='' style={{ borderBottom: language === 'DE' ? '1px solid #CAFF33' : '1px solid transparent' }} />
              <img onClick={() => setLanguage('RU')} src='images/sidebar__russia.png' alt='' style={{ borderBottom: language === 'RU' ? '1px solid #CAFF33' : '1px solid transparent' }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (props.userData?.Flags?.isAdmin) {
    return (
      <div>
        {window.innerWidth < 1041 && <div className='component__sidebar__mobile'>
          <div className='component__sidebar__mobile__logo'>
            <img src='images/logo.png' alt='' />
            <p>LeadSquar</p>
          </div>
          <img onClick={() => setMobSidebar(s => !s)} src='images/apphamburger.png' alt='' />
        </div>}

        <div className={!mobSidebar ? 'component__sidebar' : 'component__sidebar component__sidebar__active'}>
          <div className='component__sidebar__logo'>
            <img src='images/logo.png' alt='' />
            <p>LeadSquar</p>
            {window.innerWidth < 1041 && <img onClick={() => setMobSidebar(false)} className='component__sidebar__logo__closeSidebar' src='images/sidebar__close.png' alt='' />}
          </div>
          <div className='component__sidebar__routes component__sidebar__routes__admin'>
            <div onClick={() => { animateNavigate('admin/dashboard') }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/dashboard") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__dashboard.png' alt='' />
              <p>Dashboard</p>
            </div>
            <div onClick={() => { animateNavigate('admin/offers'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/offers") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__offers.svg' alt='' />
              <p>Offers</p>
            </div>
            <div onClick={() => { animateNavigate('admin/statistics'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/statistics") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__statistics.png' alt='' />
              <p>Statistics</p>
            </div>
            <div onClick={() => { animateNavigate('admin/advertisers'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/advertisers") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__advertisers.png' alt='' />
              <p>Advertisers</p>
            </div>
            <div onClick={() => { animateNavigate('admin/affiliates'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/affiliates") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__affiliates.png' alt='' />
              <p>Affiliates</p>
            </div>
            <div onClick={() => { animateNavigate('admin/leads'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/leads") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__leads.png' alt='' />
              <p>Leads</p>
            </div>
            <div onClick={() => { animateNavigate('admin/managers'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/managers") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__managers.png' alt='' />
              <p>Managers</p>
            </div>
            <div onClick={() => { animateNavigate('admin/payments'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/payments") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__balance.png' alt='' />
              <p>Payments</p>
            </div>
            <div onClick={() => { animateNavigate('admin/news'); }} className='component__sidebar__routes__item' style={{ borderLeft: mainLocation.pathname.startsWith("/admin/news") ? '5px solid #CAFF33' : '' }}>
              <img src='images/sidebar__news.png' alt='' />
              <p>News</p>
            </div>
          </div>
          <div onClick={() => setProfileDropdown(pd => !pd)} className='component__sidebar__profile'>
            <div className='component__sidebar__profile__left'>
              <img src='images/profileImage.webp' alt='' />
              <p>{props.userData?.FirstName}</p>
            </div>
            <div className='component__sidebar__profile__right'>
              <img src='images/sidebar__profileArrow.png' alt='' />
            </div>
            <div className={profileDropdown ? 'component__sidebar__profile__dropdown component__sidebar__profile__dropdown__active' : 'component__sidebar__profile__dropdown'}>
              <p onClick={() => logout()}>Logout</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
