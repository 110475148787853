import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment';
import Spinner from '../../components/Spinner';
import { useLocation, useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

export default function AdminNews(props) {
    const [spinner, setSpinner] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [news, setNews] = React.useState([]);

    const mainNavigate = useNavigate();

    const animateNavigate = to => {
      animateModule(mainNavigate, to, document.querySelector(".route__toAnimate"));
    };

    const getAllNews = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/news/getAllNews`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setNews(res.data.data);
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getAllNews();
    }, []);

    return (
        <div className='route__news'>
            <div className='route__news__header'>
                <h1>LeadSquar News</h1>
                <div onClick={(e) => animateBox({currentTarget: e.currentTarget.parentNode}, <NewPostModal refresh={getAllNews} />)} className='route__news__header__button'>
                    <p>New post</p>
                </div>
            </div>
            <div className='route__news__body'> 
                {!spinner && news.map(item =>{
                    return <div onClick={() => animateNavigate(`/admin/news/read?articleID=${item.ID}`)} className='route__news__body__post'>
                        <i>{moment(item.createdAt).format('DD.MM.YYYY.')}</i>
                        {item.IsUrgent && <span>URGENT</span>}
                        <h1>{item.Heading}</h1>
                        <small>{item.Category}</small>
                        <p>{item.Description}</p>
                    </div>
                })}
                {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                {error && <p style={{color: 'maroon', fontSize: '15px'}}>{error}</p>}
                {news.length === 0 && <p style={{color: 'black', fontSize: '15px'}}>No news yet</p>}
            </div>
        </div>
    )
}


const NewPostModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);

    const headingRef = React.useRef(null);
    const categoryRef = React.useRef(null);
    const descriptionRef = React.useRef(null);
    const isUrgentRef = React.useRef(null);
    const textRef = React.useRef(null);

    const addArticle = () => {
        if(
            !headingRef.current.value ||
            !categoryRef.current.value ||
            !descriptionRef.current.value ||
            !textRef.current.value
        ){
            return setError('Info missing');
        }else{
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/news/addArticle`,
            data: {
                Heading: headingRef.current.value,
                Category: categoryRef.current.value,
                Description: descriptionRef.current.value,
                Text: textRef.current.value,
                IsUrgent: isUrgentRef.current.checked ? true : false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.refresh();
                props.onClose();
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setSpinner(false);
        });
    }


    return <div className='component__newPost'>
        <div className='component__newPost__wrapper'>
            <div onClick={props.onClose} className='component__newPost__wrapper__header'>
                <h1>Create new post</h1>
                <img src='images/closeModal.png' alt='' />
            </div>
            <div className='component__newPost__wrapper__body'>
                <div className='component__newPost__wrapper__body__field'>
                    <label>Heading</label>
                    <input ref={headingRef} type='text' />
                </div>
                <div className='component__newPost__wrapper__body__field'>
                    <label>Category</label>
                    <input ref={categoryRef} type='text' />
                </div>
                <div className='component__newPost__wrapper__body__field'>
                    <label>Description</label>
                    <input ref={descriptionRef} type='text' />
                </div>
                <div className='component__newPost__wrapper__body__field'>
                    <label>Is urgent?</label>
                    <input ref={isUrgentRef} type='checkbox' />
                </div>
                <div className='component__newPost__wrapper__body__field'>
                    <label>Text</label>
                    <textarea ref={textRef} />
                </div>
            </div>
            <div className='component__newPost__wrapper__bottom'>
                {error && <p style={{color: 'maroon', fontSize: '15px'}}>{error}</p>}
                <div onClick={() => addArticle()} className='component__newPost__wrapper__bottom__button'>
                    {!spinner && <p>Add post</p>}
                    {spinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
                </div>
            </div>
        </div>
    </div>
}