import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';
import SimpleDropdown from '../../components/SimpleDropdown';
import { paymentStatuses } from "../../modules/scppModule";
import moment from 'moment';

export default function AffiliatePayments(props) {
    const [kpiSpinner, setKpiSpinner] = React.useState(true);
    const [reqSpinner, setReqSpinner] = React.useState(false);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [tableSpinner, setTableSpinner] = React.useState(true);
    const [paginationSpinner, setPaginationSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [reqError, setReqError] = React.useState(null);
    const [tableError, setTableError] = React.useState(null);
    const [kpiError, setKpiError] = React.useState(null);
    const [settingsActive, setSettingsActive] = React.useState(false);
    const [paypalData, setPaypalData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [kpiData, setKpiData] = React.useState([]);
    const [message, setMessage] = React.useState(null);
    const [reqMessage, setReqMessage] = React.useState(null);
    const [paginationMessage, setPaginationMessage] = React.useState(null);
    const [selectedMethod, setSelectedMethod] = React.useState(null);

    const trc20ref = React.useRef(null);
    const erc20ref = React.useRef(null);
    const payoneerRef = React.useRef(null);
    const paypalNameRef = React.useRef(null);
    const paypalSurnameRef = React.useRef(null);
    const paypalEmailRef = React.useRef(null);
    const paypalAddressRef = React.useRef(null);
    const paypalPhoneNumberRef = React.useRef(null);

    const amountRef = React.useRef(null);
    const notationRef = React.useRef(null);

    const updateAffiliate = () => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/updateAffiliate`,
            data: {
                ID: props.userData?.ID,
                USDTtrc20: trc20ref.current.value,
                USDTerc20: erc20ref.current.value,
                Payoneer: payoneerRef.current.value,
                Paypal: {
                    Name: paypalNameRef.current.value,
                    Surname: paypalSurnameRef.current.value,
                    Email: paypalEmailRef.current.value,
                    Address: paypalAddressRef.current.value,
                    PhoneNumber: paypalPhoneNumberRef.current.value
                }
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setMessage('Success!');
                setError(null);
                props.checkLogin();
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    const getPayments = () => {
        setTableSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAllPayments`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    { name: 'AffiliateID', op: 'eq', value: props.userData?.ID }
                ],
                orders: [["createdAt", "DESC"]]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setTableData(res.data.data);
            } else {
                setTableError('History error occurred');
            }
        }).catch(() => {
            setTableError('History error occurred');
        }).finally(() => {
            setTableSpinner(false);
        });
    }

    const getPaymentsPagination = (limit, offset) => {
        setPaginationSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAllPayments`,
            data: {
                limit: limit,
                offset: offset,
                filters: [
                    { name: 'AffiliateID', op: 'eq', value: props.userData?.ID }
                ],
                orders: [["createdAt", "DESC"]]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 0) {
                    setPaginationMessage('There are no more leads');
                }
                else {
                    setTableData(prev => [...prev, ...res.data.data]);
                }
            } else {
                setPaginationMessage('Unknown error occurred');
            }
        }).catch(() => {
            setPaginationMessage('Unknown error occurred');
        }).finally(() => {
            setPaginationSpinner(false);
        });
    }

    const requestPayment = () => {
        if (!amountRef.current.value || !selectedMethod) {
            return setReqError('Info missing');
        } else {
            const numericValue = parseFloat(amountRef.current.value);
            if (!Number.isInteger(numericValue) && !amountRef.current.value.endsWith(".00")) {
                return setReqError('Please make the amount end with .00');
            } else {
                if (selectedMethod && selectedMethod.value === 0) {
                    return setReqError('Payment method unavailable');
                } else {
                    if(amountRef.current.value < 100){
                        return setReqError('Minimum amount is $100');
                    }else{
                        setReqSpinner(true);
                    }
                }
            }
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/addPayment`,
            data: {
                AffiliateID: props.userData?.ID,
                Notation: notationRef.current.value,
                Method: selectedMethod.name,
                Details: selectedMethod.value,
                Amount: amountRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setReqMessage('Success!');
                setReqError(null);
                props.checkLogin();
                getPayments();
                getAmountByStatus();
            }
            else {
                setReqError(res.data.data);
                setReqMessage(null);
            }
        }).catch(() => {
            setReqError('Unknown error occured');
            setReqMessage(null);
        }).finally(() => {
            setReqSpinner(false);
        });
    }

    const getAmountByStatus = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payments/getAmountByStatus`,
            data: {
                filters: [
                    { name: 'AffiliateID', op: 'eq', value: props.userData?.ID }
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setKpiError(null);
                setKpiData(res.data.data);
            }
            else {
                setKpiError('Statistics error occurred');
            }
        }).catch(() => {
            setKpiError('Statistics error occured');
        }).finally(() => {
            setKpiSpinner(false);
        });
    }

    const returnCurrentStatus = (ID) => {
        return paymentStatuses.find(ps => ps.value === ID);
    }

    React.useEffect(() => {
        setPaypalData(JSON.parse(props.userData?.Paypal));
    }, [props.userData]);

    React.useEffect(() => {
        getPayments();
        getAmountByStatus();
    }, []);

    return (
        <div className='route__payments'>
            <div className='route__payments__settings'>
                <div onClick={() => setSettingsActive(se => !se)} className='route__payments__settings__header'>
                    <h1>Payment settings</h1>
                    <img className={settingsActive ? 'route__payments__settings__header__img route__payments__settings__header__img__active' : 'route__payments__settings__header__img'} src='images/arrowDown.png' alt='' />
                </div>
                <div className={settingsActive ? `route__payments__settings__wrapper route__payments__settings__wrapper__active` : 'route__payments__settings__wrapper'} >
                    <div className='route__payments__settings__wrapper__note'>
                        <p>USDT trc20, USDT erc20, payoneer, paypal are available. And if you want payment to a bank account, please contact your manager</p>
                        {message && <p style={{ color: 'white', fontSize: '15px', background: 'green', padding: '5px', width: '150px', height: '30px', textAlign: 'center' }}>{message}</p>}
                        {error && <p style={{ color: 'white', fontSize: '15px', background: 'maroon', padding: '5px', width: 'fit-content', height: '30px', textAlign: 'center' }}>{error}</p>}
                        <div onClick={() => updateAffiliate()} className='route__payments__settings__wrapper__note__button'>
                            {!btnSpinner && <p>Save changes</p>}
                            {btnSpinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
                        </div>
                    </div>
                    <div className='route__payments__settings__wrapper__body'>
                        <div className='route__payments__settings__wrapper__body__field'>
                            <label>USDT trc20</label>
                            <div className='route__payments__settings__wrapper__body__field__inputs'>
                                <input defaultValue={props.userData?.USDTtrc20} ref={trc20ref} type='text' placeholder='Wallet address' />
                            </div>
                        </div>
                        <div className='route__payments__settings__wrapper__body__field'>
                            <label>USDT erc20</label>
                            <div className='route__payments__settings__wrapper__body__field__inputs'>
                                <input defaultValue={props.userData?.USDTerc20} ref={erc20ref} type='text' placeholder='Wallet address' />
                            </div>
                        </div>
                        <div className='route__payments__settings__wrapper__body__field'>
                            <label>Payoneer</label>
                            <div className='route__payments__settings__wrapper__body__field__inputs'>
                                <input defaultValue={props.userData?.Payoneer} ref={payoneerRef} type='text' placeholder='E-mail' />
                            </div>
                        </div>
                        <div className='route__payments__settings__wrapper__body__field'>
                            <label>Wire transfer</label>
                            <div className='route__payments__settings__wrapper__body__field__inputs'>
                                <p>Please contact your's affiliate manager</p>
                            </div>
                        </div>
                        <div className='route__payments__settings__wrapper__body__field'>
                            <label>Paypal</label>
                            <div className='route__payments__settings__wrapper__body__field__inputs'>
                                <input defaultValue={paypalData?.Name} ref={paypalNameRef} type='text' placeholder='Your name' />
                                <input defaultValue={paypalData?.Surname} ref={paypalSurnameRef} type='text' placeholder='Your surname' />
                                <input defaultValue={paypalData?.Email} ref={paypalEmailRef} type='text' placeholder='Your e-mail' />
                                <input defaultValue={paypalData?.Address} ref={paypalAddressRef} type='text' placeholder='Your address' />
                                <input defaultValue={paypalData?.PhoneNumber} ref={paypalPhoneNumberRef} type='text' placeholder='Your phone number' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='route__payments__main'>
                <div className='route__payments__main__header'>
                    <h1>Payment requests</h1>
                    {reqError && <p style={{ color: 'maroon', fontSize: '15px' }}>{reqError}</p>}
                    {reqMessage && <p style={{ color: 'green', fontSize: '15px' }}>{reqMessage}</p>}
                </div>
                <div className='route__payments__main__requestPayment'>
                    <div className='route__payments__main__requestPayment__amount'>
                        <p>USD</p>
                        <input ref={amountRef} type='number' placeholder='Sum' defaultValue={props.userData?.Balance} />
                        <p>.00</p>
                    </div>
                    <SimpleDropdown heading='Select method' data={[
                        props.userData?.Payoneer ? { name: 'Payoneer', value: props.userData?.Payoneer } : { name: 'Unavailable - Payoneer ', value: 0 },
                        props.userData?.USDTtrc20 ? { name: 'USDT (trc20)', value: props.userData?.USDTtrc20 } : { name: 'Unavailable - USDT (trc20) ', value: 0 },
                        props.userData?.USDTerc20 ? { name: 'USDT (erc20)', value: props.userData?.USDTerc20 } : { name: 'Unavailable - USDT (erc20) ', value: 0 },
                        paypalData?.Email ? { name: 'Paypal', value: paypalData?.Email } : { name: 'Unavailable - Paypal ', value: 0 },
                        { name: 'Wire transfer', value: 'Wire transfer - contact him' }
                    ]} setSelected={setSelectedMethod} />
                    <input ref={notationRef} type='text' placeholder='Notation' />
                    <div onClick={() => requestPayment()} className='route__payments__main__requestPayment__button'>
                        {!reqSpinner && <p>Request</p>}
                        {reqSpinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
                    </div>
                </div>
                <div className='route__payments__main__body'>
                    <div className='route__payments__main__body__table'>
                        <div className='route__payments__main__body__table__headers'>
                            <p>ID</p>
                            <p>Date</p>
                            <p>Amount</p>
                            <p>Status</p>
                            <p>Method</p>
                            <p>Notation</p>
                        </div>
                        <div className='route__payments__main__body__table__content'>
                            {!tableSpinner && !tableError ? tableData?.map((payment, index) => {
                                return <div style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__payments__main__body__table__content__row'>
                                    <p>{payment.ID}</p>
                                    <p>{moment(payment.createdAt).format('DD.MM.YYYY.')}</p>
                                    <p>${payment.Amount}</p>
                                    <p
                                        className='route__payments__main__body__table__content__row__status'
                                        style={{ background: returnCurrentStatus(payment.Status).background, border: returnCurrentStatus(payment.Status).border, color: returnCurrentStatus(payment.Status).textColor }}
                                    >
                                        {returnCurrentStatus(payment.Status).name}
                                    </p>
                                    <p>{payment.Method}</p>
                                    <p>{payment.Notation}</p>
                                </div>
                            }) : null}
                            {tableData.length > 19 && <div className='route__payments__main__body__table__content__pagination'>
                                {!paginationSpinner && !paginationMessage ? <div onClick={() => getPaymentsPagination(20, tableData?.length)} className='route__payments__main__body__table__content__pagination__btn'>
                                    <p>Load more</p>
                                </div> : null}
                                {paginationMessage && <p style={{ color: 'maroon', marginLeft: 'auto', marginRight: 'auto', display: 'block', fontSize: '14px' }}>{paginationMessage}</p>}
                                {paginationSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                            </div>}
                            {tableSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                            {tableError && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{tableError}</p>}
                            {tableData.length === 0 && <p style={{ padding: '10px' }}>No data yet</p>}
                        </div>
                    </div>
                    {!kpiSpinner && !kpiError ? <div className='route__payments__main__body__kpis'>
                        <div className='route__payments__main__body__kpis__pending'>
                            <label>Pending</label>
                            <span className='route__payments__main__body__kpis__pending__bottom'>
                                <p>${kpiData?.Pending}</p>
                                <img src='images/dollars.png' alt='' />
                            </span>
                        </div>
                        <div className='route__payments__main__body__kpis__approved'>
                            <label>Approved</label>
                            <span className='route__payments__main__body__kpis__pending__bottom'>
                                <p>${kpiData?.Approved}</p>
                                <img src='images/dollars.png' alt='' />
                            </span>
                        </div>
                        <div className='route__payments__main__body__kpis__rejected'>
                            <label>Rejected</label>
                            <span className='route__payments__main__body__kpis__pending__bottom'>
                                <p>${kpiData?.Rejected}</p>
                                <img src='images/dollars.png' alt='' />
                            </span>
                        </div>
                        <div className='route__payments__main__body__kpis__paid'>
                            <label>Paid</label>
                            <span className='route__payments__main__body__kpis__pending__bottom'>
                                <p>${kpiData?.Paid}</p>
                                <img src='images/dollars.png' alt='' />
                            </span>
                        </div>
                    </div> : null}
                    {kpiSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    {kpiError ? <p style={{color: 'maroon', fontSize: '15px'}}>{kpiError}</p> : null}
                </div>
            </div>
        </div>
    )
}
