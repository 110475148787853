import React from 'react';
import "./index.scss";
import animateModule from "../../modules/animateModule";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Header(props) {
  const [mobile, setMobile] = React.useState(false);

  const scrollToSection = (section) => {
    const target = document.getElementById(section);

    if (!target) {
      console.error("Element with ID 'target-section' not found.");
      return;
    }

    const targetPosition = target.getBoundingClientRect().top + window.scrollY;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const duration = 1000;
    let start = null;

    function smoothScroll(currentTime) {
      if (!start) start = currentTime;
      const timeElapsed = currentTime - start;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(smoothScroll);
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(smoothScroll);
  };

  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__contentWrapper"));
  };

  const location = useLocation();

  const allowedPaths = ["/", "/login", "/register"];

  const shouldShowHeader = allowedPaths.includes(location.pathname);

  return shouldShowHeader ? (
    <div className='component__header'>
      <div className='component__header__container'>
        <div onClick={() => {animateNavigate('/')}} className='component__header__container__left'>
          <img src='images/logo.png' alt='' />
          <p>LeadSquar</p>
        </div>
        <div className='component__header__container__middle'>
          <p onClick={() => animateNavigate('/')}>Home</p>
          <p onClick={() => scrollToSection('affiliates')}>Affiliates</p>
          <p onClick={() => scrollToSection('advertisers')}>Advertisers</p>
          <p onClick={() => scrollToSection('advantages')}>Advantages</p>
        </div>
        <div className='component__header__container__right'>
          <p onClick={() => animateNavigate('/register')}>Sign Up</p>
          <p className='component__header__container__right__login' onClick={() => animateNavigate('/login')}>Login</p>
          <img onClick={() => setMobile(m => !m)} className='component__header__container__right__hamburger' src='images/hamburger.svg' alt='' />
        </div>


        <div className={mobile ? 'component__header__container__mobile component__header__container__mobile__active' : 'component__header__container__mobile'}>
          <div className='component__header__container__mobile__top'>
            <div onClick={() => {animateNavigate('/'); setMobile(m => !m)}} className='component__header__container__mobile__top__logo'>
              <img src='images/logo.png' alt='' />
              <p>LeadSquar</p>
            </div>
            <div onClick={() => setMobile(m => !m)} className='component__header__container__mobile__top__x'>
              <img src='images/closemenu.svg' alt='' />
            </div>
          </div>
          <p onClick={() => {animateNavigate('/'); setMobile(m => !m)}}>Home</p>
          <p onClick={() => {scrollToSection('affiliates'); setMobile(m => !m)}}>Affiliates</p>
          <p onClick={() => {scrollToSection('advertisers'); setMobile(m => !m)}}>Advertisers</p>
          <p onClick={() => {scrollToSection('advantages'); setMobile(m => !m)}}>Advantages</p>
          <p onClick={() => {animateNavigate('/register'); setMobile(m => !m)}}>Sign Up</p>
          <p className='component__header__container__mobile__login' onClick={() => {animateNavigate('/login'); setMobile(m => !m)}}>Login</p>
        </div>
      </div>
    </div>
  ) : null
}
