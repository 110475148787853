import React from 'react';
import "./index.scss";
import animateModule from "../../../modules/animateModule";
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
  const scrollToSection = (section) => {
    const target = document.getElementById(section);

    if (!target) {
      console.error("Element with ID 'target-section' not found.");
      return;
    }

    const targetPosition = target.getBoundingClientRect().top + window.scrollY;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const duration = 1000;
    let start = null;

    function smoothScroll(currentTime) {
      if (!start) start = currentTime;
      const timeElapsed = currentTime - start;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(smoothScroll);
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(smoothScroll);
  };

  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".route__contentWrapper"));
  };

  return (
    <div className='route__landingPage'>
      <div id='home' className='route__landingPage__hero'>
        <div className='route__landingPage__hero__left'>
          <h1>Personalized <span style={{ color: '#CAFF33' }}>CPA Network</span> Experience</h1>
          <p>
            The key to success for affiliate marketers lies in executing a strategy that leverages your strengths.
            <br /><br />
            Enhance your affiliate marketing approach with guaranteed top conversion rates and CPA, supported by customizable payouts and high-speed lead processing.
          </p>
          <div onClick={() => scrollToSection('bonuses')} className='route__landingPage__hero__left__button'>
            <p>Join The Revolution</p>
          </div>
        </div>
        <div className='route__landingPage__hero__right'>
          <img src='images/herostrelice.svg' alt='' />
        </div>
      </div>

      <div id='advantages' className='route__landingPage__somethingNew'>
        <h1>We want to bring something new!</h1>
        <p>
          Affiliate marketers have always had approval rate nightmares, we decided to put an end to that!
        </p>
        <div className='route__landingPage__somethingNew__grid'>
          <div className='route__landingPage__somethingNew__grid__left'>
            <div className='route__landingPage__somethingNew__grid__left__AR'>
              <h3>33%</h3>
              <p>AR Guarantee</p>
            </div>
            <div className='route__landingPage__somethingNew__grid__left__explain'>
              <h3>How is that possible?</h3>
              <p>
                - We have a 33% guaranteed approval rate on our best nutra offers. This is possible thanks to advertisers who have created exclusive offers for our network!
              </p>
              <p>
                - Such offers are priority number 1 for processing on the advertiser's side. To qualify for the AR guarantee, a minimum of 10 leads per day are required.
              </p>
            </div>
          </div>
          <div className='route__landingPage__somethingNew__grid__right'>
            <img src='images/vision.svg' alt='' />
          </div>
        </div>
      </div>

      <div id='bonuses' className='route__landingPage__bonuses'>
        <div className='route__landingPage__bonuses__left'>
          <img src='images/Coins-amico.svg' alt='' />
        </div>
        <div className='route__landingPage__bonuses__right'>
          <h1>We have prepared a special welcome bonus!</h1>
          <p>
            An additional bonus will be paid to you on your first payout!
            <br /> <br />
            Hurry up because this offer is only valid until the end of 2024!
            <br /> <br />
            Also for every advertiser or affiliate you bring you will get $20.
          </p>
          <div onClick={() => animateNavigate('/register')} className='route__landingPage__bonuses__right__button'>
            <p>Register now</p>
          </div>
        </div>
      </div>

      <div id='advertisers' className='route__landingPage__advertisers'>
        <div className='route__landingPage__advertisers__left'>
          <h1>LeadSquar Advertiser</h1>
          <p>Equipped with numerous features, user-friendly, and crafted to enhance your earnings.</p>
          <div onClick={() => animateNavigate('/register')} className='route__landingPage__advertisers__left__button'>
            <p>Get Started</p>
          </div>
        </div>
        <div className='route__landingPage__advertisers__right'>
          <div className='route__landingPage__advertisers__right__grid'>
            <div className='route__landingPage__advertisers__right__grid__item'>
              <img src='images/roi.svg' alt='' />
              <strong>High ROI & Quality Experience</strong>
              <p>We strive to deliver high ROI and a top-quality consumer experience is our top priority.</p>
            </div>
            <div className='route__landingPage__advertisers__right__grid__item'>
              <img src='images/verticals.svg' alt='' />
              <strong>Variety of Verticals</strong>
              <p>We enable you to discover your ideal niche or product by choosing from a diverse range of verticals.</p>
            </div>
            <div className='route__landingPage__advertisers__right__grid__item'>
              <img src='images/stats.svg' alt='' />
              <strong>Advanced statistics</strong>
              <p>Detailed reports, monitor progress, and optimize your campaign performance.</p>
            </div>
            <div className='route__landingPage__advertisers__right__grid__item'>
              <img src='images/support.svg' alt='' />
              <strong>24 Hour Full Support</strong>
              <p>We offer 24-hour support, along with landing pages and creatives that we can update weekly or based on your needs and preferences.</p>
            </div>
          </div>
        </div>
      </div>

      <div id='affiliates' className='route__landingPage__affiliates'>
        <div className='route__landingPage__affiliates__left'>
          <div className='route__landingPage__affiliates__left__grid'>
            <div className='route__landingPage__affiliates__left__grid__item'>
              <img src='images/ui.svg' alt='' />
              <strong>Easy-to-use UI</strong>
              <p>Scale effortlessly with our user-friendly UI and seamlessly access advertising channels as you grow.</p>
            </div>
            <div className='route__landingPage__affiliates__left__grid__item'>
              <img src='images/promote.svg' alt='' />
              <strong>Promote desired products</strong>
              <p>Select your ideal product and drive results with strategies that work—it's completely up to you!</p>
            </div>
            <div className='route__landingPage__affiliates__left__grid__item'>
              <img src='images/money.svg' alt='' />
              <strong>Bonuses</strong>
              <p>You have the opportunity to boost your earnings with built-in monthly performance bonuses.</p>
            </div>
            <div className='route__landingPage__affiliates__left__grid__item'>
              <img src='images/support.svg' alt='' />
              <strong>24 Hour Full Support</strong>
              <p>Immediately after registration, you are assigned a personal manager who will be available to you at any time! He will find a solution to every problem you have and an answer to every question you have.</p>
            </div>
          </div>
        </div>
        <div className='route__landingPage__affiliates__right'>
          <h1>LeadSquar Affiliate</h1>
          <p>We make payments on any working day and Saturday within 24 hours after your request!</p>
          <p>We offer the following payment methods: USDT, payoneer, bank and paypal. Other payment methods are also possible by agreement.</p>
          <div onClick={() => animateNavigate('/register')} className='route__landingPage__affiliates__right__button'>
            <p>Get Started</p>
          </div>
        </div>
      </div>

      <div className='route__landingPage__verticals'>
        <div className='route__landingPage__verticals__left'>
          <h1>A Vast variety of verticals</h1>
          <p>
            Explore a wide variety of verticals such as e-commerce, nutra, crypto, health & beauty, gaming, sweepstakes, casino, subscriptions, app installs, SmartLink, finance, software, dating, and more. Find what fits you best.
          </p>
        </div>
        <div className='route__landingPage__verticals__right'>
          <img src='images/categories.svg' alt='' />
        </div>
      </div>

      <div className='route__landingPage__partners'>
        <h1>Partners</h1>
        <div className='route__landingPage__partners__grid'>
          <img src='images/mgid.png' alt='' />
          <img src='images/adnow.png' alt='' />
          <img src='images/almar.webp' alt='' />
          <img src='images/voluum.png' alt='' />
          <img src='images/facebook.png' alt='' />
        </div>
      </div>
    </div>
  )
}
