import React from 'react';
import "./index.scss";
import moment from 'moment';
import DateRangeCalendar from '../../components/DateRangeCalendar';
import Spinner from '../../components/Spinner';
import axios from "axios";
import * as backendModule from "../../modules/backendModule";

export default function AdvertiserBalance(props) {
    const [dateRange, setDateRange] = React.useState({ start: null, end: null });
    const [payouts, setPayouts] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);
    const [paginationSpinner, setPaginationSpinner] = React.useState(false);
    const [paginationMessage, setPaginationMessage] = React.useState(null);

    const getPayouts = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payouts/getAllPayouts`,
            data: {
                filters: [{ name: 'AdvertiserID', op: 'eq', value: props.userData?.ID }],
                startDate: dateRange.start !== null ? dateRange.start : null,
                endDate: dateRange.end !== null ? dateRange.end : null,
                offset: 0,
                limit: 20,
                orders: [["createdAt", "DESC"]]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setPayouts(res.data.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const getPayoutsPagination = (limit, offset) => {
        setPaginationSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payouts/getAllPayouts`,
            data: {
                filters: [{ name: 'AdvertiserID', op: 'eq', value: props.userData?.ID }],
                startDate: dateRange.start !== null ? dateRange.start : null,
                endDate: dateRange.end !== null ? dateRange.end : null,
                offset: offset,
                limit: limit,
                orders: [["createdAt", "DESC"]]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 0) {
                    setPaginationMessage('There are no more payouts');
                }
                else {
                    setPayouts(prev => [...prev, ...res.data.data]);
                }
            } else {
                setPaginationMessage('Unknown error occurred');
            }
        }).catch(() => {
            setPaginationMessage('Unknown error occurred');
        }).finally(() => {
            setPaginationSpinner(false);
        });
    }

    React.useEffect(() => {
        getPayouts();
    }, []);

    React.useEffect(() => {
        if (dateRange.start !== null && dateRange.end !== null) {
            getPayouts();
        }
    }, [dateRange]);

    return (
        <div className='route__balance'>
            <div className='route__balance__header'>
                <h1>Balance history</h1>
                <DateRangeCalendar range={dateRange} setRange={setDateRange} />
            </div>

            <div className='route__balance__table'>
                <div className='route__balance__table__headers'>
                    <p>ID</p>
                    <p>Lead ID</p>
                    <p>Amount</p>
                    <p>Currency</p>
                    <p>Status</p>
                    <p>Time</p>
                </div>
                <div className='route__balance__table__content'>
                    {!spinner && !error ? payouts?.map((payout, index) => {
                        return <div style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__balance__table__content__row'>
                            <p>{payout.ID}</p>
                            <p>{payout.LeadID}</p>
                            <p>{payout.AdvertiserAmount}</p>
                            <p>USD</p>
                            <p className='route__balance__table__content__row__status'>Charge off</p>
                            <p>{moment(payout.createdAt).format('DD.MM.YYYY. hh:mm:ss A')}</p>
                        </div>
                    }) : null}
                    {payouts.length > 19 && <div className='route__balance__table__content__pagination'>
                        {!paginationSpinner && !paginationMessage ? <div onClick={() => getPayoutsPagination(20, payouts?.length)} className='route__balance__table__content__pagination__btn'>
                            <p>Load more</p>
                        </div> : null}
                        {paginationMessage && <p style={{ color: 'maroon', marginLeft: 'auto', marginRight: 'auto', display: 'block', fontSize: '14px' }}>{paginationMessage}</p>}
                        {paginationSpinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    </div>}
                    {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    {error && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
                    {payouts.length === 0 && <p style={{ padding: '10px' }}>No data yet</p>}
                </div>
            </div>
        </div>
    )
}
