import React from 'react';
import "./index.scss";

export default function AccessDenied() {
    return (
        <div className='component__accessDenied'>
            <div className='component__accessDenied__wrapper'>
                <p>
                    Access not granted. Please wait for your registration to be confirmed by the assigned manager.<br/><br/>
                    Telegram: dzenan_leadsquar
                </p>
            </div>
        </div>
    )
}
