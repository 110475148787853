import React from 'react';
import "./index.scss";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';

export default function AffiliateProfile(props) {
    const [curUser, setCurUser] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [saveError, setSaveError] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [passSpinner, setPassSpinner] = React.useState(false);
    const [passError, setPassError] = React.useState(null);
    const [passMessage, setPassMessage] = React.useState(null);

    const emailRef = React.useRef(null);
    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const zipRef = React.useRef(null);
    const experienceRef = React.useRef(null);
    const skypeRef = React.useRef(null);
    const telegramRef = React.useRef(null);
    const whatsappRef = React.useRef(null);
    const vatRef = React.useRef(null);
    const companyNameRef = React.useRef(null);
    const oldPasswordRef = React.useRef(null);
    const newPasswordRef = React.useRef(null);

    const getCurUser = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getUserByID`,
            data: {
                ID: props.userData?.ID
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setCurUser(res.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const saveChanges = () => {
        if (
            !emailRef.current.value ||
            !firstNameRef.current.value ||
            !lastNameRef.current.value ||
            !zipRef.current.value ||
            !experienceRef.current.value
        ) {
            return setSaveError('Essential info missing!');
        } else {
            setBtnSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editUser`,
            data: {
                id: props.userData?.ID,
                email: emailRef.current.value,
                FirstName: firstNameRef.current.value,
                LastName: lastNameRef.current.value,
                ZIP: zipRef.current.value,
                Experience: experienceRef.current.value,
                Skype: skypeRef.current.value,
                Telegram: telegramRef.current.value,
                Whatsapp: whatsappRef.current.value,
                VATnumber: vatRef.current.value,
                CompanyName: companyNameRef.current.value
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setMessage('Changes are saved!');
                setSaveError(null);
                getCurUser();
            } else {
                setSaveError('Unknown error occurred');
            }
        }).catch(() => {
            setSaveError('Unknown error occurred');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    const changePassword = () => {
        if(!oldPasswordRef.current.value ||!newPasswordRef.current.value){
            return setPassError('Info missing!');
        }else{
            setPassSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data: {
                ID: props.userData?.ID,
                oldPassword: oldPasswordRef.current.value,
                newPassword: newPasswordRef.current.value
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setPassMessage('Password is changed!');
            } else {
                setPassError('Unknown error occurred');
            }
        }).catch(() => {
            setPassError('Unknown error occurred');
        }).finally(() => {
            setPassSpinner(false);
        });
    }

    React.useEffect(() => {
        getCurUser();
    }, []);

    return (
        <div className='route__profile'>
            <div className='route__profile__header'>
                <h1>My profile</h1>
                {message && <p style={{ color: 'green', fontSize: '15px' }}>{message}</p>}
                {saveError && <p style={{ color: 'maroon', fontSize: '15px' }}>{saveError}</p>}
                <div onClick={() => saveChanges()} className='route__profile__header__button'>
                    {!btnSpinner && <img src='images/savechanges.png' alt='' />}
                    {!btnSpinner && <p>Save changes</p>}
                    {btnSpinner && <Spinner style={{ width: '24px', height: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
                </div>
            </div>
            {!spinner && !error ? <div className='route__profile__container'>
                <div className='route__profile__container__editableInfo'>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>Email</label>
                        <input ref={emailRef} type='text' defaultValue={curUser?.data?.Email} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>First name</label>
                        <input ref={firstNameRef} type='text' defaultValue={curUser?.data?.FirstName} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>Last Name</label>
                        <input ref={lastNameRef} type='text' defaultValue={curUser?.data?.LastName} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>ZIP</label>
                        <input ref={zipRef} type='text' defaultValue={curUser?.data?.ZIP} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>Experience (years)</label>
                        <input ref={experienceRef} type='number' defaultValue={curUser?.data?.Experience} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>Skype</label>
                        <input ref={skypeRef} type='text' defaultValue={curUser?.data?.Skype} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>Telegram</label>
                        <input ref={telegramRef} type='text' defaultValue={curUser?.data?.Telegram} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>Whatsapp</label>
                        <input ref={whatsappRef} type='text' defaultValue={curUser?.data?.Whatsapp} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>VAT Number</label>
                        <input ref={vatRef} type='text' defaultValue={curUser?.data?.VATnumber} />
                    </div>
                    <div className='route__profile__container__editableInfo__field'>
                        <label>Company name</label>
                        <input ref={companyNameRef} type='text' defaultValue={curUser?.data?.CompanyName} />
                    </div>
                </div>
                <div className='route__profile__container__info'>
                    <div className='route__profile__container__info__field'>
                        <label>Affiliate ID</label>
                        <p>{curUser?.data?.ID}</p>
                    </div>
                    <div className='route__profile__container__info__field'>
                        <label>Username</label>
                        <p>{curUser?.data?.Username}</p>
                    </div>
                    <div className='route__profile__container__info__field'>
                        <label>Country</label>
                        <p>{curUser?.data?.Country}</p>
                    </div>
                    <div className='route__profile__container__info__field'>
                        <label>Api key</label>
                        <p>{curUser?.data?.ApiKey}</p>
                    </div>
                    <div className='route__profile__container__info__field'>
                        <label>Joined</label>
                        <p>{moment(curUser?.data?.createdAt).format('DD.MM.YYYY.')}</p>
                    </div>
                    <div className='route__profile__container__info__field'>
                        <label>Balance</label>
                        <p>${curUser?.data?.Balance}</p>
                    </div>
                    <div className='route__profile__container__info__field'>
                        <label>Squers balance</label>
                        <p>{curUser?.data?.Squers}</p>
                    </div>
                    <div className='route__profile__container__info__field'>
                        <label>Affiliate manager</label>
                        <p>@dzenan_leaddquar</p>
                    </div>
                </div>
            </div> : null}
            {!spinner && !error ? <div className='route__profile__changePassword'>
                <div className='route__profile__changePassword__top'>
                    <h2>Change password</h2>
                    {passError && <p style={{color: 'maroon', fontSize: '15px'}}>{passError}</p>}
                    {passMessage && <p style={{color: 'green', fontSize: '15px'}}>{passMessage}</p>}
                </div>
                <div className='route__profile__changePassword__wrapper'>
                    <div className='route__profile__changePassword__wrapper__field'>
                        <label>Current password</label>
                        <input ref={oldPasswordRef} type='password' />
                    </div>
                    <div className='route__profile__changePassword__wrapper__field'>
                        <label>New password</label>
                        <input ref={newPasswordRef} type='password' />
                    </div>
                    <div onClick={() => changePassword()} className='route__profile__changePassword__wrapper__button'>
                        {!passSpinner && <img src='images/changepassword.png' alt='' />}
                        {!passSpinner && <p>Change password</p>}
                        {passSpinner && <Spinner style={{ width: '24px', height: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
                    </div>
                </div>
            </div> : null}
            {error && <p style={{ color: 'maroon' }}>{error}</p>}
            {spinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        </div>
    )
}
