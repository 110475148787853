import React from "react";
import "./index.scss";

import PropTypes from "prop-types";

const SimpleDropdown = (props) => {
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const [search, setSearch] = React.useState(null);
    const [filteredData, setFilteredData] = React.useState(props.data);

    const searchRef = React.useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const searchQuery = searchRef.current.value.toLowerCase();
            setSearch(searchQuery);

            const filteredResults = props.data.filter((item) =>
                Object.values(item).some((value) =>
                    String(value).toLowerCase().includes(searchQuery)
                )
            );

            setFilteredData(filteredResults);
        }
    };

    return <div className={props.className ? `component__simpleDropdown ${props.className}` : 'component__simpleDropdown'}>

        <div style={props.headStyle} onClick={(e) => { e.stopPropagation(); setOpen(o => !o) }} className="component__simpleDropdown__head">
            <p style={props.headTextStyle} className="component__simpleDropdown__head__text">{selected ? selected : props.heading}</p>
        </div>
        <div style={props.bodyStyle} className={open ? 'component__simpleDropdown__body component__simpleDropdown__body__active' : 'component__simpleDropdown__body'}>
            {
                props.search && <div className="component__simpleDropdown__body__search">
                    <input ref={searchRef} onKeyDown={handleKeyDown} type='text' placeholder="Search... (press enter)" />
                </div>
            }
            {
                !search ? props.data?.map(item => {
                    return <p
                        style={props.itemStyle}
                        onClick={() => { props.setSelected(item); setSelected(item.name); setOpen(false); }}
                        className="component__simpleDropdown__body__item"
                    >
                        {item.name}
                    </p>
                }) : null
            }
            {
                props.search && search ? filteredData?.map(item => {
                    return <p
                        style={props.itemStyle}
                        onClick={() => { props.setSelected(item); setSelected(item.name); setOpen(false); }}
                        className="component__simpleDropdown__body__item"
                    >
                        {item.name}
                    </p>
                }) : null
            }
        </div>
    </div>
}

SimpleDropdown.propTypes = {
    heading: PropTypes.string,
    setSelected: PropTypes.func,
    xCallback: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
    })),
    headStyle: PropTypes.any,
    bodyStyle: PropTypes.any,
    itemStyle: PropTypes.any,
    headTextStyle: PropTypes.any,
    search: PropTypes.any
}

export default SimpleDropdown;