import React from 'react';
import "./index.scss";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';
import Spinner from '../../components/Spinner';

export default function ApiPostbackDocumentation(props) {
    const [curUser, setCurUser] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(true);

    const paramsString = `
    ApiKey: '${curUser?.data?.ApiKey}', 
    ClientName: nameInput.value, (STRING)
    ClientPhone: phoneInput.value, (STRING || NUMBER)
    AdvertiserID: (NUMBER),
    OfferID: (NUMBER), 
    AffiliateID: ${curUser?.data?.ID},
    SubID1: getQueryParam('sub_id1') ? getQueryParam('sub_id1') : null,
    SubID2: getQueryParam('sub_id2') ? getQueryParam('sub_id2') : null,
    SubID3: getQueryParam('sub_id3') ? getQueryParam('sub_id3') : null,
    FBpixelID: getQueryParam('fb_pixel') ? getQueryParam('fb_pixel') : null,
    TrackingID: getQueryParam('tracking_id') ? getQueryParam('tracking_id') : null
    `;

    const addLeadCode = `document.addEventListener('DOMContentLoaded', function() {
        const form = document.getElementById('LeadSquar-form');
        const button = form.querySelector('button');
    
        function getQueryParam(param) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
        }
    
        button.addEventListener('click', function(event) {
            event.preventDefault(); 
    
            const nameInput = form.querySelector('input[name="name"]');
            const phoneInput = form.querySelector('input[name="phone"]');
    
            const data = {
                ApiKey: '${curUser?.data?.ApiKey}',                
                ClientName: nameInput.value,
                ClientPhone: phoneInput.value,
                AdvertiserID: (NUMBER),
                OfferID: (NUMBER),
                AffiliateID: ${props.userData?.ID},
                SubID1: getQueryParam('sub_id1') ? getQueryParam('sub_id1') : null,
                SubID2: getQueryParam('sub_id2') ? getQueryParam('sub_id2') : null,
                SubID3: getQueryParam('sub_id3') ? getQueryParam('sub_id3') : null,
                FBpixelID: getQueryParam('fb_pixel_id') ? getQueryParam('fb_pixel_id') : null,
                TrackingID: getQueryParam('tracking_id') ? getQueryParam('tracking_id') : null,
                Source: getQueryParam('source') ? getQueryParam('source') : null
            }
    
            fetch("${backendModule.backendURL}/leads/addLead", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(data => {
                if(data.status === 200){
                    //window.location.href='/thankyou.html';
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        });
    });`;

    const getLeadsParams = `
    {
        "ApiKey" : "vFNpdlYsmPmyfWX2Lp6OmO5YsZD1Ejif",
        "filters":  [{"name": "OfferCountry", "op": "eq", "value": "BA"}, {"name": "Status", "op": "eq", "value": "2"}],
        "orders":  [["createdAt", "DESC"]]
    }
    `;

    const leadStatusesList = `
    leadStatuses = [
        { name: 'Accepted', value: 0},
        { name: 'Expect', value: 1},
        { name: 'Confirmed', value: 2},
        { name: 'Reject', value: 3},
        { name: 'Trash', value: 4},
        { name: 'Duplicate', value: 5},
        { name: 'Test', value: 6},
        { name: 'Error', value: 7}
    ];
    `;

    const postbackParams = `
        {
            "AdvertiserID": ${props.userData?.ID},
            "Status": STATUS_FROM_YOUR_CRM,
            "LeadID": 37, // if you have saved our Lead ID
            "AdditionalInfo": SOME_VALUE // if you send us your Lead ID or your LeadHash
        }
    `;

    const getLeadsResponse = `
        "root":{
            "status":"ok"
            "data":[
                    0:{
                        "ID":59
                        "ClientName":"test" //this row will only advertisers get
                        "ClientPhone":"234234324" //this row will only advertisers get
                        "AffiliateID":10
                        "OfferID":2
                        "OfferName":"Hemol"
                        "OfferCountry":"BA"
                        "OfferType":"FULL"
                        "OfferPrice":59
                        "OfferCurrency":"KM"
                        "OfferPayout":27
                        "AdvertiserID":5
                        "Status":2
                        "AdditionalInfo":"aid5-59becbd1-2554-4fa5-b4fd-415791abcba1" //this row will only advertisers get
                        "SubID1":"dashara"
                        "SubID2":"test"
                        "SubID3":NULL
                        "SubID4":NULL
                        "FBpixelID":"3455632"
                        "TrackingID":"ae5fe12c-80f3-4d41-a1c7-1575c1a76893"
                        "Source":"facebook"
                        "createdAt":"2024-11-16T23:18:55.000Z"
                        "updatedAt":"2024-11-16T23:20:38.000Z"
                    }
                ]
        }
    `;

    const getCurUser = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getUserByID`,
            data: {
                ID: props.userData?.ID
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setCurUser(res.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    React.useEffect(() => {
        getCurUser();
    }, []);

    return (
        <div className='route__documentation'>
            {!spinner && !error ? <div className='route__documentation__wrap'>
                <h1>API & postback</h1>
                <div className='route__documentation__wrap__box'>
                    <h3>Add Lead</h3>
                    <strong>METHOD: POST</strong>
                    <div className='route__documentation__wrap__box__url'>
                        <p>{backendModule.backendURL}/leads/addLead</p>
                    </div>
                    <h4>Request body params (JSON)</h4>
                    <div className='route__documentation__wrap__box__code'>
                        <pre>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: paramsString
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;')
                                        .replace(/"/g, '&quot;')
                                        .replace(/'/g, '&#39;')
                                }}
                            />
                        </pre>
                    </div>
                    <br></br>
                    <p>
                        SubID1, SubID2, SubID3, SubID4, FBpixelID, TrackingID are optional, you should use them only if you are using tracking system.
                        <br></br>
                        OfferID and AdvertiserID you can find on offer page.
                    </p>
                    <br></br>
                    <h4>JavaScript example</h4>
                    <div className='route__documentation__wrap__box__code'>
                        <pre>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: addLeadCode
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;')
                                        .replace(/"/g, '&quot;')
                                        .replace(/'/g, '&#39;')
                                }}
                            />
                        </pre>
                    </div>
                </div>

                <div className='route__documentation__wrap__box'>
                    <h3>Get Leads</h3>
                    <strong>METHOD: POST</strong>
                    <div className='route__documentation__wrap__box__url'>
                        <p>{backendModule.backendURL}/leads/getAllLeads</p>
                    </div>
                    <h4>Request body params (JSON)</h4>
                    <div className='route__documentation__wrap__box__code'>
                        <pre>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: getLeadsParams
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;')
                                        .replace(/"/g, '&quot;')
                                        .replace(/'/g, '&#39;')
                                }}
                            />
                        </pre>
                    </div>
                    <p>
                        Filters are array of objects, you can have one or more objects.
                        <br></br><br></br>
                        "name" - is name of field in database, as value there can be ID, ClientName, ClientPhone, Source, OfferCountry, OfferName, OfferType, Status, SubID1, SubID2, SubID3, SubID4, TrackingID etc.
                        <br></br>
                        "op" - is operator, as value there can be "eq" or "like", eq means equals, like means includes, contains...
                        <br></br>
                        "value" - is some value of field for which you are searching or filtering
                    </p>
                    <br></br>
                    <h4>Lead statuses list</h4>
                    <div className='route__documentation__wrap__box__code'>
                        <pre>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: leadStatusesList
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;')
                                        .replace(/"/g, '&quot;')
                                        .replace(/'/g, '&#39;')
                                }}
                            />
                        </pre>
                    </div>
                    <br></br>
                    <h4>Expected response example</h4>
                    <div className='route__documentation__wrap__box__code'>
                        <pre>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: getLeadsResponse
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;')
                                        .replace(/"/g, '&quot;')
                                        .replace(/'/g, '&#39;')
                                }}
                            />
                        </pre>
                    </div>
                </div>

                <div className='route__documentation__wrap__box'>
                    <h3>Postback for advertisers</h3>
                    <strong>METHOD: POST</strong>
                    <div className='route__documentation__wrap__box__url'>
                        <p>{backendModule.backendURL}/postback/pingLead</p>
                    </div>
                    <h4>Request body params (JSON)</h4>
                    <div className='route__documentation__wrap__box__code'>
                        <pre>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: postbackParams
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;')
                                        .replace(/"/g, '&quot;')
                                        .replace(/'/g, '&#39;')
                                }}
                            />
                        </pre>
                    </div>
                    <p>
                        You can use this route to notify us when a lead changes status, use either only LeadID or only AdditionalInfo
                    </p>
                </div>
            </div> : null}
            {error && <p style={{ color: 'maroon' }}>{error}</p>}
            {spinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        </div>
    )
}
