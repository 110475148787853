import React from 'react';
import "./index.scss";

export default function Shop() {
  return (
    <div className='route__shop'>
        <h1>Here you will be able to spend the collected squares</h1>
        <p>Coming soon...</p>
    </div>
  )
}
