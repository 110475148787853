import React from 'react';
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Spinner from '../../components/Spinner';

export default function ReadNews(props) {
    const [articleData, setArticleData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const getArticle = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/news/getAllNews`,
            data: {
                filters: [{name: 'ID', op: 'eq', value: Number(queryParams.get('articleID'))}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setArticleData(res.data.data);
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteArticle = () => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/news/removeArticle`,
            data: {
                ID: queryParams.get('articleID')
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setError('Article was deleted!');
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    const updateUrgency = (mode) => {
        setBtnSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/news/editArticle`,
            data: {
                ID: queryParams.get('articleID'),
                IsUrgent: mode === 'urgent' ? true : false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getArticle();
                setError('Urgency changed!');
            }
            else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occured');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    React.useEffect(() => {
        getArticle();
    }, []);

  return (
    <div className='route__read'>
        {props.mode === 'admin' && <div className='route__read__actions'>
            <div onClick={() => deleteArticle()} className='route__read__actions__button'>
                {!btnSpinner && <p>Delete</p>}
                {btnSpinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
            </div>
            {articleData[0]?.IsUrgent && <div onClick={() => updateUrgency('notUrgent')} className='route__read__actions__button'>
                {!btnSpinner && <p>Not urgent</p>}
                {btnSpinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
            </div>}
            {!articleData[0]?.IsUrgent && <div onClick={() => updateUrgency('urgent')} className='route__read__actions__button'>
                {!btnSpinner && <p>Urgent</p>}
                {btnSpinner && <Spinner style={{ width: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
            </div>}
        </div>}

        {spinner && <Spinner style={{ width: '64px',height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
        {error && <p style={{color: 'maroon', fontSize: '15px'}}>{error}</p>}
        {!spinner && <div className='route__read__article'>
            <small>{moment(articleData[0]?.createdAt).format('DD.MM.YYYY.')}</small>
            <h1>{articleData[0]?.Heading}</h1>
            <p dangerouslySetInnerHTML={{ __html: articleData[0]?.Text }}></p>
        </div>}
    </div>
  )
}
