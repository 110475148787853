import React from 'react';
import "./index.scss";
import axios from "axios";
import moment from 'moment';
import * as backendModule from "../../modules/backendModule";
import Spinner from '../../components/Spinner';
import DateRangePicker from '../../components/DateRangeCalendar';
import { animateBox } from "../../modules/componentAnimation";
import {
    Chart,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip,
    Legend
} from 'chart.js';

Chart.register(CategoryScale, LinearScale, LineController, LineElement, PointElement, Tooltip, Legend);
let myChart;

export default function AffiliateTracking(props) {
    const [campaigns, setCampaigns] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [chartSpinner, setChartSpinner] = React.useState(true);
    const [paginationSpinner, setPaginationSpinner] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [chartError, setChartError] = React.useState(null);
    const [paginationMessage, setPaginationMessage] = React.useState(null);
    const [dateRange, setDateRange] = React.useState({ start: null, end: null });
    const [additionalInfoActive, setAdditionalInfoActive] = React.useState({ index: null, state: false });
    const [labels, setLabels] = React.useState(null);
    const [clicksData, setClicksData] = React.useState(null);
    const [conversionsData, setConversionsData] = React.useState(null);

    const chartRef = React.useRef(null);


    const handleAdditionalInfoBox = (idx) => {
        if (additionalInfoActive.state) {
            setAdditionalInfoActive({ index: idx, state: false });
        } else {
            setAdditionalInfoActive({ index: idx, state: true });
        }
    }

    const getCampaigns = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/tracking/getAllCampaigns`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    { name: 'AffiliateID', op: 'eq', value: props.userData?.ID }
                ],
                orders: [["createdAt", "DESC"]],
                startDate: dateRange.start !== null ? dateRange.start : null,
                endDate: dateRange.end !== null ? dateRange.end : null
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setCampaigns(res.data.data);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const getCampaignsPagination = (limit, offset) => {
        setPaginationSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/tracking/getAllCampaigns`,
            data: {
                limit: limit,
                offset: offset,
                orders: [["createdAt", "DESC"]],
                filters: [
                    { name: 'AffiliateID', op: 'eq', value: props.userData?.ID }
                ],
                startDate: dateRange.start !== null ? dateRange.start : null,
                endDate: dateRange.end !== null ? dateRange.end : null
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 0) {
                    setPaginationMessage('There are no more campaigns');
                }
                else {
                    setCampaigns(prev => [...prev, ...res.data.data]);
                }
            } else {
                setPaginationMessage('Unknown error occurred');
            }
        }).catch(() => {
            setPaginationMessage('Unknown error occurred');
        }).finally(() => {
            setPaginationSpinner(false);
        });
    }

    const getChartData = async () => {
        try {
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/tracking/getTrackingStats`,
                data: {
                    AffiliateID: props.userData?.ID
                },
                ...backendModule.axiosConfig,
            }).then(res => {
                if (res.data.status === 'ok') {
                    setLabels(res.data.data.map(item => item.date));
                    setClicksData(res.data.data.map(item => item.totalClicks));
                    setConversionsData(res.data.data.map(item => item.totalConversions));
                }
            }).finally(() => {
                setChartSpinner(false);
            });
        } catch (error) {
            return setChartError("Error while fetching chart data");
        }
    };

    React.useEffect(() => {
        getCampaigns();
        getChartData();
    }, []);

    React.useEffect(() => {
        if (dateRange.start !== null && dateRange.end !== null) {
            getCampaigns();
        }
    }, [dateRange]);

    React.useEffect(() => {
        if (!chartSpinner && labels && clicksData && conversionsData) {
            if (myChart) {
                myChart.destroy();
            }
            const ctx = chartRef.current.getContext('2d');
            myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Unique clicks',
                            data: clicksData,
                            borderColor: 'blue',
                            fill: false
                        },
                        {
                            label: 'Leads',
                            data: conversionsData,
                            borderColor: 'green',
                            fill: false
                        }
                    ]
                },
                options: {
                    scales: {
                        x: {
                            type: 'category',
                            title: {
                                display: true,
                                text: 'Date'
                            }
                        },
                        y: {
                            type: 'linear',
                            title: {
                                display: false
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.dataset.label || '';
                                    const value = context.raw;
                                    return `${label}: ${value}`;
                                }
                            }
                        },
                        legend: {
                            labels: {
                                font: {
                                    size: window.innerWidth < 660 ? '10' : '14',
                                    family: 'Figtree',
                                    weight: '600',
                                },
                                color: '#555',
                                boxWidth: 10,
                                padding: 10,
                            },
                        },
                    }
                }
            });
        }
    }, [chartSpinner, labels, clicksData, conversionsData]);

    return (
        <div className='route__tracking'>
            <div className='route__tracking__header'>
                <h1>Tracking</h1>
                <div onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <NewCampaignModal refresh={getCampaigns} userData={props.userData} />)} className='route__tracking__header__button'>
                    <img src='images/sidebar__tracking.png' alt='' />
                    <p>New campaign</p>
                </div>
            </div>

            <div className='ls___chart__container' style={{ width: '100%' }}>
                {!chartSpinner && labels && clicksData && conversionsData && !chartError ? <canvas width='100%' height='500px' ref={chartRef} className='ls___chart'></canvas> : null}
                {chartSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                {chartError && <p style={{ color: 'maroon', fontSize: '15px' }}>{chartError}</p>}
            </div>

            <div className='route__tracking__filters'>
                <h1>Campaigns</h1>
                <DateRangePicker setRange={setDateRange} range={dateRange} />
            </div>
            <div className='route__tracking__table'>
                <div className='route__tracking__table__headers'>
                    <p>ID</p>
                    <p>Name</p>
                    <p>Offer</p>
                    <p>Tracking ID</p>
                    <p>Unique clicks</p>
                    <p>Leads</p>
                    <p>Created at</p>
                </div>
                <div className='route__tracking__table__content'>
                    {!spinner && !error ? campaigns?.map((campaign, index) => {
                        return <div>
                            <div onClick={() => handleAdditionalInfoBox(index)} style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }} className='route__tracking__table__content__row'>
                                <p>{campaign.ID}</p>
                                <p>{campaign.Name}</p>
                                <p>{campaign.OfferName}</p>
                                <p>{campaign.TrackingID}</p>
                                <p>{campaign.Clicks}</p>
                                <p>{campaign.Conversions}</p>
                                <p>{moment(campaign.createdAt).format('DD.MM.YYYY.')}</p>
                                <img
                                    src='images/arrowDown.png' alt=''
                                    className={additionalInfoActive.state && additionalInfoActive.index === index ? 'route__tracking__table__content__row__arrow route__tracking__table__content__row__arrow__active'
                                        : 'route__tracking__table__content__row__arrow'}
                                />
                            </div>
                            <div
                                style={{ background: index % 2 === 0 ? '#F2F3F5' : 'white' }}
                                className={additionalInfoActive.state && additionalInfoActive.index === index ? 'route__tracking__table__content__row__additionalInfo route__tracking__table__content__row__additionalInfo__active'
                                    : 'route__tracking__table__content__row__additionalInfo'}
                            >
                                <p><b>Landing Page URL:</b> {campaign.LandingPageURL}</p>
                                <p><b>Tracking URL:</b> {campaign.TrackingURL}</p>
                                <p><b>FB pixel ID:</b> {campaign.FBpixelID}</p>
                                <p><b>Source:</b> {campaign.Source}</p>
                                <p><b>Sub_id1:</b> {campaign.SubID1}</p>
                                <p><b>Sub_id2:</b> {campaign.SubID2}</p>
                                <p><b>Sub_id3:</b> {campaign.SubID3}</p>
                                <p><b>Sub_id4:</b> {campaign.SubID4}</p>
                                <p><b>Offer ID:</b> {campaign.OfferID}</p>
                            </div>
                        </div>
                    }) : null}
                    {campaigns.length > 19 && <div className='route__tracking__table__content__pagination'>
                        {!paginationSpinner && !paginationMessage ? <div onClick={() => getCampaignsPagination(20, campaigns?.length)} className='route__tracking__table__content__pagination__btn'>
                            <p>Load more campaigns</p>
                        </div> : null}
                        {paginationMessage && <p style={{ color: 'maroon', marginLeft: 'auto', marginRight: 'auto', display: 'block', fontSize: '14px' }}>{paginationMessage}</p>}
                        {paginationSpinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    </div>}
                    {spinner && <Spinner style={{ width: '64px', height: '50px', marginLeft: '20px', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                    {error && <p style={{ color: 'maroon', marginLeft: '20px', fontSize: '14px' }}>{error}</p>}
                    {campaigns.length === 0 && <p style={{ padding: '10px' }}>No data yet</p>}
                </div>
            </div>
        </div>
    )
}

const NewCampaignModal = (props) => {
    const [offers, setOffers] = React.useState([]);
    const [selectedOffer, setSelectedOffer] = React.useState([]);
    const [search, setSearch] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [genErrror, setGenError] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [btnSpinner, setBtnSpinner] = React.useState(false);
    const [dropdown, setDropdown] = React.useState(false);

    const searchRef = React.useRef(null);
    const nameRef = React.useRef(null);
    const pixelRef = React.useRef(null);
    const sub1Ref = React.useRef(null);
    const sub2Ref = React.useRef(null);
    const sub3Ref = React.useRef(null);
    const sub4Ref = React.useRef(null);
    const sourceRef = React.useRef(null);
    const landingRef = React.useRef(null);

    const searchOfffer = (event) => {
        if (event.key === "Enter") {
            const searchQuery = searchRef.current.value.toLowerCase();
            setSearch(searchQuery);
        }
    };

    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                filters: [{ name: 'Name', op: 'like', value: search }],
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data?.data);
                setDropdown(true);
            } else {
                setError('Unknown error occurred');
            }
        }).catch(() => {
            setError('Unknown error occurred');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const generateTrackingURL = (e) => {
        if (!nameRef.current.value || !selectedOffer?.Name || !landingRef.current.value) {
            return setGenError('Name, Offer and Landing URL are essential!');
        } else {
            setBtnSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/tracking/generateTrackingURL`,
            data: {
                AffiliateID: props.userData?.ID,
                OfferID: selectedOffer?.ID,
                SubID1: sub1Ref.current.value,
                SubID2: sub2Ref.current.value,
                SubID3: sub3Ref.current.value,
                SubID4: sub4Ref.current.value,
                Source: sourceRef.current.value,
                FBpixelID: pixelRef.current.value,
                LandingPageURL: landingRef.current.value,
                Name: nameRef.current.value
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === "ok") {
                animateBox(e, <URLmodal trackingURL={res.data} refresh={props.refresh} />);
            } else {
                setGenError('Unknown error occurred');
            }
        }).catch(() => {
            setGenError('Unknown error occurred');
        }).finally(() => {
            setBtnSpinner(false);
        });
    }

    React.useEffect(() => {
        if (search) {
            getOffers();
        } else {
            setDropdown(false);
        }
    }, [search]);

    return <div className='component__newCampaignModal'>
        <div className='component__newCampaignModal__wrapper'>
            <div className='component__newCampaignModal__wrapper__header'>
                <h1>New campaign</h1>
                <img onClick={props.onClose} src='images/closeModal.png' alt='' />
            </div>
            <div className='component__newCampaignModal__wrapper__body'>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>Campaign name</label>
                    <input ref={nameRef} type='text' />
                </div>
                <div className='component__newCampaignModal__wrapper__body__field component__newCampaignModal__wrapper__body__field__dropdown'>
                    <label>Select offer</label>
                    {selectedOffer.length === 0 && <input placeholder='search by name (press enter)' ref={searchRef} onKeyDown={searchOfffer} type='text' />}
                    {selectedOffer?.Name ? <p onClick={() => { setSelectedOffer([]); setSearch(null) }}>{selectedOffer?.Type}-{selectedOffer?.Name}-{selectedOffer?.Country}-#{selectedOffer?.ID}</p> : null}
                    <div className={dropdown ? 'component__newCampaignModal__wrapper__body__field__dropdown__body component__newCampaignModal__wrapper__body__field__dropdown__body__active' : 'component__newCampaignModal__wrapper__body__field__dropdown__body'}>
                        {
                            !spinner && !error ? offers.map(item => {
                                return <div onClick={() => { setSelectedOffer(item); setDropdown(false) }} className='component__newCampaignModal__wrapper__body__field__dropdown__body__item'>
                                    <p>{item.Type}-{item.Name}-{item.Country}-#{item.ID}</p>
                                </div>
                            }) : null
                        }
                        {spinner && <Spinner style={{ width: '24px', height: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='#1A1A1A' />}
                        {error && <p style={{ color: 'maroon', fontSize: '12px' }}>{error}</p>}
                    </div>
                </div>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>FB pixel ID (optional)</label>
                    <input ref={pixelRef} type='text' />
                </div>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>Sub_id1 (optional)</label>
                    <input ref={sub1Ref} type='text' />
                </div>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>Sub_id2 (optional)</label>
                    <input ref={sub2Ref} type='text' />
                </div>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>Sub_id3 (optional)</label>
                    <input ref={sub3Ref} type='text' />
                </div>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>Sub_id4 (optional)</label>
                    <input ref={sub4Ref} type='text' />
                </div>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>Source</label>
                    <input ref={sourceRef} type='text' />
                </div>
                <div className='component__newCampaignModal__wrapper__body__field'>
                    <label>Affiliate ID</label>
                    <p>{props.userData?.ID}</p>
                </div>
                <div className='component__newCampaignModal__wrapper__body__field component__newCampaignModal__wrapper__body__field__lpurl'>
                    <label>Landing page URL</label>
                    <input ref={landingRef} type='text' placeholder='http:// or https://' />
                </div>
            </div>
            <div className='component__newCampaignModal__wrapper__bottom'>
                {genErrror && <p style={{ color: 'maroon', fontSize: '14px' }}>{genErrror}</p>}
                <div onClick={(e) => generateTrackingURL({ currentTarget: e.currentTarget.parentNode })} className='component__newCampaignModal__wrapper__bottom__button'>
                    {!btnSpinner && <img src='images/url.png' alt='' />}
                    {!btnSpinner && <p>Generate Tracking URL</p>}
                    {btnSpinner && <Spinner style={{ width: '24px', height: '24px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} color='white' />}
                </div>
            </div>
        </div>
    </div>
}

const URLmodal = (props) => {
    React.useEffect(() => {
        props.refresh();
    }, []);
    return <div className='component__urlModal'>
        <div className='component__urlModal__wrapper'>
            <img onClick={props.onClose} src='images/closeModal.png' alt='' />
            <label>Tracking URL is successfully generated:</label>
            <p>{props.trackingURL?.data?.trackingLink}</p>
        </div>
    </div>
}