import React, { useState } from 'react';
import moment from 'moment';
import "./index.scss";

const DateRangePicker = ({ range, setRange, width }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [hoveredDate, setHoveredDate] = useState(null);

  const toggleCalendar = () => setShowCalendar(!showCalendar);

  const handleMonthChange = (direction) => {
    setCurrentMonth(currentMonth.clone().add(direction, 'months'));
  };

  const generateCalendarDays = () => {
    const startOfMonth = currentMonth.clone().startOf('month');
    const daysInMonth = currentMonth.daysInMonth();
    const calendarDays = [];

    for (let i = 0; i < daysInMonth; i++) {
      calendarDays.push(startOfMonth.clone().add(i, 'days'));
    }
    return calendarDays;
  };

  const handleDateClick = (date) => {
    if (!range.start || (range.start && range.end)) {
      setRange({ start: date, end: null });
    } else if (date.isAfter(range.start)) {
      setRange({ start: range.start, end: date });
      setShowCalendar(false);
    } else {
      setRange({ start: date, end: null });
    }
  };

  const isInRange = (date) => {
    return (
      range.start &&
      range.end &&
      date.isSameOrAfter(moment(range.start), 'day') &&
      date.isSameOrBefore(moment(range.end), 'day')
    );
  };

  return (
    <div style={{width: width ? width : ''}} className='calendar-wrapper'>
      <button onClick={toggleCalendar}>
        {range.start && range.end
          ? `${moment(range.start).format('DD.MM.YYYY')} - ${moment(range.end).format('DD.MM.YYYY')}`
          : 'Date range'}
      </button>

      {showCalendar && (
        <div className="calendar">
          <div className="calendar-header">
            <button onClick={() => handleMonthChange(-1)}>&lt;</button>
            <span>
              {currentMonth.format('MMMM YYYY')}
            </span>
            <button onClick={() => handleMonthChange(1)}>&gt;</button>
          </div>
          <div className="calendar-days">
            {generateCalendarDays().map((date) => (
              <div
                key={date.format('DD-MM-YYYY')}
                onClick={() => handleDateClick(date)}
                onMouseEnter={() => setHoveredDate(date)}
                className={[
                  date.isSame(range.start, 'day') ? 'start-date' : '',
                  date.isSame(range.end, 'day') ? 'end-date' : '',
                  isInRange(date) || (range.start && !range.end && hoveredDate && hoveredDate.isAfter(moment(range.start)) && date.isSameOrBefore(hoveredDate, 'day'))
                    ? 'in-range'
                    : '',
                ].join(' ')}
              >
                {date.date()}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
